/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import logoVide from '../../../assets/images/logoVide.png';

const LogoVide = ({
  width, height, style, className
}) => (
  <img src={logoVide} alt="logo" width={width} height={height} style={style} className={className} />
);

LogoVide.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string
};

LogoVide.defaultProps = {
  width: 223,
  height: 111,
  style: {},
  className: ''
};

export default memo(LogoVide);
