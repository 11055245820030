/* eslint-disable max-len */
import React, { memo } from 'react';

const Dots = ({ ...props }) => (
  <svg
    width="8"
    height="16"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33268 12.0003C3.33268 12.7337 2.73268 13.3337 1.99935 13.3337C1.26602 13.3337 0.666016 12.7337 0.666016 12.0003C0.666016 11.267 1.26602 10.667 1.99935 10.667C2.73268 10.667 3.33268 11.267 3.33268 12.0003ZM1.99935 6.66699C1.26602 6.66699 0.666016 7.26699 0.666016 8.00033C0.666016 8.73366 1.26602 9.33366 1.99935 9.33366C2.73268 9.33366 3.33268 8.73366 3.33268 8.00033C3.33268 7.26699 2.73268 6.66699 1.99935 6.66699ZM1.99935 2.66699C1.26602 2.66699 0.666016 3.26699 0.666016 4.00033C0.666016 4.73366 1.26602 5.33366 1.99935 5.33366C2.73268 5.33366 3.33268 4.73366 3.33268 4.00033C3.33268 3.26699 2.73268 2.66699 1.99935 2.66699ZM5.99935 5.33366C6.73268 5.33366 7.33268 4.73366 7.33268 4.00033C7.33268 3.26699 6.73268 2.66699 5.99935 2.66699C5.26602 2.66699 4.66602 3.26699 4.66602 4.00033C4.66602 4.73366 5.26602 5.33366 5.99935 5.33366ZM5.99935 6.66699C5.26602 6.66699 4.66602 7.26699 4.66602 8.00033C4.66602 8.73366 5.26602 9.33366 5.99935 9.33366C6.73268 9.33366 7.33268 8.73366 7.33268 8.00033C7.33268 7.26699 6.73268 6.66699 5.99935 6.66699ZM5.99935 10.667C5.26602 10.667 4.66602 11.267 4.66602 12.0003C4.66602 12.7337 5.26602 13.3337 5.99935 13.3337C6.73268 13.3337 7.33268 12.7337 7.33268 12.0003C7.33268 11.267 6.73268 10.667 5.99935 10.667Z"
      fill="currentColor"
    />
  </svg>
);
export default memo(Dots);
