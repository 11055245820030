import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';
import FileDropzone from '../../UI/FileDropzone';

import { noopPromise } from '../../../utils';
import uploadFileToAWS from '../../../utils/uploadToAWS';

const FormTargetMediaAdd = ({
  onSuccess,
  onFail,
  limit,
  text,
  acceptedFiles,
  maxFileSize,
  disabled
}) => (
  <FormControl margin="normal" fullWidth>
    <FileDropzone
      text={text}
      acceptedFiles={acceptedFiles}
      onChange={uploadFileToAWS}
      onSuccess={onSuccess}
      onFail={onFail}
      maxFileSize={maxFileSize}
      limit={limit}
      disabled={disabled}
    />
  </FormControl>
);

FormTargetMediaAdd.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  limit: PropTypes.number,
  maxFileSize: PropTypes.number,
  text: PropTypes.string,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool
};

FormTargetMediaAdd.defaultProps = {
  onSuccess: noopPromise,
  onFail: noopPromise,
  limit: 30,
  maxFileSize: 314572800,
  text: 'Upload Media',
  acceptedFiles: ['image/*', 'video/*'],
  disabled: false
};

export default memo(FormTargetMediaAdd);
