import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PaginationLeft from '../../icons/PaginationLeft';
import PaginationFirst from '../../icons/PaginationFirst';
import PaginationRight from '../../icons/PaginationRight';
import PaginationLast from '../../icons/PaginationLast';

import style from './Pagination.scss';

const Pagination = ({
  total,
  activePage,
  perPage,
  handlePageChange,
  handleChangePageSize
}) => {
  const calculatePageCount = () => {
    const pages = [];
    const pageCount = Math.ceil(total / perPage);
    if (pageCount <= 7) {
      for (let i = 1; i <= pageCount; i++) {
        pages.push(i);
      }
    } else {
      if (activePage + 4 < pageCount) {
        for (let i = activePage; i <= activePage + 4; i++) {
          pages.push(i);
        }
      } else {
        for (let i = pageCount - 4; i <= pageCount; i++) {
          pages.push(i);
        }
      }
      if (activePage + 4 < pageCount) {
        pages.push('...');
        pages.push(pageCount);
      } else {
        pages.unshift('...');
        pages.unshift(1);
      }
    }
    return pages;
  };

  const nexPage = () => {
    if (activePage < total / perPage) {
      handlePageChange(activePage + 1);
    }
  };
  const previousPage = () => {
    if (activePage > 1) {
      handlePageChange(activePage - 1);
    }
  };
  const changePageSize = (event) => {
    const { value } = event.target;
    handleChangePageSize(parseInt(value, 10));
  };
  const changePage = (page) => {
    handlePageChange(page);
  };

  return (
    <div className={style.component}>
      <div className={style.action}>
        <button
          type="button"
          aria-label="first"
          onClick={() => handlePageChange(1)}
          className={`${style.button} ${activePage === 1 ? style.grayBtn : style.buttonActive}`}
        >
          <PaginationFirst />
        </button>
        <button
          type="button"
          aria-label="prev"
          onClick={previousPage}
          className={`${style.button} ${activePage === 1 ? style.grayBtn : style.buttonActive}`}
        >
          <PaginationLeft />
        </button>
        <span>
          <span>
            {calculatePageCount().map((p) => (
              <button
                type="button"
                key={p}
                className={`${style.number} ${activePage === p ? style.black : style.gray}`}
                onClick={() => changePage(Number(p))}
              >
                {p}
              </button>
            ))}
          </span>
        </span>
        <button
          type="button"
          aria-label="next"
          onClick={nexPage}
          className={`${style.button} ${activePage < total / perPage ? style.buttonActive : style.grayBtn}`}
        >
          <PaginationRight />
        </button>
        <button
          type="button"
          aria-label="last"
          className={`${style.button} ${activePage < total / perPage ? style.buttonActive : style.grayBtn}`}
          onClick={() => handlePageChange(Math.ceil(total / perPage))}
        >
          <PaginationLast />
        </button>
      </div>
      <div className={style.changeHolder}>
        <b className={style.descr}>Rows per page</b>
        <select
          className={style.select}
          onChange={changePageSize}
        >
          {[10, 20, 50].map((o) => (
            <option value={o} key={o}>
              {o}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleChangePageSize: PropTypes.func.isRequired
};

export default memo(Pagination);
