import React, {
  useCallback,
  memo
} from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';

import { noop } from '../../../utils';

import style from './Switch.scss';

const Switch = ({
  id,
  name,
  onChange,
  checked,
  className,
  label,
  is2FA,
  errors,
  disabled,
  ...props
}) => {
  const onChangeProxy = useCallback(({ target }) => {
    onChange(target.checked, id);
  }, [id, onChange]);

  const switchClasses = `${style.switch} ${className} ${checked ? style.checked : ''}`.trim();
  const inputId = `switch-${id}`;

  return (
    <label className={switchClasses} htmlFor={inputId}>
      {!is2FA && <span className={style.label}>{label}</span>}
      <input
        id={inputId}
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChangeProxy}
        disabled={disabled}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
      <span className={style.slider} />
      {is2FA && <span className={style.labelBig}>{label}</span>}
      {is2FA && errors ? (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      ) : null}
    </label>
  );
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  is2FA: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Switch.defaultProps = {
  name: '',
  onChange: noop,
  checked: true,
  className: '',
  label: 'Sound',
  is2FA: false,
  disabled: false,
  errors: ''
};

export default memo(Switch);
