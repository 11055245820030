import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';
import FileDropzone from '../../UI/FileDropzone';

import { noopPromise } from '../../../utils';

const FormTargetVideoAdd = ({
  onSubmit,
  onSuccess
}) => (
  <div>
    <FormControl margin="normal" fullWidth>
      <FileDropzone
        text="Drag and drop a video here or click"
        acceptedFiles={['video/*']}
        onChange={onSubmit}
        onSuccess={onSuccess}
        maxFileSize={314572800}
      />
    </FormControl>
  </div>
);

FormTargetVideoAdd.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func
};

FormTargetVideoAdd.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise
};

export default memo(FormTargetVideoAdd);
