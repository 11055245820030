import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { isNotEmptyArray, noopPromise } from '../../../utils';

import Button from '../../UI/Button';
import Loader from '../../UI/Loader/Loader';
import ErrorMessage from '../../UI/ErrorMessage';
import InputText from '../../UI/InputText';
import Heading from '../../UI/Heading';

import style from './FormCreateEvent.scss';

const DATA = {
  eventName: '',
  eventDate: ''
};
const RULES = {
  eventName: {
    eventName: true,
    required: true
  },
  eventDate: {
    required: true
  }
};

const FormCreateEvent = ({
  onSubmit,
  onSuccess
}) => {
  const [value, setValue] = useState(''); // eslint-disable-line no-unused-vars

  function handleSuccess(action) {
    onSuccess(action.request.response.data.id);
  }

  const {
    onFormSubmit, onFieldChange, formState, processing, errors
  } = useFormState(DATA, RULES, onSubmit, handleSuccess);
  const isFormValue = () => formState.eventName && formState.eventDate;

  return (
    <form className={style.formHolder} onSubmit={onFormSubmit} noValidate>
      <Heading level={2} weight={500}>
        Create Event
      </Heading>
      <FormControl margin="normal" fullWidth>
        <InputText
          id="eventName"
          name="eventName"
          placeholder={value ? '' : 'Name'}
          disabled={processing}
          errors={errors.eventName}
          value={formState.eventName}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputText
          id="emaildate"
          name="eventDate"
          type="date"
          placeholder={value ? '' : 'Date'}
          disabled={processing}
          errors={errors.eventDate}
          value={formState.eventDate}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <Button type="submit" disabled={!isFormValue()} fullWidth>
          Create
        </Button>
      </div>
      {processing && <Loader />}
    </form>
  );
};

FormCreateEvent.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func
};

FormCreateEvent.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise
};

export default memo(FormCreateEvent);
