import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import style from './Link.scss';

const Link = ({ children, ...props }) => (
  <RouterLink
    className={style.component}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {children}
  </RouterLink>
);

Link.propTypes = {
  children: PropTypes.node
};

Link.defaultProps = {
  children: null
};

export default memo(Link);
