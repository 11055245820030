/* eslint-disable max-len */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise } from '../../../utils';

import { getIsFetchingSelector, goalTarget } from '../../../redux/target/selectors';
import InputText from '../../UI/InputText';
import ErrorMessage from '../../UI/ErrorMessage';
import Heading from '../../UI/Heading';
import DragNDrop from '../../DragNDrop/DrugNDrop';
import { fetchEventsCount, fetchEventsList } from '../../../redux/events/actions';
import { selectEventsList, selectNewEvent } from '../../../redux/events/selectors';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button';
import { deleteTarget, updateDataTargets } from '../../../redux/target/actions';
import Popup from '../../Popup/Popup';
import FormCreateEvent from '../FormCreateEvent';
import CustomSelect from '../CustomSelect/CustomSelect';
import sortMedia from '../../../utils/sortMedia';
import CustomButton from '../../UI/CustomButton';
import Alert from '../../UI/Alert';
import FormTargetMediaAdd from '../FormTargetMediaAdd/FormTargetMediaAdd';
import { REMOVE_FILE_FROM_UPLOAD, UPLOAD_MEDIA, UPLOAD_MEDIA_SUCCESS } from '../../../redux/target/constants';

import style from './FormEditTarget.scss';

const RULES = {
  title: {
    required: true,
    custom: (value) => value.length > 150 && 'Title must be less than 150 characters length'
  }
};

const FormEditTarget = ({
  onMount, onSubmit, onSuccess, onFail, editTargetId
}) => {
  const dispatch = useDispatch();
  const editTarget = useSelector(goalTarget);
  const isFetching = useSelector(getIsFetchingSelector);
  const newEvent = useSelector(selectNewEvent);
  const events = useSelector(selectEventsList);
  const {
    sessionId, sessionName, eventId, isSingle, targets
  } = editTarget;
  const [selectedEvent, setSelectedEvent] = useState(eventId);
  const [sessionTitle, setSessionTitle] = useState('');
  const [targetsData, setTargetsData] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isPopupEventOpen, setIsPopupEventOpen] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    type: 'success',
    message: ''
  });

  const toggleEditEvent = () => {
    setEditEvent(!editEvent);
  };

  useEffect(() => {
    if (onMount) {
      onMount();
      dispatch(fetchEventsList());
    }
  }, [onMount, dispatch]);

  useEffect(() => {
    setSessionTitle(sessionName);
    if (eventId) {
      setSelectedEvent(eventId);
    }
    setTargetsData(targets);
    const photo = [];
    const video = [];
    const mediaFiles = [];
    targets?.forEach((t) => {
      photo.push({ photo: t.photoUrl });
      video.push({ video: t.videoUrl });

      const photoFileName = t.photoUrl ? t.photoUrl.split('_').pop() : null;
      const videoFileName = t.videoUrl ? t.videoUrl.split('_').pop() : null;
      if (photoFileName) {
        const photoFile = new File([], photoFileName);
        mediaFiles.push(photoFile);
      }
      if (videoFileName) {
        const videoFile = new File([], videoFileName);
        mediaFiles.push(videoFile);
      }
    });
    setVideos(video);
    setImages(photo);
    if (mediaFiles.length > 0) {
      dispatch({ type: UPLOAD_MEDIA, payload: mediaFiles });
      dispatch({ type: UPLOAD_MEDIA_SUCCESS });
    }
  }, [sessionName, eventId, isSingle, targets, editTarget, dispatch]);

  const openPopupCreateEvent = useCallback(() => {
    setIsPopupEventOpen(true);
  }, []);

  const closePopupEvent = useCallback(() => {
    setIsPopupEventOpen(false);
  }, []);

  useEffect(() => {
    if (isPopupEventOpen) {
      setTimeout(() => {
        dispatch(fetchEventsList());
        dispatch(fetchEventsCount());
      }, 1000);
      closePopupEvent();
    }
  }, [newEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = (message, type = 'error') => {
    setAlertMsg({ open: true, type, message });
  };

  const handleCloseAlert = () => {
    setAlertMsg({ ...alertMsg, open: false });
  };

  const handleDeleteItem = (url, index) => {
    const fileName = url.split('_').pop();
    dispatch({ type: REMOVE_FILE_FROM_UPLOAD, payload: fileName });

    let shouldRemoveTarget = false;

    if (url.includes('photos')) {
      setTargetsData((prevTargets) => {
        const updatedTargets = prevTargets.map((target, i) => {
          if (i === index) {
            if (Object.prototype.hasOwnProperty.call(target, 'photoVector')) {
              return {
                ...target,
                photoUrl: null,
                photoVector: null
              };
            }
            return {
              ...target,
              photoUrl: null
            };
          }
          return target;
        });
        if (updatedTargets[index].videoUrl === null) {
          shouldRemoveTarget = true;
        }
        return updatedTargets;
      });
      setImages((prevImages) =>
        // eslint-disable-next-line
        prevImages.map((image, i) => {
          if (i === index) {
            const updatedImage = { ...image, photo: null, photoUrl: null };

            if ('photoVector' in image) {
              updatedImage.photoVector = null;
            }

            return updatedImage;
          }

          return image;
        }));
    } else if (url.includes('videos')) {
      setTargetsData((prevTargets) => {
        const updatedTargets = prevTargets.map((target, i) => {
          if (i === index) {
            return { ...target, videoUrl: null };
          }
          return target;
        });
        if (updatedTargets[index].photoUrl === null) {
          shouldRemoveTarget = true;
        }
        return updatedTargets;
      });
      setVideos((prev) => prev.map((video, i) => {
        if (i === index) {
          return { ...video, video: null, videoUrl: null };
        }
        return video;
      }));
    }
    if (shouldRemoveTarget) {
      setTargetsData((prevTargets) => prevTargets.filter((_, i) => i !== index));
      setImages((prev) => prev.filter((_, i) => i !== index));
      setVideos((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleSessionNameChange = (event) => {
    setSessionTitle(event);
  };

  const handleEventSelect = (e) => {
    const eventValue = e;
    if (eventValue === 'create') {
      openPopupCreateEvent();
    }

    const selectedId = e;
    const foundEvent = events.find((event) => event.id === selectedId);
    if (foundEvent) {
      setSelectedEvent(selectedId);
    }
  };

  const {
    processing, errors
  } = useFormState(RULES, onSubmit, onSuccess, onFail);

  const handleFormSubmit = async(event) => {
    event.preventDefault();
    const updatedTargets = targetsData?.reduce((acc, item) => {
      const el = {
        ...item,
        photoUrl: item.photoUrl,
        photoVector: item.photoVector,
        videoUrl: item.videoUrl
      };
      if (!el.photoVector) {
        delete el.photoVector;
      }
      if (!el.targetId) {
        delete el.targetId;
      }
      acc.push(el);
      return acc;
    }, []);

    const targetsToDelete = targets?.filter((target) => {
      const foundInUpdated = targetsData.some((t) => t.targetId === target.targetId);
      return !foundInUpdated;
    });
    try {
      await Promise.all(targetsToDelete.map((target) => dispatch(deleteTarget({ targetId: target.targetId }))));
    } catch (error) {
    }

    const data = new FormData();
    if (updatedTargets.length > 1) {
      data.append('sessionName', sessionTitle || '');
    }
    data.append('sessionId', sessionId);
    if (selectedEvent) {
      data.append('eventId', selectedEvent);
    }
    data.append('targets', JSON.stringify(updatedTargets));
    try {
      if (sessionName !== sessionTitle && updatedTargets.length > 1) {
        const updateSessionNameData = new FormData();
        updateSessionNameData.append('sessionId', sessionId);
        updateSessionNameData.append('sessionName', sessionTitle || '');
        if (selectedEvent) {
          updateSessionNameData.append('eventId', selectedEvent);
        }
        await dispatch(updateDataTargets(updateSessionNameData));
      }
      await dispatch(updateDataTargets(data));
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error creating targets:", error);
    }
  };

  const onFileUpload = (action) => {
    const isImage = action.url.includes('photos');
    const isVideo = action.url.includes('videos');
    const fileName = action?.file.name;
    let newTarget = {};
    const { url } = action;

    if (isImage) {
      const isDuplicate = images.some((img) => img.photo?.includes(fileName));
      if (!isDuplicate) {
        newTarget = { photo: url };
        setImages((prevState) => {
          const emptyIndex = prevState.findIndex((i) => !i.photo);
          const newImages = [...prevState];
          if (emptyIndex !== -1) {
            newImages[emptyIndex] = newTarget;
          } else {
            newImages.push(newTarget);
          }
          return sortMedia(newImages);
        });
      } else {
        showAlert(`Photo ${fileName} already exists in the target`);
      }
    } else if (isVideo) {
      const isDuplicate = videos.some((vid) => vid.video?.includes(fileName));

      if (!isDuplicate) {
        newTarget = { video: url };
        setVideos((prevState) => {
          const emptyIndex = prevState.findIndex((i) => !i.video);
          const newVideos = [...prevState];
          if (emptyIndex !== -1) {
            newVideos[emptyIndex] = newTarget;
          } else {
            newVideos.push(newTarget);
          }
          return sortMedia(newVideos);
        });
      } else {
        showAlert(`Video ${fileName} already exists in the target`);
      }
    }
  };

  useEffect(() => {
    const emptyImageIndexes = images.reduce((acc, item, index) => {
      if (!item.photo && !item.photoUrl) {
        acc.push(index);
      }
      return acc;
    }, []);

    const emptyVideoIndexes = videos.reduce((acc, item, index) => {
      if (!item.video && !item.videoUrl) {
        acc.push(index);
      }
      return acc;
    }, []);
    const [commonEmptyIndex] = emptyImageIndexes.filter((index) => emptyVideoIndexes.includes(index));
    if (commonEmptyIndex !== undefined) {
      setImages((prev) => prev.filter((_, i) => i !== commonEmptyIndex));
      setVideos((prev) => prev.filter((_, i) => i !== commonEmptyIndex));
    }
  }, [targetsData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isButtonDisabled = useMemo(() => {
    const pairs = targetsData?.some((target) => target.photoUrl == null || target.videoUrl == null);
    const disabled = targetsData?.length === 0 || pairs || isFetching;
    return disabled;
  }, [targetsData, isFetching]);

  const showSessionNameField = images?.length >= 2 || videos?.length >= 2;

  return (
    <>
      <form className={style.form} onSubmit={handleFormSubmit} noValidate>
        <Heading align="center" level={2} weight={500} fSize={24} mBottom={14}>
          Edit Target
        </Heading>
        <DragNDrop
          targets={targetsData}
          images={images}
          videos={videos}
          handleDeleteItem={handleDeleteItem}
          setSingleImage={setImages}
          setSingleVideo={setVideos}
          setPairedData={setTargetsData}
          isCreateForm={false}
          editTargetId={editTargetId}
        />
        <div className={style.dragHolder}>
          <FormTargetMediaAdd
            text="Upload Pictures and Videos"
            acceptedFiles={['image/*', 'video/*']}
            onSuccess={onFileUpload}
            disabled={!!editTargetId}
          />
        </div>
        <div className={style.inputHolder}>
          {showSessionNameField ? (
            <FormControl margin="none" fullWidth>
              <InputText
                type="text"
                id="title"
                name="title"
                placeholder="Session name"
                errors={errors.title}
                disabled={processing}
                value={sessionTitle}
                onChange={handleSessionNameChange}
                fullWidth
              />
            </FormControl>
          ) : null}
          <div className={style.eventEditHolder}>
            <CustomSelect
              className={`${!editEvent ? style.disabledSelect : ''}`}
              placeholder="Select Event..."
              label="Choose Event"
              options={events}
              selectedValue={selectedEvent}
              onSelect={handleEventSelect}
              onCreateEvent={openPopupCreateEvent}
              disabled={!editEvent}
              editEvent={editEvent}
            />
            <CustomButton
              className={style.editSelectBtn}
              type="button"
              fullWidth
              onClick={toggleEditEvent}
            >
              {editEvent ? 'Cancel' : 'Edit'}
            </CustomButton>
          </div>
        </div>

        {errors.detail && (
          <div className={style.nonFieldError}>
            <ErrorMessage>{errors.detail}</ErrorMessage>
          </div>
        )}

        <Alert
          open={alertMsg.open}
          handleCloseAlert={handleCloseAlert}
          type={alertMsg.type}
        >
          {alertMsg.message}
        </Alert>

        <div className={style.submitButton}>
          <Button
            type="submit"
            disabled={isButtonDisabled}
            fullWidth
          >
            Save
          </Button>
        </div>
        {processing && <Loader />}
      </form>
      <Popup open={isPopupEventOpen} handleClose={closePopupEvent}>
        <FormCreateEvent />
      </Popup>
    </>
  );
};

FormEditTarget.propTypes = {
  onMount: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  editTargetId: PropTypes.number
};

FormEditTarget.defaultProps = {
  onMount: noopPromise,
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise,
  editTargetId: null
};

export default memo(FormEditTarget);
