import React from 'react';
import PropTypes from 'prop-types';

import style from './CustomButton.scss';

const CustomButton = ({
  className,
  variant,
  disabled,
  type,
  children,
  ...props
}) => {
  const classes = `${style.customButton} ${style[variant]} ${className}`;

  return (
    <button
      className={classes}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node.isRequired
};

CustomButton.defaultProps = {
  className: '',
  variant: 'primary',
  disabled: false,
  type: 'button'
};

export default CustomButton;
