import { noop } from '../../utils';

const createErrorsMiddleware = (onError = noop) => () => (next) => (action) => {
  if (!action) {
    return next(action);
  }

  const { request, status } = action;

  if (request && status === 'FAIL' && request.response) {
    onError(action);
  }

  return next(action);
};

export default createErrorsMiddleware;
