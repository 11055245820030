import React, {
  memo, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Heading from '../../components/UI/Heading';
import CustomButton from '../../components/UI/CustomButton';
import Popup from '../../components/Popup/Popup';
import Pagination from '../../components/Pagination/Pagination';
import TargetList from '../../components/TargetList';
import SearchInput from '../../components/UI/SearchInput';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import events from '../../jsons/events.json';
import targetsList from '../../jsons/targetsList.json';
import FormCreateTarget from '../../components/Forms/FormCreateTarget';
import { fetchTargetsCount, getTargetsList, resetState } from '../../redux/target/actions';

import { RESET_EDIT_POPUP_STATE, RESET_NEW_TARGET } from '../../redux/target/constants';
import { noopPromise } from '../../utils';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

import style from './TargetDetail.scss';

const TargetDetail = ({
  onMount,
  onSearch,
  onSearchCancel
}) => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [isPopupCreateTargetOpen, setIsPopupCreateTargetOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    perPage: 10
  });

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handleOpenCreatePopup = useCallback(() => {
    dispatch({ type: RESET_NEW_TARGET });
    setIsPopupCreateTargetOpen(true);
  }, [setIsPopupCreateTargetOpen, dispatch]);

  const closePopupCreateTarget = useCallback(() => {
    setIsPopupCreateTargetOpen(false);
    dispatch({ type: RESET_EDIT_POPUP_STATE });
  }, [setIsPopupCreateTargetOpen, dispatch]);

  const onTargetCreateSuccess = useCallback(() => {
    setTimeout(() => {
      dispatch(fetchTargetsCount());
      dispatch(getTargetsList());
      dispatch(resetState());
    }, 1000);
    closePopupCreateTarget();
  }, [dispatch, closePopupCreateTarget]);

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, pageNumber: page }));
  };
  const handleChangePageSize = (size) => {
    setPagination((prev) => ({ ...prev, perPage: size, pageNumber: 1 }));
  };

  const event = events.find((el) => el.id === eventId);
  if (!event) {
    return <div>Event not found</div>;
  }
  const { title, date } = event;
  return (
    <div className={style.component}>
      <div className={style.titleHolder}>
        <Heading weight={500}>
          {capitalizeFirstLetter(title)}
        </Heading>
        <strong className={style.date}>{date}</strong>
        <CustomButton
          type="button"
          variant="primary"
          className={style.btn}
          onClick={handleOpenCreatePopup}
        >
          Create Target
        </CustomButton>
      </div>
      <Breadcrumb items={[]} />
      <SearchInput
        style={{ marginTop: '24px', marginBottom: '24px' }}
        // onChange={handleSearch}
        onCancelSearch={onSearchCancel}
        onChange={onSearch}
      />
      <div className={style.titleHolder}>
        <Heading weight={500}>
          List of targets
        </Heading>
        <strong className={style.count}>{targetsList.length}</strong>
      </div>
      <TargetList targets={targetsList} />
      {targetsList.length > 0 ? (
        <Pagination
          total={targetsList.length}
          activePage={pagination.pageNumber}
          perPage={pagination.perPage}
          handlePageChange={handlePageChange}
          handleChangePageSize={handleChangePageSize}
        />
      ) : null}
      <Popup
        open={isPopupCreateTargetOpen}
        handleClose={closePopupCreateTarget}
        size="529px"
        closeIconWidth={32}
        closeIconHeight={32}
      >
        <FormCreateTarget onSuccess={onTargetCreateSuccess} />
      </Popup>
    </div>
  );
};

TargetDetail.propTypes = {
  onMount: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchCancel: PropTypes.func
};

TargetDetail.defaultProps = {
  onMount: noopPromise,
  onSearch: noopPromise,
  onSearchCancel: noopPromise
};

export default memo(TargetDetail);
