import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';
import Button from '../Button';

import style from './ButtonWithSpinner.scss';

const ButtonWithSpinner = ({
  processing, disabled, children, ...props
}) => (
  <Button
    disabled={disabled || processing}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    <span className={style.inner}>
      {children}

      {processing && (
        <span className={style.spinner}>
          <CircularProgress size={20} />
        </span>
      )}
    </span>
  </Button>
);

ButtonWithSpinner.propTypes = {
  processing: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

ButtonWithSpinner.defaultProps = {
  processing: false,
  disabled: false,
  children: null
};

export default memo(ButtonWithSpinner);
