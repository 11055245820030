export const SET_TOKEN = 'auth/SET_TOKEN';
export const DELETE_TOKEN = 'auth/DELETE_TOKEN';
export const ADD_DEFERRED_ACTION = 'auth/ADD_DEFERRED_ACTION';
export const CLEAR_DEFERRED_ACTIONS = 'auth/CLEAR_DEFERRED_ACTIONS';
export const POST_REGISTRATION = 'auth/POST_REGISTRATION';
export const POST_REGISTRATION_RESEND = 'auth/POST_REGISTRATION_RESEND';
export const POST_REGISTRATION_CONFIRM = 'auth/POST_REGISTRATION_CONFIRM';
export const POST_PASSWORD_RESET_REQUEST = 'auth/POST_PASSWORD_RESET_REQUEST';
export const POST_PASSWORD_RESET = 'auth/POST_PASSWORD_RESET';
export const POST_LOGIN = 'auth/POST_LOGIN';
export const POST_LOGIN_SUCCESS = 'auth/POST_LOGIN_SUCCESS';
export const POST_OAUTH_CODE = 'auth/POST_OAUTH_CODE';
export const POST_REFRESH_TOKEN = 'auth/POST_REFRESH_TOKEN';
export const POST_CONFIRMATION_CODE = 'auth/POST_CONFIRMATION_CODE';
export const POST_CONFIRMATION_CODE_RESEND = 'auth/POST_CONFIRMATION_CODE_RESEND';
