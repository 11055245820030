import { createActionRequestWithAuth } from '../utils';
import { GET_ACCOUNT, UPDATE_ACCOUNT, UPDATE_PASSWORD } from './constants';

export const getAccount = (userId) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_ACCOUNT,
  request: {
    method: 'GET',
    endpoint: `accounts/${userId}/`
  }
}));

export const updateAccount = (userId, data) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: UPDATE_ACCOUNT,
  request: {
    method: 'PUT',
    endpoint: `accounts/${userId}/`,
    data
  }
}));

export const updatePassword = ({
  oldPassword,
  password,
  passwordConfirmation
}) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: UPDATE_PASSWORD,
  request: {
    method: 'PUT',
    endpoint: 'accounts/update_password/',
    data: {
      oldPassword,
      password,
      passwordConfirmation
    }
  }
}));
