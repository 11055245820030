import { createSelector } from 'reselect';

export const createReducer = (initialState, reducersMap = {}) => (state = initialState, action = {}) => {
  const reducer = reducersMap[action.type];
  return reducer ? reducer(state, action) : state;
};

export const createRequestReducer = (state, action, reducerMap) => {
  const reducer = reducerMap[action.status];
  return reducer ? reducer(state, action) : state;
};

export const createActionRequestWithAuth = (action) => {
  const { request, token: queryToken } = action;
  const token = localStorage.getItem('auth_token') || queryToken;

  if (!request || !token) {
    return action;
  }

  return {
    ...action,
    request: {
      ...request,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  };
};

export const defaultFetchDataState = {
  data: null,
  fetching: false
};

export const defaultFetchReducer = (state, action, reducerMap = {}) => createRequestReducer(state, action, {
  SEND: () => ({
    ...state,
    fetching: true
  }),

  SUCCESS: () => ({
    ...state,
    fetching: false,
    data: action.request.response.data
  }),

  FAIL: () => ({
    ...state,
    fetching: false
  }),

  ...reducerMap
});

export const defaultFetchingSelector = (slice, defaultValue = false) => createSelector(
  slice,
  ({ fetching }) => fetching || defaultValue
);

export const defaultDataSelector = (slice, defaultValue = null) => createSelector(
  slice,
  ({ data }) => data || defaultValue
);

export const defaultErrorsSelector = (slice, defaultValue = null) => createSelector(
  slice,
  ({ errors }) => errors || defaultValue
);
