import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const ErrorMessage = ({ children, ...props }) => (
  <Typography
    color="error"
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {children}
  </Typography>
);

ErrorMessage.propTypes = {
  children: PropTypes.node
};

ErrorMessage.defaultProps = {
  children: null
};

export default memo(ErrorMessage);
