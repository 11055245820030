/* eslint-disable max-len */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise, isNotEmptyArray } from '../../../utils';

import InputText from '../../UI/InputText';
import InputPassword from '../../UI/InputPassword';
import ErrorMessage from '../../UI/ErrorMessage';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button';

import style from './FormRegistration.scss';

const DATA = {
  email: '',
  name: '',
  password: '',
  passwordConfirmation: ''
};

const RULES = {
  email: {
    email: true,
    required: true
  },
  name: {
    required: true
  },
  password: {
    required: true,
    custom: (value) => value.length < 8 && 'Password must be at least 8 characters length'
  },
  passwordConfirmation: {
    required: true,
    custom: (value, formState) => value !== formState.password && 'Passwords do not match'
  }
};

const FormRegistration = ({ onSubmit, onSuccess, onFail }) => {
  const {
    onFormSubmit, onFieldChange, formState, processing, errors
  } = useFormState(DATA, RULES, onSubmit, onSuccess, onFail);

  const [value, setValue] = useState(''); // eslint-disable-line no-unused-vars

  const isFormValue = () => formState.name && formState.email && formState.password && formState.passwordConfirmation;
  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputText
          id="name"
          name="name"
          placeholder={value ? '' : 'Your name'}
          disabled={processing}
          errors={errors.name}
          value={formState.name}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputText
          id="email"
          name="email"
          type="email"
          placeholder={value ? '' : 'Your email'}
          disabled={processing}
          errors={errors.email}
          value={formState.email}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="password"
          name="password"
          placeholder={value ? '' : 'Password'}
          disabled={processing}
          errors={errors.password}
          value={formState.password}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="passwordConfirmation"
          name="passwordConfirmation"
          placeholder={value ? '' : 'Confirm password'}
          disabled={processing}
          errors={errors.passwordConfirmation}
          value={formState.passwordConfirmation}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <Button type="submit" disabled={!isFormValue()} fullWidth>
          Sign up
        </Button>
      </div>
      {processing && <Loader />}
    </form>
  );
};

FormRegistration.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

FormRegistration.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormRegistration);
