import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Heading from '../../components/UI/Heading';
import Panel from '../../components/UI/Panel';
import FormTargetVideoAdd from '../../components/Forms/FormTargetVideoAdd';
import FormCreateTarget from '../../components/Forms/FormCreateTarget';
import UploadMediaControl from '../../components/UploadMediaControl';

import { noop } from '../../utils';

import style from './AddTarget.scss';
import FormTargetMediaAdd from '../../components/Forms/FormTargetMediaAdd/FormTargetMediaAdd';

const AddTarget = ({
  targetImageSource,
  targetVideoSource,
  onTargetUploadSuccess,
  onImageUploadSuccess,
  onVideoUploadSuccess
}) => {
  const { push } = useHistory();

  const handleTargetUploadSuccess = useCallback(() => {
    onTargetUploadSuccess();
    push('/targets/');
  }, [onTargetUploadSuccess, push]);

  return (
    <div className={style.component}>
      <Heading>
        Add Target
      </Heading>

      <div className={style.section}>
        <Panel width="100%">
          <Heading level={2}>
            1) Upload target image
          </Heading>

          <UploadMediaControl mediaSource={targetImageSource}>
            <FormTargetMediaAdd onSuccess={onImageUploadSuccess} />
          </UploadMediaControl>
        </Panel>
      </div>

      <div className={style.section}>
        <Panel width="100%">
          <Heading level={2}>
            2) Upload target video
          </Heading>

          <UploadMediaControl mediaSource={targetVideoSource} type="video">
            <FormTargetVideoAdd onSuccess={onVideoUploadSuccess} />
          </UploadMediaControl>
        </Panel>
      </div>

      <div className={style.section}>
        <Panel width="100%">
          <Heading level={2}>
            3) Create target
          </Heading>
          <FormCreateTarget onSuccess={handleTargetUploadSuccess} />
        </Panel>
      </div>
    </div>
  );
};

AddTarget.propTypes = {
  targetImageSource: PropTypes.string,
  targetVideoSource: PropTypes.string,
  onTargetUploadSuccess: PropTypes.func,
  onImageUploadSuccess: PropTypes.func,
  onVideoUploadSuccess: PropTypes.func
};

AddTarget.defaultProps = {
  targetImageSource: null,
  targetVideoSource: null,
  onTargetUploadSuccess: noop,
  onImageUploadSuccess: noop,
  onVideoUploadSuccess: noop
};

export default memo(AddTarget);
