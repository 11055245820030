import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Home from '../../icons/Home';
import Next from '../../icons/Next';

import style from './Breadcrumb.scss';

const Breadcrumb = ({
  items
}) => {
  const breadcrumbItem = items.map((part, index) => {
    const { name, path } = part;
    const isLastItem = index === items.length - 1;
    const isDisabled = name === 'Without Event';

    if (isLastItem || isDisabled) {
      return (
        <li key={name} className={style.breadcrumbItem}>
          <Next />
          <span className={`${style.title} ${isDisabled ? style.disabledLink : ''}`}>
            {name}
          </span>
        </li>
      );
    }

    return path ? (
      <li key={name} className={style.breadcrumbItem}>
        <Next />
        <NavLink to={path} className={style.link}>
          <strong className={style.title}>{name}</strong>
        </NavLink>
      </li>
    ) : (
      <li key={name} className={style.breadcrumbItem}>
        <Next />
        <span className={style.title}>{name}</span>
      </li>
    );
  });

  return (
    <nav className={style.breadcrumb}>
      <ul className={style.breadcrumbList}>
        <li key="dashboard" className={style.breadcrumbItem}>
          <NavLink exact to="/" className={style.link}>
            <Home />
            <strong className={style.title}>Dashboard</strong>
          </NavLink>
        </li>
        {breadcrumbItem}
      </ul>
    </nav>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.array
};

Breadcrumb.defaultProps = {
  items: []
};

export default memo(Breadcrumb);
