import { connect } from 'react-redux';

import { registrationConfirm } from '../../../redux/auth/actions';

import ConfirmEmail from './ConfirmEmail';

const mapDispatchToProps = {
  registrationConfirm
};

export default connect(null, mapDispatchToProps)(ConfirmEmail);
