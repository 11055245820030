import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SearchBar from 'material-ui-search-bar';

import useDebounce from '../../../hooks/useDebounce';

import { noopPromise } from '../../../utils';

const SearchInput = ({
  value,
  onChange,
  onCancelSearch,
  style
}) => {
  const debouncedOnChange = useDebounce(onChange, 500);
  return (
    <SearchBar
      style={style}
      value={value}
      onChange={debouncedOnChange}
      onCancelSearch={onCancelSearch}
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onCancelSearch: PropTypes.func
};

SearchInput.defaultProps = {
  value: '',
  style: {},
  onChange: noopPromise,
  onCancelSearch: noopPromise
};

export default memo(SearchInput);
