import React, { useEffect, StrictMode, memo } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import Router from './Router';
import Layout from '../components/Layout';

import { noop } from '../utils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D70059'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        border: '1px solid #d1d1d1',
        borderRadius: '12px',
        boxShadow: 'none',
        color: 'inherit'
      },
      elevation1: {
        border: '1px solid #d1d1d1',
        borderRadius: '12px',
        boxShadow: 'none'
      }
    },
    MuiIconButton: {
      label: {
        color: 'rgba(255, 113, 149, 0.3)'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 12,
        padding: '12px 24px',
        color: 'inherit',
        minWidth: 'auto'
      },
      text: {
        padding: 0
      },
      containedPrimary: {
        backgroundColor: '#D70059',
        color: '#fff',
        boxShadow: '0 0 0 2px rgba(215, 0, 89, 0.2)',
        '&:hover': {
          color: '#fff',
          boxShadow: '0 0 0 2px rgba(215, 0, 89, 0.2)',
          background: 'rgba(0, 0, 0, 0.05)'
        },
        '&:disabled': {
          backgroundColor: 'rgba(215, 0, 89, 0.1)',
          color: 'rgba(215, 0, 89, 0.2)',
          boxShadow: 'none'
        }
      }
    },
    MuiTextField: {
      root: {
        borderRadius: 12,
        backgroundColor: '#fff',
        '& + div': {
          'text-align': 'left'
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '12px 16px',
        height: '24px',
        lineHeight: 1.2,
        fontSize: 14,
        '&:is([type="date"])': {
          cursor: 'pointer'
        },
        '&::-webkit-calendar-picker-indicator': {
          cursor: 'pointer'
        },
        '&::-calendar-picker-indicator': {
          cursor: 'pointer'
        }
      }
    },
    MuiInputBase: {
      root: {
        '&.MuiOutlinedInput-root': {
          borderRadius: 12,
          '& fieldset': {
            borderColor: '#d1d1d1',
            borderRadius: 12
          },
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.4)'
          },
          '&.Mui-focused fieldset': {
            borderColor: '#d1d1d1'
          }
        }
      }
    },
    MuiTypography: {
      h1: {
        fontSize: '1.5rem',
        fontFamily: 'inherit'
      },
      h2: {
        fontSize: '0.875rem',
        fontFamily: 'inherit'
      },
      h3: {
        fontSize: '1rem',
        fontFamily: 'inherit'
      },
      h5: {
        fontSize: '0.625rem',
        fontFamily: 'inherit',
        fontWeight: '500'
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          backgroundColor: 'transparent',
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
            borderRadius: '12px'
          }
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiDialogContent: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '18px !important',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        '@media (min-width: 768px)': {
          flexDirection: 'row',
          padding: '20px !important'
        }
      }
    },
    MuiDropzoneArea: {
      root: {
        borderWidth: '2px',
        minHeight: '40px',
        overflow: 'visible'
      },
      textContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap'
      },
      icon: {
        width: '16px',
        height: '16px',
        marginRight: '6px',
        flexShrink: 0
      },
      text: {
        marginTop: '13px',
        marginBottom: '13px'
      }
    }
  }
});

const App = ({ store, onMount }) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <StrictMode>
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Layout>
                <Router />
              </Layout>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </StrictMode>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
  onMount: PropTypes.func
};

App.defaultProps = {
  onMount: noop
};

export default memo(App);
