import React, {
  memo
} from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise } from '../../../utils';

import InputText from '../../UI/InputText';
import ErrorMessage from '../../UI/ErrorMessage';
import ButtonWithSpinner from '../../UI/ButtonWithSpinner';

import style from './FormScaleEdit.scss';

const RULES = {
  title: {
    required: true,
    custom: (value) => value.length > 150 && 'Title must be less than 150 characters length'
  },
  scale: {
    required: true
  }
};

const FormScaleEdit = ({
  onSubmit,
  onSuccess,
  onFail,
  initialData
}) => {
  const {
    onFormSubmit,
    onFieldChange,
    formState,
    processing,
    errors
  } = useFormState(initialData, RULES, onSubmit, onSuccess, onFail);

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputText
          type="text"
          id="title"
          name="title"
          label="Scale title"
          placeholder="Enter your scale title"
          errors={errors.title}
          disabled={processing}
          value={formState.title}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputText
          type="number"
          id="scale"
          name="scale"
          label="Scale"
          placeholder="Enter your scale value"
          errors={errors.scale}
          disabled={processing}
          value={formState.scale}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {errors.detail && (
        <div className={style.nonFieldError}>
          <ErrorMessage>
            {errors.detail}
          </ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <ButtonWithSpinner
          type="submit"
          processing={processing}
        >
          Update scale
        </ButtonWithSpinner>
      </div>
    </form>
  );
};

FormScaleEdit.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  initialData: PropTypes.object
};

FormScaleEdit.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise,
  initialData: {
    title: '',
    scale: 1
  }
};

export default memo(FormScaleEdit);
