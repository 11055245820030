import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import useFormState from '../../../hooks/useFormState';

import Button from '../../UI/Button';
import Loader from '../../UI/Loader/Loader';
import ErrorMessage from '../../UI/ErrorMessage';
import InputText from '../../UI/InputText';
import { noopPromise, isNotEmptyArray } from '../../../utils';

import style from './FormEditEvent.scss';

const RULES = {
  eventName: {},
  eventDate: {}
};

const FormEditEvent = ({
  initialData, onSubmit, onSuccess, onFail, id
}) => {
  const {
    onFormSubmit, onFieldChange, formState, processing, errors
  } = useFormState(initialData, RULES, (formData) => onSubmit({ ...formData, id }), onSuccess, onFail);
  const [value, setValue] = useState(''); // eslint-disable-line no-unused-vars

  const isFormValue = () => formState.eventName && formState.eventDate;
  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputText
          type="text"
          id="eventName"
          name="eventName"
          placeholder={value ? '' : 'Name'}
          disabled={processing}
          errors={errors.eventName}
          value={formState.eventName}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputText
          type="date"
          id="emaildate"
          name="eventDate"
          placeholder={value ? '' : 'Date'}
          disabled={processing}
          errors={errors.eventDate}
          value={formState.eventDate}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      )}

      <Button type="submit" disabled={!isFormValue()} fullWidth style={{ marginTop: '17px' }}>
        Save
      </Button>
      {processing && <Loader />}
    </form>
  );
};

FormEditEvent.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  id: PropTypes.number
};

FormEditEvent.defaultProps = {
  initialData: {
    eventName: '',
    eventDate: ''
  },
  id: 0,
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormEditEvent);
