import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { targetImageSelector, targetVideoSelector } from '../../redux/target/selectors';
import { setTargetImageForCreation, setTargetVideoForCreation } from '../../redux/target/actions';

import { setAlert } from '../../redux/app/actions';

import AddTarget from './AddTarget';

const targetImageSourceSelector = createSelector(
  targetImageSelector,
  (data) => data?.photo || null
);

const targetVideoSourceSelector = createSelector(
  targetVideoSelector,
  (data) => data?.video || null
);

const mapStateToProps = createStructuredSelector({
  targetImageSource: targetImageSourceSelector,
  targetVideoSource: targetVideoSourceSelector
});

const onImageUploadSuccess = (action) => (dispatch) => {
  dispatch(setAlert('Image upload success'));
  const image = action?.request?.response?.data;

  if (image) {
    dispatch(setTargetImageForCreation(image));
  }
};

const onVideoUploadSuccess = (action) => (dispatch) => {
  dispatch(setAlert('Video upload success'));
  const video = action?.request?.response?.data;

  if (video) {
    dispatch(setTargetVideoForCreation(video));
  }
};

const onTargetUploadSuccess = () => (dispatch) => {
  dispatch(setAlert('Target creation success'));
};

const mapDispatchToProps = {
  onTargetUploadSuccess,
  onImageUploadSuccess,
  onVideoUploadSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTarget);
