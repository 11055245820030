import { createReducer, createRequestReducer } from '../utils';
import {
  FETCH_EVENTS_COUNT_SUCCESS,
  FETCH_EVENTS_LIST,
  FETCH_EVENTS_LIST_SUCCESS,
  CREATE_EVENT
} from './constants';

const initialState = {
  eventsCount: 0,
  eventsList: [],
  loading: false,
  error: null,
  selectedEvent: null,
  newEvent: null
};

const events = createReducer(initialState, {
  [FETCH_EVENTS_LIST]: (state) => ({
    ...state,
    loading: true,
    error: null
  }),
  [FETCH_EVENTS_COUNT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    eventsCount: action.payload.count
  }),
  [FETCH_EVENTS_LIST_SUCCESS]: (state, action) => (
    {
      ...state,
      loading: false,
      eventsList: action.payload,
      newEvent: false
    }
  ),
  [CREATE_EVENT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => (
      {
        ...state,
        loading: false,
        newEvent: action.request.response?.data
      }
    )
  })
});

export default events;
