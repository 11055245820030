/* eslint-disable max-len */
import React, { memo } from 'react';

const Check = ({ ...props }) => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.2504 38.5004C22.9028 38.5024 22.5625 38.4009 22.2728 38.2087C21.9832 38.0166 21.7573 37.7425 21.624 37.4215C21.4907 37.1004 21.4561 36.747 21.5244 36.4062C21.5928 36.0654 21.7611 35.7527 22.0079 35.5079L39.5079 18.0079C39.6706 17.8439 39.8641 17.7137 40.0774 17.6248C40.2907 17.536 40.5194 17.4902 40.7504 17.4902C40.9814 17.4902 41.2102 17.536 41.4234 17.6248C41.6367 17.7137 41.8302 17.8439 41.9929 18.0079C42.3188 18.3358 42.5018 18.7793 42.5018 19.2416C42.5018 19.704 42.3188 20.1475 41.9929 20.4754L24.4929 37.9754C24.3308 38.1407 24.1376 38.2723 23.9243 38.3624C23.711 38.4525 23.4819 38.4994 23.2504 38.5004Z"
      fill="#D70059"
    />
    <path
      d="M23.2505 38.5004C22.7889 38.4938 22.3486 38.3051 22.0255 37.9754L15.0255 30.9754C14.6995 30.6475 14.5166 30.204 14.5166 29.7416C14.5166 29.2793 14.6995 28.8358 15.0255 28.5079C15.1882 28.3439 15.3817 28.2137 15.595 28.1248C15.8082 28.036 16.037 27.9902 16.268 27.9902C16.499 27.9902 16.7277 28.036 16.941 28.1248C17.1543 28.2137 17.3478 28.3439 17.5105 28.5079L24.5105 35.5079C24.7584 35.7537 24.9271 36.068 24.995 36.4105C25.0628 36.753 25.0267 37.1079 24.8912 37.4297C24.7557 37.7515 24.5271 38.0254 24.2347 38.2162C23.9423 38.407 23.5996 38.5059 23.2505 38.5004Z"
      fill="#D70059"
    />
    <path
      d="M28.5 54.25C23.3083 54.25 18.2331 52.7105 13.9163 49.8261C9.5995 46.9417 6.23497 42.842 4.24817 38.0455C2.26137 33.2489 1.74154 27.9709 2.7544 22.8789C3.76726 17.7869 6.26733 13.1096 9.93846 9.43846C13.6096 5.76733 18.2869 3.26726 23.3789 2.2544C28.4709 1.24154 33.7489 1.76137 38.5455 3.74817C43.342 5.73497 47.4417 9.0995 50.3261 13.4163C53.2105 17.7331 54.75 22.8083 54.75 28C54.75 34.9619 51.9844 41.6387 47.0616 46.5616C42.1387 51.4844 35.4619 54.25 28.5 54.25ZM28.5 5.25001C24.0005 5.25001 19.602 6.58428 15.8608 9.08408C12.1196 11.5839 9.20365 15.1369 7.48175 19.294C5.75986 23.451 5.30933 28.0253 6.18715 32.4383C7.06496 36.8514 9.23169 40.9051 12.4133 44.0867C15.595 47.2683 19.6486 49.4351 24.0617 50.3129C28.4748 51.1907 33.049 50.7402 37.2061 49.0183C41.3631 47.2964 44.9161 44.3805 47.416 40.6392C49.9158 36.898 51.25 32.4995 51.25 28C51.25 21.9663 48.8531 16.1798 44.5867 11.9133C40.3202 7.64688 34.5337 5.25001 28.5 5.25001Z"
      fill="#D70059"
    />
  </svg>
);
export default memo(Check);
