import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useFormState from '../../../hooks/useFormState';
import Delete from '../../../icons/Delete';
import Heading from '../../UI/Heading';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader/Loader';
import ErrorMessage from '../../UI/ErrorMessage';
import CustomButton from '../../UI/CustomButton';
import { isNotEmptyArray, noopPromise } from '../../../utils';

import style from './FormDelete.scss';

const FormDelete = ({
  onSubmit, onSuccess, onFail, handleClose
}) => {
  const {
    processing, errors
  } = useFormState({}, onSubmit, onSuccess, onFail);

  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      await onSubmit();
    } catch (error) {
    }
  };
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={style.deleteHolder}>
        <Delete className={style.deleteIco} />
        <Heading align="center" level={2} weight={500} fSize={24}>
          Delete
        </Heading>
        <strong className={style.question}>Are you sure you want to delete?</strong>
      </div>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      )}

      <Button
        type="submit"
        fullWidth
        style={{ marginBottom: '16px' }}
      >
        Delete
      </Button>
      <CustomButton
        type="button"
        variant="secondary"
        className={style.cancelBtn}
        onClick={handleClose}
      >
        Cancel
      </CustomButton>
      {processing && <Loader />}
    </form>
  );
};

FormDelete.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  handleClose: PropTypes.func.isRequired
};

FormDelete.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormDelete);
