import { connect } from 'react-redux';

import { registration } from '../../../redux/auth/actions';

import FormRegistration from './FormRegistration';

const mapDispatchToProps = {
  onSubmit: registration
};

export default connect(null, mapDispatchToProps)(FormRegistration);
