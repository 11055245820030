import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../utils';
import Heading from '../../components/UI/Heading';
import FormAccountEdit from '../../components/Forms/FormAccountEdit';
import FormPasswordUpdate from '../../components/Forms/FormPasswordUpdate';
import Alert from '../../components/UI/Alert';

import style from './Account.scss';

const Account = ({ handleShowAlert }) => {
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    type: 'success',
    message: ''
  });

  const handleCloseAlert = () => {
    setAlertMsg({ ...alertMsg, open: false });
  };

  const showAlert = (message, type = 'success') => {
    setAlertMsg({ open: true, type, message });
  };

  const onAccountEditSuccess = useCallback(() => {
    showAlert('Account edit success', 'success');
    handleShowAlert('Account edit success');
  }, [handleShowAlert]);

  const onPasswordUpdateSuccess = useCallback(() => {
    showAlert('Password update success', 'success');
    handleShowAlert('Password update success');
  }, [handleShowAlert]);

  return (
    <>
      <div className={style.holder}>
        <Heading level={2} weight={500} fSize={16}>
          Basic info
        </Heading>

        <FormAccountEdit onSuccess={onAccountEditSuccess} />

        <Heading level={2} weight={500} fSize={16} mTop={24}>
          Change password
        </Heading>

        <FormPasswordUpdate onSuccess={onPasswordUpdateSuccess} />

        {/* <FormSafeEdit /> */}
      </div>

      <Alert
        open={alertMsg.open}
        handleCloseAlert={handleCloseAlert}
        type={alertMsg.type}
      >
        {alertMsg.message}
      </Alert>
    </>
  );
};

Account.propTypes = {
  handleShowAlert: PropTypes.func
};

Account.defaultProps = {
  handleShowAlert: noop
};

export default memo(Account);
