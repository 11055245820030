/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ className, ...props }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
      fill="currentColor"
    />
  </svg>
);

Calendar.propTypes = {
  className: PropTypes.string
};

Calendar.defaultProps = {
  className: ''
};

export default memo(Calendar);
