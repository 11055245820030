import { connect } from 'react-redux';
import MobileDetect from 'mobile-detect';

import { setDevice } from '../redux/app/actions';

import App from './App';

const mapDispatchToProps = {
  onMount: () => (dispatch) => {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);

    dispatch(setDevice({
      mobile: mobileDetect.mobile(),
      os: mobileDetect.os()
    }));
  }
};

export default connect(null, mapDispatchToProps)(App);
