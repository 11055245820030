import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { targetImageSelector, targetVideoSelector } from '../../../redux/target/selectors';

import FormCreateTarget from './FormCreateTarget';

const targetImageUidSelector = createSelector(
  targetImageSelector,
  (data) => data?.uid || null
);

const targetVideoUidSelector = createSelector(
  targetVideoSelector,
  (data) => data?.uid || null
);

const mapStateToProps = createStructuredSelector({
  targetImageUid: targetImageUidSelector,
  targetVideoUid: targetVideoUidSelector
});

export default connect(mapStateToProps)(FormCreateTarget);
