import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { indexPathSelector } from '../../redux/app/selectors';
import Router from './Router';

const mapStateToProps = createStructuredSelector({
  indexRedirectTo: indexPathSelector
});

export default connect(mapStateToProps)(Router);
