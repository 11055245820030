import React, {
  memo,
  useState,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardMedia,
  CardActions,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

const UploadMediaControl = ({
  children,
  mediaSource,
  type
}) => {
  const classes = useStyles();
  const [showImage, setShowImage] = useState(false);

  const handleDeleteMedia = useCallback(() => {
    setShowImage(false);
  }, []);

  useEffect(() => {
    setShowImage(!!mediaSource);
  }, [mediaSource]);

  return (
    showImage ? (
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          src={mediaSource}
          component={type}
          title="Uploaded media"
        />
        <CardActions>
          <IconButton onClick={handleDeleteMedia}>
            <DeleteForever />
          </IconButton>
        </CardActions>
      </Card>
    ) : children
  );
};

UploadMediaControl.propTypes = {
  children: PropTypes.node,
  mediaSource: PropTypes.string,
  type: PropTypes.string
};

UploadMediaControl.defaultProps = {
  children: null,
  mediaSource: null,
  type: 'img'
};

export default memo(UploadMediaControl);
