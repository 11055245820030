import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Zoom } from '@material-ui/core';

// const Transition = forwardRef((props, ref) => (
//   <Slide direction="up" ref={ref} {...props} />
// ));

// const Transition = forwardRef((props, ref) => {
//     const { open, ...other } = props;
//     return <Fade in={open} ref={ref} {...other} />;
//   })

const Transition = forwardRef((props, ref) => {
  const { open, ...other } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Zoom in={open} ref={ref} {...other} timeout={500} />;
});

Transition.propTypes = {
  open: PropTypes.bool
};

Transition.defaultProps = {
  open: false
};

export default memo(Transition);
