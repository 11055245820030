export const FETCH_EVENTS_COUNT = 'FETCH_EVENTS_COUNT';
export const FETCH_EVENTS_COUNT_SUCCESS = 'FETCH_EVENTS_COUNT_SUCCESS';
export const FETCH_EVENTS_COUNT_FAILURE = 'FETCH_EVENTS_COUNT_FAILURE';

export const FETCH_EVENTS_LIST = 'FETCH_EVENTS_LIST';
export const FETCH_EVENTS_LIST_SUCCESS = 'FETCH_EVENTS_LIST_SUCCESS';
export const FETCH_EVENTS_LIST_FAILURE = 'FETCH_EVENTS_LIST_FAILURE';

export const CREATE_EVENT = 'CREATE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
