import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { dataSelector } from '../../../redux/account/selectors';
import { clearTokens } from '../../../redux/auth/actions';

import UserBar from './UserBar';

const nameSelector = createSelector(
  dataSelector,
  (data) => data?.name || ''
);

const mapStateToProps = createStructuredSelector({
  name: nameSelector
});

const mapDispatchToProps = {
  onLogoutClick: clearTokens
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBar);
