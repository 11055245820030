import React, {
  memo, useState, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import AsideBar from './AsideBar';
import Alert from '../UI/Alert';
import LogoVide from '../UI/Logo/LogoVide';

import { noop } from '../../utils';

import style from './Layout.scss';

const Layout = ({
  authorized, alert, onAlertClose, children
}) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const asideBarRef = useRef(null);
  const btnMenuRef = useRef(null);

  const openCloseMenu = () => {
    setMenuOpened((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        asideBarRef.current
        && !asideBarRef.current.contains(event.target)
        && btnMenuRef.current
        && !btnMenuRef.current.contains(event.target)
      ) {
        setMenuOpened(false);
      }
    };

    if (menuOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpened]);

  return (
    <div className={style.component}>
      {authorized ? (
        <>
          <div
            ref={asideBarRef}
            className={`${style.asideBar} ${
              menuOpened ? style.asideBarOpened : ''
            }`}
          >
            <AsideBar setMenuOpened={setMenuOpened} authorized={authorized} />
          </div>
          <header className={style.mobileheader}>
            <button
              ref={btnMenuRef}
              type="button"
              onClick={openCloseMenu}
              className={`${style.btnMenu} ${
                menuOpened ? style.btnMenuOpened : ''
              }`}
            >
              <span>menu</span>
            </button>
            <NavLink className={style.mobileLogo} exact to="/">
              <LogoVide className={style.logoVide} />
              {/* <Logo width={24} height={24} /> */}
            </NavLink>
          </header>
        </>
      ) : null}
      <main className={`${style.main} ${menuOpened ? style.mainLayout : ''}`}>
        <div className={style.content}>{children}</div>
      </main>
      <Alert open={!!alert} handleCloseAlert={onAlertClose}>
        {alert}
      </Alert>
    </div>
  );
};

Layout.propTypes = {
  authorized: PropTypes.bool,
  alert: PropTypes.string,
  onAlertClose: PropTypes.func,
  children: PropTypes.node
};

Layout.defaultProps = {
  authorized: false,
  alert: null,
  onAlertClose: noop,
  children: null
};

export default memo(Layout);
