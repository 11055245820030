/* eslint-disable max-len */
import React, { memo } from 'react';

const Star = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.46875 20.6486L12.117 17.7191L17.7652 20.6486L16.6867 14.3906L21.234 9.96112L14.9295 9.04688L12.117 3.32812L9.3045 9.04688L3 9.96112L7.5705 14.3906L6.46875 20.6486Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Star);
