import React, { memo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Pencil from '../../icons/Pencil';
import Trash from '../../icons/Trash';
import Popup from '../Popup/Popup';
import Heading from '../UI/Heading';
import FormEditEvent from '../Forms/FormEditEvent';
import FormDelete from '../Forms/FormDelete/FormDelete';
import { deleteEvent, fetchEventsCount, fetchEventsList } from '../../redux/events/actions';
import formatDate from '../../utils/formatDate';
import { fetchTargetsCount, getTargetsList } from '../../redux/target/actions';
import { DELETE_TARGETS_BY_EVENT_ID } from '../../redux/target/constants';

import style from './EventItem.scss';

const EventItem = ({
  id, title, date, targets, views
}) => {
  const dispatch = useDispatch();

  const [isPopupEventOpen, setIsPopupEventOpen] = useState(false);
  const [isPopupDeleteEventOpen, setIsPopupDeleteEventOpen] = useState(false);
  const [state, setState] = useState({ title, date });

  const openPopupEditEvent = useCallback(() => {
    setIsPopupEventOpen(true);
  }, [setIsPopupEventOpen]);

  const closePopupEditEvent = useCallback(() => {
    setIsPopupEventOpen(false);
  }, [setIsPopupEventOpen]);

  const openPopupDeleteEvent = useCallback(() => {
    setIsPopupDeleteEventOpen(true);
  }, [setIsPopupDeleteEventOpen]);

  const closePopupDeleteEvent = useCallback(() => {
    setIsPopupDeleteEventOpen(false);
  }, [setIsPopupDeleteEventOpen]);

  const onEventEditSuccess = useCallback((action) => {
    setState(action.request.response.data);
    closePopupEditEvent();
  }, [closePopupEditEvent]);

  const handleDeleteEvent = useCallback(() => {
    dispatch(deleteEvent(id));
    setTimeout(() => {
      dispatch(fetchEventsList());
      dispatch(fetchEventsCount());
      dispatch(fetchTargetsCount());
      dispatch(getTargetsList());
      dispatch({ type: DELETE_TARGETS_BY_EVENT_ID, payload: id });
    }, 1000);
    closePopupDeleteEvent();
  }, [dispatch, id, closePopupDeleteEvent]);

  return (
    <li key={id} className={style.row}>
      <NavLink
        exact
        to={`/events/${id}`}
      >
        <b className={style.titleTarget}>Targets</b>
      </NavLink>
      <strong className={style.title}>{state.title}</strong>
      <div className={style.meta}>
        <div className={style.dateHolder}>
          <b className={style.titleDate}>Date</b>
          <time>{formatDate(state.date)}</time>
        </div>
        <div className={style.viewsHolder}>
          <b className={style.titleViews}>Views</b>
          <b className={style.countViews}>{views}</b>
        </div>
        <NavLink
          exact
          to={`/events/${id}`}
          className={style.targetsHolder}
        >
          <b className={style.titleTarget}>Targets</b>
          <b className={style.countTarget}>{targets}</b>
        </NavLink>
        <div className={style.action}>
          <button
            type="button"
            aria-label="edit"
            className={style.edit}
            onClick={openPopupEditEvent}
          >
            <Pencil />
          </button>
          <button
            type="button"
            aria-label="delete"
            className={style.delete}
            onClick={openPopupDeleteEvent}
          >
            <Trash />
          </button>
        </div>
      </div>
      <Popup open={isPopupEventOpen} handleClose={closePopupEditEvent}>
        <Heading level={2} weight={500}>
          Edit Event
        </Heading>
        <FormEditEvent
          onSuccess={onEventEditSuccess}
          id={id}
          initialData={{ eventDate: state.date, eventName: state.title }}
        />
      </Popup>
      <Popup open={isPopupDeleteEventOpen} handleClose={closePopupDeleteEvent}>
        <FormDelete
          handleClose={closePopupDeleteEvent}
          onSuccess={closePopupDeleteEvent}
          onSubmit={handleDeleteEvent}
        />
      </Popup>
    </li>
  );
};

EventItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  targets: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired
};

export default memo(EventItem);
