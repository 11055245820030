import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  targetListCountSelector
} from '../../redux/target/selectors';

import {
  getTargetsList
} from '../../redux/target/actions';

import Home from './Home';

const mapStateToProps = createStructuredSelector({
  targetsCount: targetListCountSelector
});

const mapDispatchToProps = {
  onMount: getTargetsList
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
