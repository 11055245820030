import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  MenuItem,
  Dialog,
  DialogContent
} from '@material-ui/core';
import Close from '../../../icons/Close';
import Account from '../../../pages/Account/Account';
import Person from '../../../icons/Person';
import Logout from '../../../icons/Logout';

import { noop } from '../../../utils';

import style from './UserBar.scss';

const UserBar = ({ name, onLogoutClick, setMenuOpened }) => {
  const [openProfile, setOpenProfile] = useState(false);

  const handleLogoutClick = useCallback(() => {
    onLogoutClick();
    setMenuOpened(false);
  }, [onLogoutClick, setMenuOpened]);

  const handleOpenProfile = useCallback(() => {
    setOpenProfile(true);
    setMenuOpened(false);
  }, [setOpenProfile, setMenuOpened]);

  const handleCloseProfile = useCallback(() => {
    setOpenProfile(false);
  }, [setOpenProfile]);

  return (
    <div>
      <ul>
        <MenuItem key="userbar-menu">
          <Button
            className={style.button}
            aria-controls="userbar-menu"
            aria-haspopup="true"
            onClick={handleOpenProfile}
          >
            <Person className={style.icon} />
            {name}
          </Button>
        </MenuItem>
        <MenuItem key="log-out">
          <Button
            className={style.buttonOut}
            onClick={handleLogoutClick}
          >
            <Logout className={style.icon} />
            Log out
          </Button>
        </MenuItem>
      </ul>
      <Dialog
        open={openProfile}
        onClose={handleCloseProfile}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            boxShadow: 'none',
            overflow: 'visible',
            position: 'relative',
            maxWidth: '480px',
            borderRadius: '20px',
            margin: '8px'
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)'
          }
        }}
      >
        <Button onClick={handleCloseProfile} className={style.closeButton}>
          <Close />
        </Button>
        <DialogContent
          style={{
            padding: '30px',
            borderRadius: '20px',
            flexDirection: 'column',
            height: 'auto',
            width: 'auto',
            overflow: 'auto'
          }}
        >
          <Account
            handleShowAlert={(message) => console.log(message)} // eslint-disable-line no-console
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

UserBar.propTypes = {
  name: PropTypes.string,
  onLogoutClick: PropTypes.func,
  setMenuOpened: PropTypes.func
};

UserBar.defaultProps = {
  name: '',
  onLogoutClick: noop,
  setMenuOpened: noop
};

export default memo(UserBar);
