import extractNumberFromUrl from './extractNumberFromUrl';

const sortMedia = (mediaArray) => mediaArray.sort((a, b) => {
  const numberA = extractNumberFromUrl(a.photoUrl || a.videoUrl);
  const numberB = extractNumberFromUrl(b.photoUrl || b.videoUrl);

  if (numberA !== null && numberB !== null) {
    return numberA - numberB;
  }

  const stringA = (a.photoUrl || a.videoUrl || '').toString();
  const stringB = (b.photoUrl || b.videoUrl || '').toString();

  return stringA.localeCompare(stringB);
});

export default sortMedia;
