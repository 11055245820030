/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import logo from '../../../assets/images/logo.png';

const Logo = ({
  width, height, style, className
}) => (
  <img src={logo} alt="logo" width={width} height={height} style={style} className={className} />
);

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string
};

Logo.defaultProps = {
  width: 56,
  height: 56,
  style: {},
  className: ''
};

export default memo(Logo);
