import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paper, useMediaQuery } from '@material-ui/core';

import style from './Panel.scss';

const createStyle = (padding, maxWidth, borderRadius) => ({
  padding: padding || null,
  maxWidth: maxWidth || null,
  borderRadius: borderRadius || null
});

const Panel = ({
  children, padding, maxWidth, borderRadius, ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const effectiveBorderRadius = borderRadius ?? (isMobile ? 24 : 32);
  const effectivePadding = padding ?? (isMobile ? '32px 20px' : '40px 64px');

  return (
    <Paper
      className={style.component}
      style={createStyle(effectivePadding, maxWidth, effectiveBorderRadius)}
      elevation={3}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Paper>
  );
};

Panel.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

Panel.defaultProps = {
  padding: null,
  children: null,
  borderRadius: null,
  maxWidth: 579
};

export default memo(Panel);
