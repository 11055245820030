import { connect } from 'react-redux';

import { updatePassword } from '../../../redux/account/actions';

import FormPasswordUpdate from './FormPasswordUpdate';

const mapDispatchToProps = {
  onSubmit: updatePassword
};

export default connect(null, mapDispatchToProps)(FormPasswordUpdate);
