import { createSelector } from 'reselect';
import { defaultDataSelector, defaultFetchingSelector } from '../utils';

const slice = ({ target }) => target;

export const dataSelector = defaultDataSelector(slice);
export const fetchingSelector = defaultFetchingSelector(slice);
export const targetImageSelector = createSelector(
  slice,
  (target) => target.images
);
export const targetVideoSelector = createSelector(
  slice,
  (target) => target.videos
);

export const selectEvenId = createSelector(
  slice,
  (target) => target.data.eventId
);

export const sessionDataTitle = createSelector(
  slice,
  (target) => target.data.sessionName
);

export const goalTarget = createSelector(
  slice,
  (target) => target.data
);

export const getNewTarget = createSelector(
  slice,
  (target) => target.newTarget
);

export const getUploadFiles = createSelector(
  slice,
  (target) => target.uploadMedia
);

export const getIsFetchingSelector = createSelector(
  slice,
  (target) => target.fetching
);

export const shouldUpdateSelector = createSelector(
  slice,
  (target) => target.shouldUpdate
);

export const targetListSelector = createSelector(
  dataSelector,
  (data) => data?.list ?? []
);

export const targetSelector = createSelector(
  dataSelector,
  (data) => data?.target ?? null
);

export const targetIdSelector = createSelector(
  targetSelector,
  (target) => target?.uid ?? null
);

export const targetListResultsSelector = createSelector(
  targetListSelector,
  (list) => {
    if (Array.isArray(list)) {
      return list;
    }
    if (typeof list === 'object') {
      return Object.values(list);
    }
    return [];
  }
);

export const allTargetListResultsSelector = createSelector(
  dataSelector,
  (data) => {
    if (Array.isArray(data.allTargets)) {
      return data.allTargets;
    }
    if (typeof data.allTargets === 'object') {
      return Object.values(data.allTargets);
    }
    return [];
  }
);

export const singleTargetListResultsSelector = createSelector(
  dataSelector,
  (data) => {
    if (Array.isArray(data.singleTargets)) {
      return data.singleTargets;
    }
    if (typeof data.singleTargets === 'object') {
      return Object.values(data.singleTargets);
    }
    return [];
  }
);

export const sessionTargetListResultsSelector = createSelector(
  dataSelector,
  (data) => {
    if (Array.isArray(data.sessionTargets)) {
      return data.sessionTargets;
    }
    if (typeof data.sessionTargets === 'object') {
      return Object.values(data.sessionTargets);
    }
    return [];
  }
);

export const allSessionCountSelector = createSelector(
  dataSelector,
  (data) => {
    if (typeof data.allTargetsCount === 'number') {
      return data.allTargetsCount;
    }
    return 0;
  }
);

export const sessionCountSelector = createSelector(
  dataSelector,
  (data) => {
    if (typeof data.sessionCount === 'number') {
      return data.sessionCount;
    }
    return 0;
  }
);

export const singleTargetsCountSelector = createSelector(
  dataSelector,
  (data) => {
    if (typeof data.singleTargetsCount === 'number') {
      return data.singleTargetsCount;
    }
    return 0;
  }
);

export const sessionTargetsCountSelector = createSelector(
  dataSelector,
  (data) => {
    if (typeof data.sessionTargetsCount === 'number') {
      return data.sessionTargetsCount;
    }
    return 0;
  }
);

export const singleSessionCountSelector = createSelector(
  dataSelector,
  (data) => {
    if (typeof data.singleSessionsCount === 'number') {
      return data.singleSessionsCount;
    }
    return 0;
  }
);

export const sessionSessionCountSelector = createSelector(
  dataSelector,
  (data) => {
    if (typeof data.sessionSessionCounts === 'number') {
      return data.sessionSessionCounts;
    }
    return 0;
  }
);

export const targetListCountSelector = createSelector(
  targetListSelector,
  (list) => list?.count ?? 0
);

export const targetsCountSelector = createSelector(
  dataSelector,
  (data) => data?.targetsCount ?? 0
);
