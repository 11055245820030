import React, { memo, useCallback, useState } from 'react';
import {
  Switch, Route, useHistory, useRouteMatch
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Typography } from '@material-ui/core';

import { useQuery } from '../../hooks';
import Panel from '../../components/UI/Panel';
import Link from '../../components/UI/Link';
import Heading from '../../components/UI/Heading';
import Logo from '../../components/UI/Logo';
import FormConfirmationCode from '../../components/Forms/FormConfirmationCode';
import Check from '../../icons/Check';
import { loginJWT, get2faCode } from '../../redux/auth/actions';
import Loader from '../../components/UI/Loader/Loader';
import FormLogin from '../../components/Forms/FormLogin/FormLogin';

import style from './SignIn.scss';
import Alert from '../../components/UI/Alert';

const SignIn = () => {
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    message: ''
  });
  const dispatch = useDispatch();
  const { email } = useQuery();
  const { push } = useHistory();
  const { path } = useRouteMatch();

  const [isResending, setIsResending] = useState(false);

  const onLoginSubmit = useCallback(
    async(data) => {
      const result = await dispatch(loginJWT(data));
      if (result && result.errors) {
        const errors = Object.values(result.errors).flat();
        const message = errors[0] || 'Something went wrong';
        setAlertMsg({ open: true, message });
        return;
      }
      if (result) {
        push(`${path}success/?email=${data.email}`);
        dispatch(get2faCode({ email: data.email }));
      }
    },
    [push, path, dispatch]
  );

  const handleResendCode = async() => {
    setIsResending(true);
    dispatch(get2faCode({ email }))
      .finally(() => {
        setIsResending(false);
      });
  };

  const handleCloseAlert = () => {
    setAlertMsg({ ...alertMsg, open: false });
  };
  return (
    <Switch>
      {/* <Route> */}
      <Route exact path={`${path}success/`}>
        <div className={style.component}>

          <div className={style.center}>
            <Panel align="center" maxWidth="529px">
              <Check />
              <p className={style.confirmstionText}>We are keeping you safe!</p>
              <Typography
                style={{
                  fontSize: '14px',
                  marginTop: '12px'
                }}
              >
                Enter the 6 digit code we sent you
              </Typography>
              <Typography
                style={{
                  fontSize: '14px'
                }}
              >
                via email to continue
              </Typography>

              <FormConfirmationCode email={email} />

              <div className={style.links}>
                <Typography style={{ fontSize: '14px' }}>
                  Didn&apos;t get the code?
                </Typography>
                <button
                  type="button"
                  className={style.btnResend}
                  onClick={handleResendCode}
                  disabled={isResending}
                >
                  Resend code
                </button>
              </div>
              {isResending && <Loader />}
            </Panel>
          </div>
        </div>
      </Route>

      <Route>
        <div className={style.component}>
          <div className={style.center}>
            <Panel align="center">
              <div className={style.headline}>
                <Logo />
                <Heading weight={500} fSize={24}>Hello Again!</Heading>
                <div className={style.headlineSecond}>
                  <Heading level="2" align="center" weight={400} lheight={1.7} fSize={14}>
                    Welcome back you’ve been missed!
                  </Heading>
                </div>
              </div>
              <Alert
                open={alertMsg.open}
                handleCloseAlert={handleCloseAlert}
                type="error"
              >
                {alertMsg.message}
              </Alert>
              <FormLogin email={email} onSubmit={onLoginSubmit} />

              <div className={style.links}>
                <Typography style={{ fontSize: '14px' }}>
                  Not registered yet?
                </Typography>
                <Link to="/sign-up/">Sign up</Link>
              </div>
            </Panel>
          </div>
        </div>
      </Route>

    </Switch>
  );
};

export default memo(SignIn);
