import jwtDecode from 'jwt-decode';

import { createReducer, createRequestReducer } from '../utils';
import {
  SET_TOKEN,
  DELETE_TOKEN,
  ADD_DEFERRED_ACTION,
  CLEAR_DEFERRED_ACTIONS,
  POST_REGISTRATION_CONFIRM,
  POST_OAUTH_CODE,
  POST_REFRESH_TOKEN,
  POST_LOGIN_SUCCESS,
  POST_CONFIRMATION_CODE
} from './constants';

const getTokenData = (token) => {
  let data = null;

  try {
    data = jwtDecode(token);
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }

  return data;
};

const authorizationRequestReducer = (state, action) => createRequestReducer(state, action, {
  SUCCESS: () => {
    const { access, refresh } = action.request.response.data;

    return {
      ...state,
      token: access,
      refreshToken: refresh,
      tokenData: getTokenData(access),
      authorized: true
    };
  }
});

const authorizationLoginRequestReducer = (state, action) => {
  const { access, refresh } = action.payload;

  return {
    ...state,
    token: access,
    refreshToken: refresh,
    tokenData: getTokenData(access),
    authorized: true
  };
};

const initialState = {
  token: null,
  refreshToken: null,
  tokenData: null,
  authorized: false,
  refreshing: false,
  deferredActions: []
};

const auth = createReducer(initialState, {
  [SET_TOKEN]: (state, { token, refreshToken }) => ({
    ...state,
    token,
    refreshToken,
    tokenData: getTokenData(token),
    authorized: true
  }),

  [DELETE_TOKEN]: (state) => ({
    ...state,
    token: null,
    refreshToken: null,
    tokenData: null,
    authorized: false
  }),

  [ADD_DEFERRED_ACTION]: (state, { action }) => ({
    ...state,
    deferredActions: [
      ...state.deferredActions,
      action
    ]
  }),

  [CLEAR_DEFERRED_ACTIONS]: (state) => ({
    ...state,
    deferredActions: []
  }),

  [POST_REGISTRATION_CONFIRM]: authorizationRequestReducer,

  [POST_LOGIN_SUCCESS]: authorizationLoginRequestReducer,

  [POST_OAUTH_CODE]: authorizationRequestReducer,

  [POST_CONFIRMATION_CODE]: authorizationRequestReducer,

  [POST_REFRESH_TOKEN]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      refreshing: true
    }),

    SUCCESS: () => {
      const { access, refresh } = action.request.response.data;

      return {
        ...state,
        token: access,
        refreshToken: refresh,
        tokenData: getTokenData(access),
        refreshing: false,
        authorized: true
      };
    },

    FAIL: () => ({
      ...state,
      refreshing: false
    })
  })
});

export default auth;
