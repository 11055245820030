import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import { Alert as MaterialAlert } from '@material-ui/lab';
import { noop } from '../../../utils';

import style from './Alert.scss';

const alertAlign = {
  vertical: 'top',
  horizontal: 'center'
};

const Alert = ({
  open, handleCloseAlert, type, children
}) => {
  const classes = type === 'success' || type === 'info'
    ? { root: style.customSuccess }
    : { root: style.customError };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleCloseAlert}
      anchorOrigin={alertAlign}
    >
      <MaterialAlert
        severity={type}
        style={{ borderRadius: '12px', fontWeight: '400' }}
        classes={classes}
      >
        {children}
      </MaterialAlert>
    </Snackbar>
  );
};

Alert.propTypes = {
  open: PropTypes.bool,
  handleCloseAlert: PropTypes.func,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  children: PropTypes.node
};

Alert.defaultProps = {
  open: false,
  handleCloseAlert: noop,
  type: 'success',
  children: null
};

export default memo(Alert);
