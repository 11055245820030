import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise, isNotEmptyArray } from '../../../utils';

import InputText from '../../UI/InputText';
import ErrorMessage from '../../UI/ErrorMessage';
import ButtonWithSpinner from '../../UI/ButtonWithSpinner';
import Switch from '../../UI/Switch';

import style from './FormAccountEdit.scss';

const RULES = {
  name: {}
};

const FormAccountEdit = ({
  initialData,
  onSubmit,
  onSuccess,
  onFail
}) => {
  const {
    onFormSubmit,
    onFieldChange,
    formState,
    changed,
    processing,
    errors
  } = useFormState(initialData, RULES, onSubmit, onSuccess, onFail);

  const handleToggleTwofa = (checked) => {
    onFieldChange(checked, 'mfaEnable');
  };

  const isFormChanged = changed || (formState.mfaEnable !== initialData.mfaEnable);

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputText
          type="text"
          id="name"
          name="name"
          placeholder="John Doe"
          errors={errors.name}
          disabled={processing}
          value={formState.name}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputText
          type="email"
          id="email"
          placeholder="example@mail.com"
          readOnly
          value={formState.email}
          fullWidth
        />
      </FormControl>

      <div className={style.swichHolder}>
        <Switch
          id="toggler-2fa"
          label="Enable 2FA with Email Verification Code"
          checked={formState.mfaEnable}
          name="mfaEnable"
          onChange={handleToggleTwofa}
          errors={errors.mfaEnable}
          is2FA
        />
      </div>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.nonFieldErrors[0]}</ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <ButtonWithSpinner
          type="submit"
          // disabled={!isFormChanged()}
          disabled={!isFormChanged}
          processing={processing}
          style={{ width: '100%' }}
        >
          Update
        </ButtonWithSpinner>
      </div>
    </form>
  );
};

FormAccountEdit.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

FormAccountEdit.defaultProps = {
  initialData: {
    email: '',
    name: '',
    mfaEnable: false
  },
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormAccountEdit);
