export const UPLOAD_IMAGE = 'targets/UPLOAD_IMAGE';
export const UPLOAD_VIDEO = 'targets/UPLOAD_VIDEO';
export const UPDATE_TARGETS = 'targets/UPDATE_TARGETS';
export const CREATE_TARGETS = 'targets/CREATE_TARGETS';
export const RESET_STATE = 'targets/RESET_STATE';
export const LOAD_IMAGE = 'targets/LOAD_IMAGE';
export const LOAD_VIDEO = 'targets/LOAD_VIDEO';
export const UPDATE_SESSION_NAME = 'targets/UPDATE_SESSION_NAME';
export const UPDATE_EVENT_NAME = 'targets/UPDATE_EVENT_NAME';
export const GET_TARGETS_LIST = 'targets/GET_TARGETS_LIST';
export const GET_TARGET = 'targets/GET_TARGET';
export const GET_TARGET_BY_SESSION_ID = 'targets/GET_TARGET_BY_SESSION_ID';
export const UPDATE_TARGET = 'targets/UPDATE_TARGET';
export const DELETE_TARGET = 'targets/DELETE_TARGET';
export const DELETE_TARGETS_SESSION = 'targets/DELETE_TARGETS_SESSION';
export const DELETE_ASSET = 'targets/DELETE_ASSET';
export const SET_TARGET_FIELDS = 'targets/SET_TARGET_FIELDS';
export const SET_TARGET_IMAGE_FOR_CREATION = 'targets/SET_TARGET_IMAGE_FOR_CREATION';
export const SET_TARGET_VIDEO_FOR_CREATION = 'targets/SET_TARGET_VIDEO_FOR_CREATION';
export const FETCH_TARGETS_COUNT = 'targets/FETCH_TARGETS_COUNT';
export const FETCH_SINGLE_TARGETS_COUNT = 'targets/FETCH_SINGLE_TARGETS_COUNT';
export const FETCH_SESSION_TARGETS_COUNT = 'targets/FETCH_SESSION_TARGETS_COUNT';
export const FETCH_TARGETS_COUNT_SUCCESS = 'targets/FETCH_TARGETS_COUNT_SUCCESS';
export const FETCH_TARGETS_COUNT_FAILURE = 'targets/FETCH_TARGETS_COUNT_FAILURE';
export const FETCH_SESSIONS_COUNT_REQUEST = 'targets/FETCH_SESSIONS_COUNT_REQUEST';
export const FETCH_SESSIONS_COUNT_SUCCESS = 'targets/FETCH_SESSIONS_COUNT_SUCCESS';
export const FETCH_SESSIONS_COUNT_FAILURE = 'targets/FETCH_SESSIONS_COUNT_FAILURE';
export const FETCH_SINGLE_SESSIONS_COUNT = 'targets/FETCH_SINGLE_SESSIONS_COUNT';
export const FETCH_SESSION_SESSIONS_COUNT = 'targets/FETCH_SESSION_SESSIONS_COUNT';
export const RESET_NEW_TARGET = 'targets/RESET_NEW_TARGET';
export const FETCH_ALL_TARGETS = 'targets/FETCH_ALL_TARGETS';
export const FETCH_ALL_SESSION_COUNT = 'targets/FETCH_ALL_TARGETS_COUNT';
export const FETCH_SINGLE_TARGETS = 'targets/FETCH_SINGLE_TARGETS';
export const FETCH_SESSION_TARGETS = 'targets/FETCH_SESSION_TARGETS';
export const RESET_TARGETS_LISTS = 'targets/RESET_TARGETS_LISTS';
export const UPLOAD_MEDIA = 'targets/UPLOAD_MEDIA';
export const UPLOAD_MEDIA_SUCCESS = 'targets/UPLOAD_MEDIA_SUCCESS';
export const REMOVE_FILE_FROM_UPLOAD = 'targets/REMOVE_FILE_FROM_UPLOAD';
export const INSTANT_DELETE_SESSION = 'targets/INSTANT_DELETE_SESSION';
export const RESET_EDIT_POPUP_STATE = 'targets/RESET_EDIT_POPUP_STATE';
export const DELETE_TARGETS_BY_EVENT_ID = 'targets/DELETE_TARGETS_BY_EVENT_ID';
