import { connect } from 'react-redux';

import { createDispatchPropsFactory } from '../../../utils';
import { resetPassword } from '../../../redux/auth/actions';

import FormResetPassword from './FormResetPassword';

const mapDispatchToProps = createDispatchPropsFactory(
  (dispatch, { email, code }) => ({
    onSubmit: ({ password }) => dispatch(resetPassword({ email, code, password }))
  }),
  ['email', 'code']
);

export default connect(null, mapDispatchToProps)(FormResetPassword);
