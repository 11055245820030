import React, {
  memo, useState, useEffect, useRef, useCallback
} from 'react';
import PropTypes from 'prop-types';

import style from './CustomSelect.scss';

const CustomSelect = ({
  placeholder,
  label,
  options,
  selectedValue,
  onSelect,
  onCreateEvent,
  editEvent,
  disabled
}) => {
  const [state, setState] = useState(placeholder);
  const [opened, setOpened] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const handleClickOutside = useCallback((e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpened(false);
    }
  }, []);

  useEffect(() => {
    const foundOption = options.find((option) => option.id === selectedValue);
    if (foundOption) {
      setState(foundOption.title);
    }
    if (dropdownRef.current && listRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: dropdownRect.bottom,
        left: dropdownRect.left
      });
      listRef.current.style.width = `${dropdownRect.width}px`;
    }
  }, [dropdownRef, listRef, opened, selectedValue, options]);

  useEffect(() => {
    if (opened) {
      window.addEventListener('click', handleClickOutside);
      window.addEventListener('scroll', handleClickOutside, true);
    } else {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleClickOutside, true);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleClickOutside, true);
    };
  }, [opened, handleClickOutside]);

  useEffect(() => {
    if (searchValue) {
      setFilteredOptions(options.filter((option) => option.title.toLowerCase().includes(searchValue.toLowerCase())));
    } else {
      setFilteredOptions(options);
    }
  }, [searchValue, options]);

  const handleOptionSelect = (optionTitle, optionValue) => {
    setState(optionTitle);
    onSelect(optionValue);
    setOpened(false);
  };

  return (
    <div className={`${style.dropDown} ${disabled ? style.disabled : ''}`} ref={dropdownRef}>
      {label ? <label htmlFor="custom-select" className={style.label}>{label}</label> : null}
      <select
        value={selectedValue || ''}
        disabled={!editEvent}
        onChange={(e) => {
          const selectedOption = options.find((opt) => opt.title === e.target.value);
          handleOptionSelect(selectedOption?.id || '', e.target.value);
        }}
      >
        <option key="default" value="" disabled>
          Select Event
        </option>
        <option key="create" value="create">
          Create Event
        </option>
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>
      <button
        className={`${style.dropBtn} ${state === placeholder ? style.placeholder : ''} ${opened ? style.opened : ''}`}
        type="button"
        onClick={() => setOpened(!opened)}
      >
        {state}
      </button>
      {opened && (
        <div
          ref={listRef}
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
          className={`${style.list} ${opened ? style.opened : ''}`}
        >
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search..."
            className={style.searchInput}
          />
          <ul>
            <li key="create" value="create" className={style.item}>
              <button
                className={style.option}
                type="button"
                onClick={onCreateEvent}
              >
                Create Event
              </button>
            </li>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li key={option.id} value={option.id} className={style.item}>
                  <button
                    type="button"
                    className={style.option}
                    onClick={() => handleOptionSelect(option.title, option.id)}
                  >
                    {option.title}
                  </button>
                </li>
              ))
            ) : (
              <li className={style.noOptions} />
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
  onCreateEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.bool,
  disabled: PropTypes.bool
};

CustomSelect.defaultProps = {
  placeholder: 'Select an option',
  label: '',
  selectedValue: '',
  editEvent: true,
  disabled: false
};

export default memo(CustomSelect);
