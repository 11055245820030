import { connect } from 'react-redux';

import { confirmCode } from '../../../redux/auth/actions';

import FormConfirmationCode from './FormConfirmationCode';

const mapDispatchToProps = {
  onSubmit: confirmCode
};

export default connect(null, mapDispatchToProps)(FormConfirmationCode);
