import React, { memo } from 'react';

const Home = ({ ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 10V7H7V10H9.5V6H11L6 1.5L1 6H2.5V10H5Z" />
  </svg>
);

export default memo(Home);
