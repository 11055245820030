import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { authorizedSelector } from '../../redux/auth/selectors';
import { alertSelector } from '../../redux/app/selectors';

import { setAlert } from '../../redux/app/actions';

import Layout from './Layout';

const mapStateToProps = createStructuredSelector({
  authorized: authorizedSelector,
  alert: alertSelector
});

const onAlertCloseHandle = () => (dispatch) => {
  dispatch(setAlert(null));
};

const mapDispatchToProps = {
  onAlertClose: onAlertCloseHandle
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
