import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';

import { noop, isNotEmptyArray } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  errorText: {
    marginBottom: '-10px',
    position: 'static',
    marginTop: '2px',
    color: theme.palette.error.main,
    fontSize: '0.75rem'
  }
}));

const InputText = ({
  type, name, label, errors, onChange, ...props
}) => {
  const classes = useStyles();

  const onChangeProxy = useCallback(
    ({ target }) => {
      onChange(target.value, name);
    },
    [name, onChange]
  );

  const computedLabel = useMemo(
    () => (isNotEmptyArray(errors) ? errors[0] : null),
    [errors]
  );

  return (
    <div className={classes.root}>
      <TextField
        type={type}
        name={name}
        label={label}
        error={!!errors}
        helperText={null}
        variant="outlined"
        onChange={onChangeProxy}
        InputLabelProps={{ shrink: false }}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
      {!!errors && <div className={classes.errorText}>{computedLabel}</div>}
    </div>
  );
};

InputText.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'password',
    'textarea',
    'number',
    'date'
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.array
};

InputText.defaultProps = {
  type: 'text',
  name: null,
  label: '',
  onChange: noop,
  errors: null
};

export default memo(InputText);
