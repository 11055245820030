import {
  SET_DEVICE,
  SET_ALERT
} from './constants';

// eslint-disable-next-line import/prefer-default-export
export const setDevice = (device) => ({
  type: SET_DEVICE,
  device
});

export const setAlert = (alert) => ({
  type: SET_ALERT,
  alert
});
