/* eslint-disable max-len */
import React, { memo } from 'react';

const Next = ({ ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.00001 3L4.29501 3.705L6.58501 6L4.29501 8.295L5.00001 9L8.00001 6L5.00001 3Z" fill="currentColor" />
  </svg>
);

export default memo(Next);
