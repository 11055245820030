/* eslint-disable max-len */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';

import Transition from '../Popup/Transition';
import Logo from '../UI/Logo';
// import LogoVide from '../UI/Logo/LogoVide';

import style from './PreviewPopup.module.scss';

const PreviewPopUp = ({
  targets, selectedTargetId, open, handleClose
}) => {
  const showArrows = targets.length > 1;
  const [currentTargetIndex, setCurrentTargetIndex] = useState(targets.findIndex((target) => target.targetId === selectedTargetId));

  const handlePrev = () => {
    setCurrentTargetIndex((prevIndex) => (prevIndex === 0 ? targets.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentTargetIndex((prevIndex) => (prevIndex === targets.length - 1 ? 0 : prevIndex + 1));
  };

  const { photoUrl, videoUrl } = targets[currentTargetIndex];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden'
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.9)'
        }
      }}
    >
      <IconButton onClick={handleClose} className={style.closeBtn}>
        <Close style={{ color: 'white', fontSize: '2.5rem' }} />
      </IconButton>
      <DialogContent className="dialogContent">
        {photoUrl ? (
          <div className={style.container}>
            <div className={style.frame}>
              <img
                src={photoUrl.replace(/converted_/g, '')}
                // src={photoUrl}
                alt="photoUrl"
                className={style.preview}
              />
              <Logo width={40} height={40} className={style.logo} />
              {/* <LogoVide width={111} height={55} className={style.logoVide} /> */}
            </div>
            {showArrows && (
              <IconButton
                onClick={handlePrev}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  transform: 'translateY(-50%)',
                  color: '#fff',
                  zIndex: 200
                }}
              >
                <ArrowBack style={{ color: 'white', fontSize: '2.5rem' }} />
              </IconButton>
            )}
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {showArrows && (
              <IconButton
                onClick={handlePrev}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  transform: 'translateY(-50%)',
                  color: '#fff',
                  zIndex: 200
                }}
              >
                <ArrowBack style={{ color: 'white', fontSize: '2.5rem' }} />
              </IconButton>
            )}
          </div>
        )}

        {videoUrl ? (
          <div className={style.container}>
            <div className={style.frame}>
              <img
                src={photoUrl.replace(/converted_/g, '')}
                alt="photoUrl"
                className={style.preview}
              />
              <video
                // src={videoUrl.replace(/converted_/g, '')}
                src={videoUrl}
                className={style.preview}
                controls
              />
              <Logo width={40} height={40} className={style.logo} />
            </div>
            {showArrows && (
              <IconButton
                onClick={handleNext}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  color: '#fff'
                }}
              >
                <ArrowForward style={{ color: 'white', fontSize: '2.5rem' }} />
              </IconButton>
            )}
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {showArrows && (
              <IconButton
                onClick={handleNext}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  color: '#fff'
                }}
              >
                <ArrowForward style={{ color: 'white', fontSize: '2.5rem' }} />
              </IconButton>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

PreviewPopUp.propTypes = {
  targets: PropTypes.array.isRequired,
  selectedTargetId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default memo(PreviewPopUp);
