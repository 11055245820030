/* eslint-disable */
import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

const deleteFileFromAWS = (fileKey) => {
  
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  });
  
  const s3 = new S3({
    params: { Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_S3_REGION_NAME
  });

  const params = {
    Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME,
    Key: fileKey 
  };

  return new Promise((resolve, reject) => {
    s3.deleteObject(params, (err, data) => {
      if (err) {
        reject(new Error(`Error deleting file: ${err.message}`));
      } else {
        resolve({ success: true, message: 'File deleted successfully', data });
      }
    });
  });
};

export default deleteFileFromAWS;