import { noop } from '../../utils';

const createAuthMiddleware = (onUnauthorized = noop) => (store) => (next) => (action) => {
  if (!action) {
    return next(action);
  }

  const { request, status, authorization } = action;

  if (!request || !request.response || status !== 'FAIL') {
    return next(action);
  }

  const { statusCode } = request.response;

  if ((statusCode && statusCode !== 401) || authorization) {
    return next(action);
  }

  if (action.request.endpoint === 'token/') { // @FIXME
    return next(action);
  }

  return store.dispatch(onUnauthorized(action));
};

export default createAuthMiddleware;
