import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Eye from '../../../../icons/Eye';
import CrossedEye from '../../../../icons/CrossedEye';

import { noop } from '../../../../utils';

import style from './ToggledEyeIconButton.scss';

const ToggledEyeIconButton = ({
  visible, onClick, disabled, filled
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={style.component}
    style={{ opacity: filled ? 1 : 0.3 }}
  >
    {visible ? <CrossedEye /> : <Eye />}
  </button>
);

ToggledEyeIconButton.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  filled: PropTypes.bool
};

ToggledEyeIconButton.defaultProps = {
  visible: false,
  onClick: noop,
  disabled: false,
  filled: false
};

export default memo(ToggledEyeIconButton);
