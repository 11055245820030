import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Empty from '../../icons/Empty';

import style from './NoData.scss';

const NoData = ({ children }) => (
  <div className={style.wrapper}>
    <div className={style.emptyWrapper}>
      <Empty className={style.emptySvg} />
    </div>
    <strong className={style.title}>{children}</strong>
  </div>
);

NoData.propTypes = {
  children: PropTypes.node.isRequired
};

export default memo(NoData);
