import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise, isNotEmptyArray } from '../../../utils';

import InputPassword from '../../UI/InputPassword';
import ErrorMessage from '../../UI/ErrorMessage';
import Button from '../../UI/Button';

import style from './FormResetPassword.scss';
import Loader from '../../UI/Loader/Loader';

const DATA = {
  password: '',
  passwordConfirmation: ''
};

const RULES = {
  password: {
    required: true,
    custom: (value) => value.length < 8 && 'Password must be at least 8 characters length'
  },
  passwordConfirmation: {
    required: true,
    custom: (value, formState) => value !== formState.password && 'Passwords do not match'
  }
};

const FormResetPassword = ({
  onSubmit,
  onSuccess,
  onFail
}) => {
  const {
    onFormSubmit,
    onFieldChange,
    formState,
    processing,
    errors
  } = useFormState(DATA, RULES, onSubmit, onSuccess, onFail);

  const [value, setValue] = useState(''); // eslint-disable-line no-unused-vars

  const isFormValue = () => formState.password && formState.passwordConfirmation;

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="password"
          name="password"
          placeholder={value ? '' : 'Password'}
          disabled={processing}
          errors={errors.password}
          value={formState.password}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="passwordConfirmation"
          name="passwordConfirmation"
          placeholder={value ? '' : 'Confirm password'}
          disabled={processing}
          errors={errors.passwordConfirmation}
          value={formState.passwordConfirmation}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {isNotEmptyArray(errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>
            {errors.nonFieldErrors[0]}
          </ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <Button type="submit" disabled={!isFormValue()} fullWidth>
          Save
        </Button>
      </div>
      {processing && <Loader />}
    </form>
  );
};

FormResetPassword.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

FormResetPassword.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormResetPassword);
