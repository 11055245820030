import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Heading = ({
  level,
  children,
  noMargin,
  align,
  weight = 400,
  lheight,
  fSize,
  mTop,
  mBottom,
  ...props
}) => {
  const computedTag = `h${level}`;

  return (
    <Typography
      variant={computedTag}
      component={computedTag}
      align={align}
      style={{
        fontWeight: weight,
        lineHeight: lheight,
        fontSize: fSize,
        marginTop: mTop,
        marginBottom: mBottom
      }}
      gutterBottom={!noMargin}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Typography>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, '1', 2, '2', 3, '3', 4, '4', 5, '5', 6, '6']),
  noMargin: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  fSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

Heading.defaultProps = {
  level: 1,
  noMargin: true,
  weight: 400,
  align: 'left',
  fSize: 'inherit',
  lheight: 1.2,
  mTop: 0,
  mBottom: 0,
  children: null
};

export default memo(Heading);
