import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent
} from '@material-ui/core';

import Transition from './Transition';
import Close from '../../icons/Close';

import style from './Popup.scss';

const Popup = ({
  open, handleClose, size, closeIconWidth, closeIconHeight, children
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="md"
    TransitionComponent={Transition}
    PaperProps={{
      style: {
        boxShadow: 'none',
        overflow: 'visible',
        position: 'relative',
        maxWidth: size,
        borderRadius: '20px',
        margin: '8px'
      }
    }}
    BackdropProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)'
      }
    }}
  >
    <Button onClick={handleClose} className={style.closeButton}>
      <Close width={closeIconWidth} height={closeIconHeight} />
    </Button>
    <DialogContent
      style={{
        padding: '20px',
        borderRadius: '20px',
        flexDirection: 'column',
        height: 'auto',
        width: 'auto'
      }}
    >
      {children}
    </DialogContent>
  </Dialog>
);

Popup.propTypes = {
  size: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeIconWidth: PropTypes.number,
  closeIconHeight: PropTypes.number
};

Popup.defaultProps = {
  size: '375px',
  closeIconWidth: 24,
  closeIconHeight: 24
};

export default memo(Popup);
