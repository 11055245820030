/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo, useEffect, useCallback, useState
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '../../components/UI/Heading';
import { deleteTarget, getTargetsList } from '../../redux/target/actions';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import CustomButton from '../../components/UI/CustomButton';
import Pagination from '../../components/Pagination/Pagination';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FormCreateTarget from '../../components/Forms/FormCreateTarget';
import SearchInput from '../../components/UI/SearchInput';
import Popup from '../../components/Popup/Popup';
import TargetItem from '../../components/TargetItem/TargetItem';
import Loader from '../../components/UI/Loader/Loader';
import NoData from '../../components/NoData/NoData';
import { targetListResultsSelector } from '../../redux/target/selectors';
import { RESET_EDIT_POPUP_STATE, RESET_TARGETS_LISTS } from '../../redux/target/constants';

import style from './Target.scss';

const Target = () => {
  const dispatch = useDispatch();
  const targetsData = useSelector(targetListResultsSelector);
  const history = useHistory();
  const location = useLocation();
  const { sessionId } = useParams();

  const [isPopupCreateTargetOpen, setIsPopupCreateTargetOpen] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [count, setCount] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [{
    title, date, sessionName, eventId
  }, setEvent] = useState({
    title: '', date: '', sessionName: '', eventId: 0
  });
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    perPage: 10
  });

  const updateUrl = useCallback((query, page, perPage) => {
    const params = new URLSearchParams({
      query,
      page: String(page),
      perPage: String(perPage)
    });

    const newUrl = `${location.pathname}?${params.toString()}`;
    if (location.search !== `?${params.toString()}`) {
      history.push(newUrl);
    }
  }, [history, location.pathname, location.search]);

  const getTargets = useCallback((sessionTargets) => {
    if (!sessionTargets) {
      setIsLoaded(true);
      return;
    }
    const [session] = sessionTargets;
    if (!session || sessionTargets.length === 0) {
      setIsLoaded(true);
      history.push('/');
    }

    if (session) {
      const targets = session?.targets?.map((t) => ({
        targets: [t],
        sessionId: sessionId + '_' + t.targetId,
        sessionName: session.sessionName,
        eventName: session.eventName,
        eventDate: session.eventDate,
        eventId: session.eventId
      }));

      setInitialData(targets);

      const newTotalCount = session?.targets?.length;
      const totalPages = Math.ceil(newTotalCount / pagination.perPage);
      const pageNumber = pagination.pageNumber > totalPages ? totalPages : pagination.pageNumber;
      const startIndex = (pageNumber - 1) * pagination.perPage;
      const endIndex = startIndex + pagination.perPage;

      const paginatedTargets = targets?.slice(startIndex, endIndex);
      setCurrentData(paginatedTargets);
      setCount(session?.targets?.length);
      setEvent({
        title: session.eventName,
        date: session.eventDate,
        sessionName: session.sessionName,
        eventId: session.eventId
      });
      setIsLoaded(true);
    }
  }, [sessionId, history, pagination.pageNumber, pagination.perPage]);

  useEffect(async() => {
    const params = new URLSearchParams(location.search);
    const query = await params.get('query') || '';
    const page = parseInt(params.get('page'), 10) || 1;
    const perPage = parseInt(params.get('perPage'), 10) || 10;

    if (!params.has('query') || !params.has('page') || !params.has('perPage')) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${query}&page=${page}&perPage=${perPage}`
      });
    }
    setSearch(query);
    setPagination({ pageNumber: page, perPage });

    dispatch(getTargetsList({ sessionId }));
  }, [location.search, dispatch, sessionId, history]);

  const handleTargets = useCallback((query, pageNumber, perPage, targets) => {
    const currentTargets = targets ? [...targets] : [...initialData];
    const filteredTargets = query
      ? currentTargets
        .filter((item) => item.targets.some((t) => t.title.toLowerCase().includes(query.toLowerCase())))
      : currentTargets;

    const startIndex = (pageNumber - 1) * perPage;
    const endIndex = startIndex + perPage;

    const paginatedTargets = filteredTargets.slice(startIndex, endIndex);

    setCurrentData(paginatedTargets);
    setCount(filteredTargets.length);
    setSearch(query);
  }, [initialData, count, search]);

  useEffect(() => {
    dispatch({ type: RESET_TARGETS_LISTS });
  }, [dispatch]);

  useEffect(() => {
    if (targetsData?.length) {
      getTargets(targetsData);
    }
  }, [targetsData]);

  useEffect(() => {
    handleTargets(search, pagination.pageNumber, pagination.perPage, initialData);
    if (initialData.length / pagination.perPage <= 1 && initialData.length !== 0) {
      const prevPage = pagination.pageNumber - 1;
      history.replace({
        pathname: location.pathname,
        search: `?query=${search}&page=${prevPage > 0 ? prevPage : 1}&perPage=${pagination.perPage}`
      });
    }
  }, [search, pagination.pageNumber, pagination.perPage, initialData, handleTargets]);

  const handleOpenCreatePopup = useCallback(() => {
    setIsPopupCreateTargetOpen(true);
  }, [setIsPopupCreateTargetOpen]);

  const closePopupCreateTarget = useCallback(() => {
    setIsPopupCreateTargetOpen(false);
  }, [setIsPopupCreateTargetOpen]);

  const onTargetCreateSuccess = useCallback(() => {
    closePopupCreateTarget();
    dispatch({ type: RESET_EDIT_POPUP_STATE });
  }, [closePopupCreateTarget, dispatch]);

  const handlePageChange = (page) => {
    handleTargets(search, page, pagination.perPage);
    updateUrl(search, page, pagination.perPage);
  };

  const handleChangePageSize = (size) => {
    handleTargets(search, 1, size);
    updateUrl(search, 1, size);
  };

  const handleSearch = useCallback((searchValue) => {
    const trimmedValue = searchValue.trim();
    setSearch(trimmedValue);
    updateUrl(trimmedValue, 1, pagination.perPage);
  }, [pagination.perPage, handleTargets, updateUrl]);

  const onSearchCancel = useCallback(() => {
    setSearch('');
    handleTargets('', 1, pagination.perPage);
    updateUrl('', 1, pagination.perPage);
  }, [handleTargets, pagination.perPage, updateUrl]);

  const handleUpdate = useCallback(() => {
    setTimeout(() => {
      dispatch(getTargetsList({ sessionId }));
    }, 1000);
  }, [dispatch, sessionId]);

  const handleDelete = useCallback(async(id) => {
    const targetId = id.split('_')[1];
    await dispatch(deleteTarget({ targetId }));
    setInitialData((prev) => prev.filter((t) => t.sessionId !== id));
    setCount((prev) => prev - 1);
    if (pagination.pageNumber !== 1 && currentData.length <= 1) {
      history.replace({
        pathname: location.pathname,
        search: `?query=${search}&page=${pagination.pageNumber - 1}&perPage=${pagination.perPage}`
      });
    }
  }, [dispatch]);

  return (
    <div className={style.component}>
      <div className={style.titleHolder}>
        <Heading weight={500}>
          {capitalizeFirstLetter(title || 'Without Event')}
        </Heading>
        {date ? (
          <strong className={style.date}>{date}</strong>
        ) : null}
        <CustomButton
          type="button"
          variant="primary"
          className={style.btn}
          onClick={handleOpenCreatePopup}
        >
          Create Target
        </CustomButton>
      </div>
      <Breadcrumb items={
        isLoaded
          ? [
            { name: 'Events', path: '/events' },
            {
              name: title || 'Without Event',
              path: title && title !== 'Without Event' ? `/events/${eventId}` : null,
              disabled: !title || title === 'Without Event'
            },
            { name: sessionName, path: '/' }
          ]
          : []
      }
      />
      <SearchInput
        value={search}
        style={{ marginTop: '24px', marginBottom: '24px' }}
        onChange={handleSearch}
        onCancelSearch={onSearchCancel}
      />
      <div className={style.titleHolder}>
        <Heading weight={500}>
          List of targets
        </Heading>
        <strong className={style.count}>{count || 0}</strong>
      </div>
      {!isLoaded ? (
        <div className={style.loader}>
          <Loader />
        </div>
      ) : (currentData?.length > 0 && isLoaded) ? (
        <ul className={style.listHolder}>
          {currentData?.map((target) => (
            <TargetItem
              key={target.targets[0].targetId}
              target={target}
              handleDeleteItem={handleDelete}
              handleUpdate={handleUpdate}
              targetId={target.targets[0]?.targetId}
            />
          ))}
        </ul>
      ) : (
        <div className={style.noData}>
          <NoData>No targets</NoData>
        </div>
      )}
      {(search ? initialData.filter((item) => item.targets.some((t) => t.title.toLowerCase().includes(search.toLowerCase()))).length : initialData.length) > 0 ? (
        <Pagination
          total={search ? initialData.filter((item) => item.targets.some((t) => t.title.toLowerCase().includes(search.toLowerCase()))).length : initialData.length}
          activePage={pagination.pageNumber}
          perPage={pagination.perPage}
          handlePageChange={handlePageChange}
          handleChangePageSize={handleChangePageSize}
        />
      ) : null}
      <Popup
        open={isPopupCreateTargetOpen}
        handleClose={closePopupCreateTarget}
        size="529px"
        closeIconWidth={32}
        closeIconHeight={32}
      >
        <FormCreateTarget onSuccess={onTargetCreateSuccess} defaultEventId={eventId} />
      </Popup>
    </div>
  );
};

export default memo(Target);
