import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    textTransform: 'none'
  }
});

const Button = ({ type, children, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialButton
      type={type}
      variant="contained"
      color="primary"
      classes={{ root: classes.root }}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </MaterialButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node
};

Button.defaultProps = {
  type: 'button',
  children: null
};

export default memo(Button);
