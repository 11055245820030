import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { targetSelector } from '../../redux/target/selectors';

import { setAlert } from '../../redux/app/actions';
import { setTargetFields } from '../../redux/target/actions';

import Target from './Target';

const targetImageSourceSelector = createSelector(
  targetSelector,
  (target) => target?.photoUrl || null
);

const targetVideoSourceSelector = createSelector(
  targetSelector,
  (target) => target?.videoUrl || null
);

const mapStateToProps = createStructuredSelector({
  targetImageSource: targetImageSourceSelector,
  targetVideoSource: targetVideoSourceSelector
});

const onImageUploadSuccess = (action) => (dispatch) => {
  dispatch(setAlert('Image upload success'));
  const targetImage = action?.request?.response?.data?.uid;
  const targetImageSrc = action?.request?.response?.data?.photo;

  if (targetImage) {
    dispatch(setTargetFields({
      photo: targetImage,
      photoUrl: targetImageSrc
    }));
  }
};

const onVideoUploadSuccess = (action) => (dispatch) => {
  dispatch(setAlert('Video upload success'));
  const targetVideo = action?.request?.response?.data?.uid;
  const targetVideoSrc = action?.request?.response?.data?.video;

  if (targetVideo) {
    dispatch(setTargetFields({
      video: targetVideo,
      videoUrl: targetVideoSrc
    }));
  }
};

const onTargetUploadSuccess = () => (dispatch) => {
  dispatch(setAlert('Target edit success'));
};

const mapDispatchToProps = {
  onImageUploadSuccess,
  onVideoUploadSuccess,
  onTargetUploadSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(Target);
