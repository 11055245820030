import React, { memo, useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { TrackChanges } from '@material-ui/icons';

import Dashboard from '../../../icons/Dashboard';
import Add from '../../../icons/Add';
import Calendar from '../../../icons/Calendar';
import UserBar from '../UserBar';
import Popup from '../../Popup/Popup';
import FormCreateTarget from '../../Forms/FormCreateTarget';
import { fetchTargetsCount, getTargetsList } from '../../../redux/target/actions';
import { RESET_EDIT_POPUP_STATE, RESET_NEW_TARGET } from '../../../redux/target/constants';
import LogoVide from '../../UI/Logo/LogoVide';

import { noop } from '../../../utils';

import style from './AsideBar.scss';

const AsideBar = ({ authorized, setMenuOpened }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [isPopupCreateTargetOpen, setIsPopupCreateTargetOpen] = useState(false);

  const handleOpenCreatePopup = useCallback(() => {
    dispatch({ type: RESET_NEW_TARGET });
    setIsPopupCreateTargetOpen(true);
    setMenuOpened(false);
  }, [setIsPopupCreateTargetOpen, dispatch, setMenuOpened]);

  const closePopupCreateTarget = useCallback(() => {
    setIsPopupCreateTargetOpen(false);
    dispatch({ type: RESET_EDIT_POPUP_STATE });
  }, [setIsPopupCreateTargetOpen, dispatch]);

  const onTargetCreateSuccess = useCallback(() => {
    setTimeout(() => {
      dispatch(fetchTargetsCount());
      dispatch(getTargetsList());
    }, 1000);
    closePopupCreateTarget();
  }, [dispatch, closePopupCreateTarget]);

  const handleLinkClick = () => {
    setMenuOpened(false);
  };

  return (
    <>
      <aside className={style.aside}>
        <NavLink className={style.logo} exact to="/">
          <LogoVide className={style.logoVide} />
        </NavLink>
        <nav className={style.menu}>
          <ul>
            <MenuItem key="home" selected={pathname === '/'}>
              <NavLink
                exact
                to="/"
                className={`${style.link} ${
                  pathname === '/' ? style.active : ''
                }`}
                onClick={handleLinkClick}
              >
                <Dashboard />
                <span>Dashboard</span>
              </NavLink>
            </MenuItem>
            <MenuItem key="add-target">
              <button
                type="button"
                className={style.createBtn}
                onClick={handleOpenCreatePopup}
              >
                <Add />
                <span>Create Target</span>
              </button>
            </MenuItem>
            <MenuItem key="events" selected={pathname === '/events/'}>
              <NavLink
                exact
                to="/events/"
                className={`${style.link} ${
                  pathname.includes('/events') ? style.active : ''
                }`}
                onClick={handleLinkClick}
              >
                <Calendar />
                <span>Events</span>
              </NavLink>
            </MenuItem>
            <MenuItem key="targets" selected={pathname === '/targets/'}>
              <NavLink
                exact
                to="/targets/"
                className={`${style.link} ${
                  pathname === '/targets/' ? style.active : ''
                }`}
                onClick={handleLinkClick}
              >
                <TrackChanges />
                <span>Targets</span>
              </NavLink>
            </MenuItem>
          </ul>
        </nav>
        <div className={style.userBar}>
          {authorized && <UserBar setMenuOpened={setMenuOpened} />}
        </div>
      </aside>
      <Popup
        open={isPopupCreateTargetOpen}
        handleClose={closePopupCreateTarget}
        size="529px"
        closeIconWidth={32}
        closeIconHeight={32}
      >
        <FormCreateTarget onSuccess={onTargetCreateSuccess} />
      </Popup>
    </>
  );
};

AsideBar.propTypes = {
  authorized: PropTypes.bool,
  setMenuOpened: PropTypes.func
};

AsideBar.defaultProps = {
  authorized: false,
  setMenuOpened: noop
};

export default memo(AsideBar);
