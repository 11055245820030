import { connect } from 'react-redux';

import { uploadTargetFile } from '../../../redux/target/actions';

import FormTargetVideoAdd from './FormTargetVideoAdd';

const mapDispatchToProps = {
  onSubmit: uploadTargetFile
};

export default connect(null, mapDispatchToProps)(FormTargetVideoAdd);
