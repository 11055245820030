import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { dataSelector, userIdSelector } from '../../../redux/account/selectors';
import { updateAccount } from '../../../redux/account/actions';

import FormAccountEdit from './FormAccountEdit';

const initialDataSelector = createSelector(
  dataSelector,
  (data) => ({
    email: data?.email || '',
    name: data?.name || '',
    mfaEnable: data?.mfaEnable || false
  })
);

const mapStateToProps = createStructuredSelector({
  initialData: initialDataSelector
});

const mapDispatchToProps = {
  onSubmit: (data) => (dispatch, getState) => {
    const userId = userIdSelector(getState());
    return dispatch(updateAccount(userId, data));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAccountEdit);
