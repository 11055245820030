/* eslint-disable max-len */
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'; // eslint-disable-line import/no-extraneous-dependencies
import cn from 'classnames';

import ErrorMessage from '../ErrorMessage';
import Alert from '../Alert';
import { UPLOAD_MEDIA, UPLOAD_MEDIA_SUCCESS } from '../../../redux/target/constants';
import { getUploadFiles } from '../../../redux/target/selectors';
import { noop, isNotEmptyArray } from '../../../utils';

import style from './FileDropzone.scss';

const FileDropzone = ({
  onChange,
  onSuccess,
  onFail,
  text,
  limit,
  acceptedFiles: acceptedFileTypes,
  maxFileSize,
  disabled
}) => {
  const dispatch = useDispatch();
  const currentUploadedFiles = useSelector(getUploadFiles);

  const [uploadedFiles, setUploadedFiles] = useState([]); // eslint-disable-line no-unused-vars
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    type: 'success',
    message: ''
  });

  const handleCloseAlert = () => {
    setAlertMsg({ ...alertMsg, open: false });
  };

  const showAlert = (message, type = 'error') => {
    setAlertMsg({ open: true, type, message });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (!isNotEmptyArray(acceptedFiles)) {
      return;
    }
    setError(null);
    setProcessing(true);

    const newFiles = acceptedFiles
      .filter((file) => !currentUploadedFiles?.some((uploadedFile) => uploadedFile.name === file.name))
      .sort((a, b) => a.name.localeCompare(b.name));

    dispatch({ type: UPLOAD_MEDIA, payload: newFiles });

    if (newFiles.length === 0) {
      setProcessing(false);
      showAlert(`File with name ${acceptedFiles[0].name} already exists in the target`);
      dispatch({ type: UPLOAD_MEDIA_SUCCESS });
      return;
    }

    newFiles.reduce(
      (promise, file) => promise.then(() => onChange({ file }).then((action) => {
        if (action.success) {
          onSuccess({ ...action, file });
          setUploadedFiles((prevFiles) => [...prevFiles, file]);
        } else {
          onFail(action);
          setError(action?.request?.response?.statusText || null);
        }
      })),
      Promise.resolve()
    ).finally(() => {
      setProcessing(false);
      setUploadedFiles([]);
      dispatch({ type: UPLOAD_MEDIA_SUCCESS });
    });
  }, [onChange, onSuccess, onFail, currentUploadedFiles, dispatch]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(','),
    maxSize: maxFileSize,
    multiple: limit > 1
  });

  // const handleFileLoadError = useCallback(
  //   (_, __, componentMaxFileSize) => `File is too big. Size limit is ${componentMaxFileSize / 1024} MB.`,
  //   []
  // );

  return (
    <>
      <div {...getRootProps({ className: cn(style.dropzone, { [style.disabled]: processing || disabled }) })}>
        <input {...getInputProps()} disabled={disabled} />
        <span>
          {text}
        </span>
      </div>
      {error && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
      {processing && (
        <div className={style.progress}>
          <LinearProgress />
        </div>
      )}
      <Alert
        open={alertMsg.open}
        handleCloseAlert={handleCloseAlert}
        type={alertMsg.type}
      >
        {alertMsg.message}
      </Alert>
    </>
  );
};

FileDropzone.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  maxFileSize: PropTypes.number,
  limit: PropTypes.number,
  disabled: PropTypes.bool
};

FileDropzone.defaultProps = {
  name: 'file',
  onChange: noop,
  onSuccess: noop,
  onFail: noop,
  acceptedFiles: ['image/*', 'video/*'],
  text: 'Drag and drop a file here or click',
  maxFileSize: 314572800, // 300MB
  limit: 30,
  disabled: false
};

export default memo(FileDropzone);
