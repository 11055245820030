import React, { memo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import RedirectConditionRoute from '../../components/RedirectConditionRoute';

import SignIn from '../../pages/SignIn/SignIn';
import SignUp from '../../pages/SignUp/SignUp';
import PasswordReset from '../../pages/PasswordReset';
import Home from '../../pages/Home';
import AddTarget from '../../pages/AddTarget';
import Targets from '../../pages/Targets';
import Target from '../../pages/Target';
// import Scales from '../../pages/Scales';
import Scale from '../../pages/Scale';
import Events from '../../pages/Events/Events';
import TargetDetail from '../../pages/TargetDetail/TargetDetail';
import EventDetail from '../../pages/EventDetail/EventDetail';

// eslint-disable-next-line arrow-body-style
const Router = ({ indexRedirectTo }) => {
  return (
    <Switch>
      {/* Unauthorized routes */}

      <RedirectConditionRoute
        path="/sign-in/"
        component={SignIn}
        conditions={{ authorized: false }}
      />

      <RedirectConditionRoute
        path="/sign-up/"
        component={SignUp}
        conditions={{ authorized: false }}
      />

      <RedirectConditionRoute
        path="/reset-password/"
        component={PasswordReset}
        conditions={{ authorized: false }}
      />

      {/* Authorized routes */}

      <RedirectConditionRoute
        exact
        path="/"
        component={Home}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/add-target/"
        component={AddTarget}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/targets/"
        component={Targets}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/target/:sessionId"
        component={Target}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/events/:eventId/targets"
        component={TargetDetail}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/events/"
        component={Events}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/events/:eventId"
        component={EventDetail}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/scale/:scaleId"
        component={Scale}
        conditions={{ authorized: true }}
      />

      <Redirect to={indexRedirectTo} />
    </Switch>
  );
};

Router.propTypes = {
  indexRedirectTo: PropTypes.string
};

Router.defaultProps = {
  indexRedirectTo: '/'
};

export default memo(Router);
