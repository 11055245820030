import { localStorage, isSuccessAction } from '../../utils';
import { createActionRequestWithAuth } from '../utils';
import {
  SET_TOKEN,
  DELETE_TOKEN,
  ADD_DEFERRED_ACTION,
  CLEAR_DEFERRED_ACTIONS,
  POST_REGISTRATION,
  POST_REGISTRATION_RESEND,
  POST_REGISTRATION_CONFIRM,
  POST_PASSWORD_RESET_REQUEST,
  POST_PASSWORD_RESET,
  POST_LOGIN,
  POST_OAUTH_CODE,
  POST_REFRESH_TOKEN,
  POST_CONFIRMATION_CODE,
  POST_LOGIN_SUCCESS
} from './constants';
import {
  refreshTokenSelector,
  refreshingSelector,
  deferredActionsSelector,
  userIdSelector
} from './selectors';
import { getAccount } from '../account/actions';

const onTokensUpdate = (action) => {
  if (isSuccessAction(action)) {
    const { data } = action.request.response;
    localStorage.setItem('auth_token', data.access);
    localStorage.setItem('auth_refresh_token', data.refresh);
  }

  return action;
};

const onLoginSuccess = () => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  if (!userId) {
    return;
  }

  dispatch(getAccount(userId));
};

export const registration = ({ name, email, password }) => (dispatch) => dispatch({
  type: POST_REGISTRATION,
  request: {
    method: 'POST',
    endpoint: 'registration/',
    data: {
      name,
      email,
      password
    }
  }
});

export const registrationResend = ({ email }) => (dispatch) => dispatch({
  type: POST_REGISTRATION_RESEND,
  request: {
    method: 'POST',
    endpoint: 'registration/resend/',
    data: {
      email
    }
  }
});

export const registrationConfirm = ({ email, code }) => (dispatch) => dispatch({
  type: POST_REGISTRATION_CONFIRM,
  request: {
    method: 'POST',
    endpoint: 'registration/confirm/',
    data: {
      email,
      code
    }
  }
}).then((action) => {
  onTokensUpdate(action);
  dispatch(onLoginSuccess());
});

export const requestPasswordReset = ({ email }) => (dispatch) => dispatch({
  type: POST_PASSWORD_RESET_REQUEST,
  request: {
    method: 'POST',
    endpoint: 'restore-password/',
    data: {
      email
    }
  }
});

export const resetPassword = ({ email, code, password }) => (dispatch) => dispatch({
  type: POST_PASSWORD_RESET,
  request: {
    method: 'PUT',
    endpoint: 'restore-password/restore/',
    data: {
      email,
      code,
      password
    }
  }
});

export const loginJWT = ({ email, password }) => (dispatch) => dispatch({
  type: POST_LOGIN,
  request: {
    method: 'POST',
    endpoint: 'token/',
    data: {
      email,
      password
    }
  }
}).then((action) => {
  if (action.request.response.statusCode !== 200) {
    return { errors: action.request.response.data };
  }
  if (action.request.response.data.mfaEnable) {
    return action.request.response.data;
  }
  onTokensUpdate(action);
  dispatch({ type: POST_LOGIN_SUCCESS, payload: action.request.response.data });
  dispatch(onLoginSuccess());
  return null;
});

export const get2faCode = ({ email }) => (dispatch) => dispatch({
  type: POST_CONFIRMATION_CODE,
  request: {
    method: 'POST',
    endpoint: 'auth-code/sent/',
    data: {
      email
    }
  }
});

export const confirmCode = (data) => (dispatch) => dispatch({
  type: POST_CONFIRMATION_CODE,
  request: {
    method: 'POST',
    endpoint: 'auth-code/check/',
    data
  }
}).then((action) => {
  onTokensUpdate(action);
  dispatch(onLoginSuccess());
  return action;
});

export const loginSocial = (provider, code, redirectUri) => (dispatch) => dispatch({
  type: POST_OAUTH_CODE,
  request: {
    method: 'POST',
    endpoint: 'social/jwt/',
    data: {
      provider,
      code,
      redirectUri
    }
  }
}).then((action) => {
  onTokensUpdate(action);
  dispatch(onLoginSuccess());
});

export const refreshToken = () => (dispatch, getState) => dispatch(createActionRequestWithAuth({
  type: POST_REFRESH_TOKEN,
  request: {
    method: 'POST',
    endpoint: 'token/refresh/',
    data: {
      refresh: refreshTokenSelector(getState())
    }
  }
})).then(onTokensUpdate);

export const setTokens = () => (dispatch) => {
  const lsToken = localStorage.getItem('auth_token') || null;
  const lsRefreshToken = localStorage.getItem('auth_refresh_token') || null;

  if (!lsToken || !lsRefreshToken) {
    return;
  }

  dispatch({
    type: SET_TOKEN,
    token: lsToken,
    refreshToken: lsRefreshToken
  });

  dispatch(onLoginSuccess());
};

export const clearTokens = () => {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('auth_refresh_token');

  return {
    type: DELETE_TOKEN
  };
};

export const addDeferredAction = (action) => ({
  type: ADD_DEFERRED_ACTION,
  action
});

export const clearDeferredActions = () => ({
  type: CLEAR_DEFERRED_ACTIONS
});

export const onUnauthorized = (deferredAction) => (dispatch, getState) => {
  dispatch(addDeferredAction(deferredAction));

  const refreshing = refreshingSelector(getState());

  if (refreshing) {
    return Promise.resolve();
  }

  return dispatch(refreshToken()).then((action) => {
    if (!isSuccessAction(action)) {
      dispatch(clearTokens());
    } else {
      const deferredActions = deferredActionsSelector(getState());
      const promises = deferredActions.map((a) => dispatch(createActionRequestWithAuth(a)).then((response) => {
        if (response.status === 'SUCCESS') {
          dispatch({
            type: `${a.type}_SUCCESS`,
            payload: response.request.response.data
          });
        } else {
          dispatch({
            type: `${a.type}_FAILURE`,
            error: response.error || 'Failed to refresh token and retry request'
          });
        }
      }));

      dispatch(clearDeferredActions());

      return Promise.all(promises);
    }

    return action;
  });
};
