import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from './config';
import history from './clientHistory';
import configureStore from './redux';
import { setTokens } from './redux/auth/actions';
import App from './app';

import './style/index.scss';

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    tracesSampleRate: 1.0,
    blacklistUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extensions:\/\//i
    ]
  });
}

const store = configureStore();

store.dispatch(setTokens());

ReactDOM.render(
  <App
    store={store}
  />,
  document.getElementById('root')
);
