import merge from 'deepmerge';

const defaultConfig = {
  APP_PROTOCOL: '',
  APP_HOST: '',
  APP_PATH: '/',

  API_PROTOCOL: '',
  API_HOST: '',
  API_PATH: '/api/v1/',

  SENTRY_DSN: '',

  GOOGLE_APP_ID: '',
  FACEBOOK_APP_ID: ''
};

let runtimeConfig = {};

/* eslint-disable no-underscore-dangle */
if (typeof window !== 'undefined' && window.__config && typeof window.__config === 'object') {
  runtimeConfig = window.__config;
}
/* eslint-enable no-underscore-dangle */

const config = merge(defaultConfig, runtimeConfig);

export default config;
