/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Close from '../../icons/Close';
import Dots from '../../icons/Dots';
import Eye from '../../icons/Eye';
import InputText from '../UI/InputText';
import useFormState from '../../hooks/useFormState';
import Switch from '../UI/Switch';

import { noopPromise } from '../../utils';
import { deleteAsset } from '../../redux/target/actions';
import FormTargetMediaAdd from '../Forms/FormTargetMediaAdd/FormTargetMediaAdd';
import Alert from '../UI/Alert';
import PreviewPopUp from '../PreviewPopup/PreviewPopUp';
import usePopupState from '../../hooks/usePopupState';
import ArrowDown from '../../icons/ArrowDown';

import style from './DrugNDrop.scss';
import { getIsFetchingSelector, getUploadFiles } from '../../redux/target/selectors';
import { scrollToTarget } from '../../utils/DOM';

const RULES = {
  title: {
    required: true,
    custom: (value) => (value?.length > 150 ? 'Title must be less than 150 characters length' : '')
  }
};

const DragNDrop = ({
  onMount,
  targets,
  images,
  videos,
  setPairedData,
  setSingleImage,
  setSingleVideo,
  handleDeleteItem,
  isCreateForm,
  editTargetId
}) => {
  const dispatch = useDispatch();
  const targetsListRef = useRef(null);
  const uploadFiles = useSelector(getUploadFiles) || [];
  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, [onMount]);

  const [isBtnVisible, setIsBtnVisible] = useState(false);
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const { onToggle, isOpen } = usePopupState();
  const [allSoundsOn, setAllSoundsOn] = useState(true);
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedType, setDraggedType] = useState(null);
  const [selectedTargetId, setSelectedTargetId] = useState(null);
  const [initialTargetsLength, setInitialTargetsLength] = useState(0);
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    type: 'success',
    message: ''
  });

  const flag = useRef(true);

  useEffect(() => {
    if (flag.current && targets.length) {
      setInitialTargetsLength(targets.length);
      flag.current = false;
    }
  }, [targets]);

  const photoFiles = uploadFiles?.filter((file) => file.name?.match(/\.(jpg|jpeg|png|gif)$/i));
  const videoFiles = uploadFiles?.filter((file) => file.name?.match(/\.(mp4|mov|avi|mkv)$/i));

  const photoCount = photoFiles?.length ?? 0;
  const videoCount = videoFiles?.length ?? 0;
  const expectedTargetsCount = isCreateForm
    ? Math.max(photoCount, videoCount, targets?.length || 0)
    : Math.max(photoCount, videoCount, (targets.length - initialTargetsLength)) || 0;

  const scrollToEnd = () => {
    if (targetsListRef.current) {
      targetsListRef.current.scrollTo({
        top: targetsListRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = () => {
    const isAtBottom = targetsListRef.current.scrollHeight - targetsListRef.current.scrollTop === targetsListRef.current.clientHeight;
    setIsBtnVisible(!isAtBottom);
  };

  const toggleButtonVisibility = () => {
    if (targetsListRef.current.clientHeight < targetsListRef.current.scrollHeight) {
      setIsBtnVisible(true);
      setIsBorderVisible(true);
    } else {
      setIsBtnVisible(false);
      setIsBorderVisible(false);
    }
  };

  useEffect(() => {
    const currentTargetsListRef = targetsListRef.current;
    if (currentTargetsListRef) {
      currentTargetsListRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentTargetsListRef) {
        currentTargetsListRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    toggleButtonVisibility();
    window.addEventListener('resize', toggleButtonVisibility);
    return () => window.removeEventListener('resize', toggleButtonVisibility);
  }, [targets]);

  useEffect(() => {
    if (editTargetId) {
      const element = document.getElementById(editTargetId);
      if (element) {
        const scrollableContainer = document.getElementById('targets-list');
        const targetElement = document.getElementById(editTargetId.toString());
        targetElement.style.borderBottom = '1px solid #d1d1d1';
        scrollToTarget(targetElement, scrollableContainer);
      }
    }
  }, [editTargetId, targets]);

  const handleSwitchChange = (checked, index) => {
    const updatedTargets = [...targets];
    updatedTargets[index].sound = checked;
    setPairedData(updatedTargets);

    const allOn = updatedTargets.every((item) => item.sound);
    setAllSoundsOn(allOn);
  };

  const handleToggleAllSounds = () => {
    const newValue = !allSoundsOn;
    setAllSoundsOn(newValue);

    const updatedTargets = targets.map((item) => ({
      ...item,
      sound: newValue
    }));
    setPairedData(updatedTargets);
  };

  const handleTitleChange = useCallback((value, index) => {
    if (index >= targets?.length || value === null || value === undefined) {
      return;
    }
    const updatedTargets = targets?.map((item, i) => {
      if (i === index) {
        return { ...item, title: value };
      }
      return item;
    });
    setPairedData(updatedTargets);
  }, [targets, setPairedData]);

  const { processing, errors } = useFormState(targets, RULES);

  const photoRef = useRef(null);
  const videoRef = useRef(null);

  const handleDragStart = (event, index, type) => {
    if (type === 'photo') {
      photoRef.current = event.target;
    } else if (type === 'video') {
      videoRef.current = event.target;
    }
    setDraggedItem(index);
    setDraggedType(type);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, index, type) => {
    event.preventDefault();

    if (draggedType !== type) {
      return;
    }

    const newTargets = [...targets];
    const newImages = [...images];
    const newVideos = [...videos];

    if (type === 'photo') {
      const draggedPhotoUrl = newTargets[draggedItem].photoUrl;
      const draggedPhotoVector = newTargets[draggedItem].photoVector;

      newTargets[draggedItem].photoUrl = newTargets[index].photoUrl;
      newTargets[draggedItem].photoVector = newTargets[index].photovector;

      newTargets[index].photoUrl = draggedPhotoUrl;
      newTargets[index].photoVector = draggedPhotoVector;

      const draggedPhotoItem = newImages[draggedItem];
      newImages[draggedItem] = newImages[index];
      newImages[index] = draggedPhotoItem;
    } else if (type === 'video') {
      const draggedVideo = newTargets[draggedItem]?.videoUrl;
      newTargets[draggedItem].videoUrl = newTargets[index]?.videoUrl;
      newTargets[index].videoUrl = draggedVideo;

      const draggedVideoItem = newVideos[draggedItem];
      newVideos[draggedItem] = newVideos[index];
      newVideos[index] = draggedVideoItem;
    }

    setSingleVideo([...newVideos]);
    setSingleImage([...newImages]);
    setPairedData([...newTargets]);
    setDraggedItem(null);
    setDraggedType(null);
  };

  useEffect(() => {
    const existingItemsMap = (targets || []).reduce((accumulatedMap, item, index) => {
      const key = index;
      return { ...accumulatedMap, [key]: item };
    }, {});

    const pairedItems = images?.map((imageObj, index) => {
      const photo = imageObj?.photo || imageObj?.photoUrl;
      const video = videos[index]?.videoUrl || videos[index]?.video;
      const imageKey = index;
      const existingItem = existingItemsMap[imageKey] || {};

      const pairedItem = {
        targetId: existingItem.targetId,
        photoUrl: photo?.replace('http://localhost:3000', 'http://localhost:8080') || null,
        videoUrl: video?.replace('http://localhost:3000', 'http://localhost:8080') || null,
        title: existingItem.title || '',
        sound: existingItem.sound === undefined ? true : existingItem.sound,
        videoConverted: existingItem.videoConverted === undefined ? true : existingItem.videoConverted,
        photoConverted: existingItem.photoConverted === undefined ? true : existingItem.photoConverted
      };

      if (imageObj?.photoVector || isCreateForm) {
        pairedItem.photoVector = imageObj?.photoVector || null;
      }

      return pairedItem;
    });

    for (let i = images?.length; i < videos?.length; i++) {
      const video = videos[i]?.videoUrl || videos[i]?.video;
      const videoKey = video?.replace('http://localhost:3000', 'http://localhost:8080');
      const existingItem = existingItemsMap[videoKey] || {};

      const pairedItem = {
        photoUrl: null,
        targetId: existingItem.targetId,
        videoUrl: videoKey || null,
        title: existingItem.title || '',
        sound: existingItem.sound === undefined ? true : existingItem.sound,
        videoConverted: existingItem.videoConverted === undefined ? true : existingItem.videoConverted,
        photoConverted: existingItem.photoConverted === undefined ? true : existingItem.photoConverted
      };

      if (isCreateForm) {
        pairedItem.photoVector = null;
      }

      pairedItems.push(pairedItem);
    }
    const isAllSoundsOn = pairedItems.every((p) => p.sound);
    setAllSoundsOn(isAllSoundsOn);
    setPairedData(pairedItems.filter((p) => p.photoUrl || p.videoUrl));
  }, [images, videos]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseAlert = () => {
    setAlertMsg({ ...alertMsg, open: false });
  };

  const showAlert = (message, type = 'error') => {
    setAlertMsg({ open: true, type, message });
  };

  const onFileUpload = (action, index) => {
    const isImage = action.url.includes('photos');
    const isVideo = action.url.includes('videos');
    const fileName = action?.file.name;

    const { url } = action;

    const newTargets = [...targets];
    if (isImage) {
      const isImageExists = images?.some((img) => img?.photoUrl?.includes(fileName));
      if (isImageExists) {
        showAlert(`Photo ${fileName} already exists in the target`);

        return;
      }
      const newImage = {
        photoUrl: url
      };

      setTimeout(() => {
        setSingleImage((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = newImage;
          return updatedImages;
        });
      }, 100);
      newTargets[index] = {
        ...newTargets[index],
        ...newImage
      };
    }

    if (isVideo) {
      const isVideoExists = videos?.some((video) => video?.videoUrl?.includes(fileName));
      if (isVideoExists) {
        showAlert(`Video ${fileName} already exists in the target`);
        return;
      }
      const newVideo = {
        videoUrl: url
      };
      setTimeout(() => {
        setSingleVideo((prevVideos) => {
          const updatedVideos = [...prevVideos];
          updatedVideos[index] = newVideo;
          return updatedVideos;
        });
      }, 100);

      newTargets[index] = {
        ...newTargets[index],
        ...newVideo
      };
    }

    setTimeout(() => {
      setPairedData(newTargets);
    }, 100);
  };

  const handleAssetDelete = async(url, index) => {
    if (!url) {
      return;
    }

    if (isCreateForm) {
      await dispatch(deleteAsset(url));
    }
    handleDeleteItem(url, index);
  };

  const getCorrectProp = (name, index) => (`${name}${index}`);

  const openPreview = (targetId) => {
    setSelectedTargetId(targetId);
    onToggle();
  };

  const placeholders = Array.from({ length: expectedTargetsCount }, (_, index) => ({ // eslint-disable-line no-unused-vars
    targetId: null,
    photoUrl: null,
    videoUrl: null,
    title: '',
    sound: true,
    videoConverted: true,
    photoConverted: true,
    isPlaceholder: true
  }));

  const isFetching = useSelector(getIsFetchingSelector);
  const combinedTargets = targets.concat(placeholders.slice(targets.length));

  return (
    <>
      {targets.length > 0 && (
        <div className={style.switchHolder}>
          <div className={style.targetsCount}>
            {isCreateForm ? (
              <>
                <p>Loaded</p>
                <strong className={style.count}>{targets.length}</strong>
                {expectedTargetsCount > 0 ? (
                  <>
                    <p>of</p>
                    <strong className={style.count}>
                      {expectedTargetsCount}
                    </strong>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <p>Targets</p>
                <strong className={style.count}>{targets.length}</strong>
                {(expectedTargetsCount - initialTargetsLength) > 0 && (targets.length - initialTargetsLength) > 0 ? (
                  <>
                    <p className={style.uploadCount}>Loaded</p>
                    <strong className={style.count}>{(targets.length - initialTargetsLength) > 0 ? (targets.length - initialTargetsLength) : 0}</strong>
                    <p>of</p>
                    <strong className={style.count}>{(targets.length - initialTargetsLength) < (expectedTargetsCount - initialTargetsLength) ? expectedTargetsCount - initialTargetsLength : targets.length - initialTargetsLength}</strong>
                  </>
                ) : null}
              </>
            )}
          </div>
          {targets.length > 1 && (
            <Switch
              id={getCorrectProp('toggler-all')}
              label="All Sounds"
              checked={allSoundsOn}
              onChange={handleToggleAllSounds}
            />
          )}
        </div>
      )}
      <ul
        className={`${style.targetsList} ${isBorderVisible ? style.targetsListLong : ''
        }`}
        ref={targetsListRef}
        id="targets-list"
      >
        {combinedTargets?.map((item, index) => {
          const isDisabled = editTargetId && item.targetId !== editTargetId;
          const { isPlaceholder } = item;
          const isNewPhoto = !uploadFiles.some((el) => el.name === item.photoUrl?.split('_').pop()) ? !isFetching : true;
          const isNewVideo = !uploadFiles.some((el) => el.name === item.videoUrl?.split('_').pop()) ? !isFetching : true;

          return (
            <li
              key={item.videoUrl || item.photoUrl || index}
              className={isDisabled ? style.disableListItem : style.listItem}
              id={item.targetId || index}
            >
              <div
                // eslint-disable-next-line prefer-template
                className={isDisabled ? style.dragPhoto + ' ' + style.disabled : style.dragPhoto}
                draggable={!editTargetId && !isPlaceholder}
                onDragStart={(event) => handleDragStart(event, index, 'photo')}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, index, 'photo')}
              >
                {!isPlaceholder && isNewPhoto ? (
                  item.photoUrl ? (
                    <>
                      <div className={style.image}>
                        <img
                          width="56"
                          height="56"
                          src={item.photoUrl}
                          alt="dragPhoto"
                        />
                        {!isDisabled
                          && (
                            <button
                              type="button"
                              aria-label="edit button"
                              // eslint-disable-next-line prefer-template
                              className={isDisabled ? style.btnEdit + ' ' + style.disabled : style.btnEdit}
                              onClick={() => openPreview(item.targetId || item.videoUrl || item.photoUrl)}
                            >
                              <Eye width={16} height={16} />
                            </button>
                          )}
                        {!isCreateForm
                          ? (() => {
                            let className = style.loadIndicateConverting;
                            let title = 'Converting...';

                            if (item.photoConverted === true) {
                              className = style.loadIndicateSuccess;
                              title = 'Converted successfully!';
                            } else if (item.photoConverted === false) {
                              className = style.loadIndicateError;
                              title = 'Failure! Please delete and reload!';
                            }

                            return (
                              <span
                                className={className}
                                aria-label="Loading indicate"
                                title={title}
                              />
                            );
                          })()
                          : null}
                      </div>
                      <button
                        aria-label="delete button"
                        type="button"
                        className={style.btnRemove}
                        onClick={() => handleAssetDelete(item.photoUrl, index)}
                        disabled={isDisabled}
                      >
                        <Close width={16} height={16} />
                      </button>
                      <button
                        type="button"
                        aria-label="drag button"
                        className={style.btnDrag}
                        disabled={isDisabled}
                      >
                        <Dots />
                      </button>
                    </>
                  ) : (
                    <div className={style.dragHolder}>
                      <FormTargetMediaAdd
                        acceptedFiles={['image/*']}
                        text="Upload Picture"
                        limit={1}
                        onSuccess={(data) => onFileUpload(data, index)}
                      />
                    </div>
                  )
                ) : (
                  <div className={style.placeholder} />
                )}
              </div>
              <div
                // eslint-disable-next-line prefer-template
                className={isDisabled ? style.dragVideo + ' ' + style.disabled : style.dragVideo}
                draggable
                onDragStart={(event) => handleDragStart(event, index, 'video')}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, index, 'video')}
              >
                {!isPlaceholder && isNewVideo ? (item.videoUrl ? (
                  <>
                    <div className={style.image}>
                      <video width="56" height="40">
                        <source src={item.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      {!isDisabled && (
                        <button
                          type="button"
                          aria-label="edit button"
                          className={style.btnEdit}
                          onClick={() => openPreview(item.targetId || item.videoUrl)}
                        >
                          <Eye width={16} height={16} />
                        </button>
                      )}
                      {!isCreateForm
                        ? (() => {
                          let className = style.loadIndicateConverting;
                          let title = 'Converting...';

                          if (item.videoConverted === true) {
                            className = style.loadIndicateSuccess;
                            title = 'Converted successfully!';
                          } else if (item.videoConverted === false) {
                            className = style.loadIndicateError;
                            title = 'Failure! Please delete and reload!';
                          }

                          return (
                            <span
                              className={className}
                              aria-label="Loading indicate"
                              title={title}
                            />
                          );
                        })()
                        : null}
                    </div>
                    <button
                      aria-label="delete button"
                      type="button"
                      className={style.btnRemove}
                      onClick={() => handleAssetDelete(item.videoUrl, index)}
                      disabled={isDisabled}
                    >
                      <Close width={16} height={16} />
                    </button>
                    <button
                      type="button"
                      aria-label="drag button"
                      className={style.btnDrag}
                      disabled={isDisabled}
                    >
                      <Dots />
                    </button>
                  </>
                ) : (
                  <div className={style.dragHolder}>
                    <FormTargetMediaAdd
                      text="Upload Video"
                      acceptedFiles={['video/*']}
                      limit={1}
                      onSuccess={(data) => onFileUpload(data, index)}
                    />
                  </div>
                )) : (
                  <div className={style.placeholder} />
                )}
              </div>
              <div className={style.actions}>
                <div className={style.nameField}>
                  <InputText
                    className={style.name}
                    type="text"
                    id={getCorrectProp('tname-', index)}
                    name={getCorrectProp('tname-', index)}
                    placeholder="Target name"
                    errors={errors.title}
                    disabled={processing || isDisabled}
                    value={targets[index]?.title || ''}
                    onChange={(value) => handleTitleChange(value || '', index)}
                    fullWidth
                  />
                </div>
                <Switch
                  id={getCorrectProp('toggler-', index)}
                  checked={item.sound}
                  name={getCorrectProp('soundOn-', index)}
                  onChange={(checked) => handleSwitchChange(checked, index)}
                  disabled={isDisabled}
                />
              </div>
            </li>
          );
        })}
      </ul>
      <div className={style.btnHolder}>
        <button
          type="button"
          className={`${style.btnArrow} ${isBtnVisible && style.visible}`}
          onClick={scrollToEnd}
          aria-label="Scroll to end"
        >
          <ArrowDown className={style.svgArrow} />
        </button>
      </div>
      {
        isOpen && (
          <PreviewPopUp
            targets={targets.map((t) => ({
              ...t,
              targetId: t.targetId ? t.targetId : t.videoUrl || t.photoUrl
            }))}
            selectedTargetId={selectedTargetId}
            open={onToggle}
            handleClose={onToggle}
          />
        )
      }
      <Alert
        open={alertMsg.open}
        handleCloseAlert={handleCloseAlert}
        type={alertMsg.type}
      >
        {alertMsg.message}
      </Alert>
    </>
  );
};

DragNDrop.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.shape({
    targetId: PropTypes.number,
    photoUrl: PropTypes.string,
    photoVector: PropTypes.arrayOf(PropTypes.number),
    videoUrl: PropTypes.string,
    title: PropTypes.string,
    sound: PropTypes.bool,
    photoConverted: PropTypes.bool,
    videoConverted: PropTypes.bool
  })),
  images: PropTypes.arrayOf(PropTypes.shape({
    photoUrl: PropTypes.string,
    photoVector: PropTypes.arrayOf(PropTypes.number)
  })),
  videos: PropTypes.arrayOf(PropTypes.shape({
    videoUrl: PropTypes.string,
    video: PropTypes.string
  })),
  setPairedData: PropTypes.func.isRequired,
  setSingleImage: PropTypes.func.isRequired,
  setSingleVideo: PropTypes.func.isRequired,
  onMount: PropTypes.func,
  handleDeleteItem: PropTypes.func.isRequired,
  isCreateForm: PropTypes.bool,
  editTargetId: PropTypes.number
};

DragNDrop.defaultProps = {
  targets: [],
  images: [],
  videos: [],
  onMount: noopPromise,
  isCreateForm: false,
  editTargetId: null
};

export default memo(DragNDrop);
