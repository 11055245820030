import { connect } from 'react-redux';

import {
  getScale
} from '../../redux/scale/actions';

import { setAlert } from '../../redux/app/actions';

import Scale from './Scale';

const mapDispatchToProps = {
  onMount: (scaleId) => (dispatch) => dispatch(getScale(scaleId)),
  handleShowAlert: setAlert
};

export default connect(null, mapDispatchToProps)(Scale);
