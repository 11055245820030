import React, { memo } from 'react';

const PaginationFirst = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.41 7.41L8.83 11H23V13H8.83L12.42 16.59L11 18L5 12L11 6L12.41 7.41ZM4 6V18H2V6H4Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(PaginationFirst);
