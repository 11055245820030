/* eslint-disable */
export const scrollToTarget = function (
    target,
    containerEl
) {
    const isElement = target && target.nodeType === 1;

    if (isElement && containerEl) {
        const topPos = containerEl.offsetTop;
        containerEl.scrollTop = target.offsetTop - topPos;
    }
};