import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';

import InputText from '../InputText';
import ToggledEyeIconButton from './ToggledEyeIconButton';

import style from './InputPassword.scss';

const InputPassword = ({
  name, disabled, onChange, ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [filled, setFilled] = useState(false);

  const toggleVisible = useCallback(() => {
    setVisible((prevValue) => !prevValue);
  }, []);

  const handleChange = (value) => {
    setFilled(value !== '');
    if (onChange) {
      onChange(value, name);
    }
  };

  return (
    <div className={style.component}>
      <InputText
        type={!visible ? 'password' : 'text'}
        name={name}
        onChange={handleChange}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
      <ToggledEyeIconButton
        visible={visible}
        onClick={toggleVisible}
        disabled={disabled}
        filled={filled}
      />
    </div>
  );
};

InputPassword.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

InputPassword.defaultProps = {
  name: null,
  disabled: false,
  onChange: null
};

export default memo(InputPassword);
