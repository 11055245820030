import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  useParams
} from 'react-router-dom';

import Panel from '../../components/UI/Panel';
import Heading from '../../components/UI/Heading';
import FormScaleEdit from '../../components/Forms/FormScaleEdit';

import { noop, noopPromise } from '../../utils';

import style from './Scale.scss';

const Scale = ({
  onMount,
  handleShowAlert
}) => {
  const { scaleId } = useParams();

  const onScaleEditSucess = useCallback(() => {
    handleShowAlert('Scale edit success');
  }, [handleShowAlert]);

  useEffect(() => {
    onMount(scaleId);
  }, [onMount, scaleId]);

  return (
    <div className={style.component}>
      <Heading>
        Edit scale
      </Heading>

      <Panel width="100%">
        <FormScaleEdit onSuccess={onScaleEditSucess} />
      </Panel>
    </div>
  );
};

Scale.propTypes = {
  onMount: PropTypes.func,
  handleShowAlert: PropTypes.func
};

Scale.defaultProps = {
  onMount: noopPromise,
  handleShowAlert: noop
};

export default memo(Scale);
