import {
  createReducer,
  createRequestReducer
} from '../utils';
import {
  GET_SCALES_LIST,
  GET_SCALE,
  UPDATE_SCALE,
  DELETE_SCALE,
  CREATE_SCALE
} from './constants';

const initialState = {
  data: {
    list: [],
    scale: null
  },
  fetching: false
};

const account = createReducer(initialState, {
  [GET_SCALES_LIST]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        list: action.request.response.data
      }
    })
  }),

  [GET_SCALE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        scale: action.request.response.data
      }
    })
  }),

  [CREATE_SCALE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        scale: action.request.response.data,
        list: [
          ...state.data.list,
          action.request.response.data
        ]
      }
    })
  }),

  [UPDATE_SCALE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        scale: action.request.response.data
      }
    })
  }),

  [DELETE_SCALE]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        list: state.data.list.filter((s) => s.uid !== action.data.scaleId),
        scale: null
      }
    })
  })
});

export default account;
