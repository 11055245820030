import { connect } from 'react-redux';

import { requestPasswordReset } from '../../../redux/auth/actions';

import FormRequestPasswordReset from './FormRequestPasswordReset';

const mapDispatchToProps = {
  onSubmit: requestPasswordReset
};

export default connect(null, mapDispatchToProps)(FormRequestPasswordReset);
