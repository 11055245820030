import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { targetSelector, targetIdSelector } from '../../../redux/target/selectors';

import { updateTargets } from '../../../redux/target/actions';

import FormEditTarget from './FormEditTarget';

const initialDataSelector = createSelector(
  targetSelector,
  (target) => ({
    title: target?.title || '',
    scale: target?.scale || 1,
    sound: target?.sound || true,
    photo: target?.photo || null,
    video: target?.video || null
  })
);

const mapStateToProps = createStructuredSelector({
  initialData: initialDataSelector
});

const mapDispatchToProps = {
  onSubmit: (data) => (dispatch, getState) => {
    const targetId = targetIdSelector(getState());
    return dispatch(updateTargets(targetId, data));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEditTarget);
