import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { scaleSelector, scaleIdSelector } from '../../../redux/scale/selectors';

import { updateScale } from '../../../redux/scale/actions';

import FormScaleEdit from './FormScaleEdit';

const initialDataSelector = createSelector(
  scaleSelector,
  (scale) => ({
    title: scale?.title || '',
    scale: scale?.scale || 1
  })
);

const mapStateToProps = createStructuredSelector({
  initialData: initialDataSelector
});

const mapDispatchToProps = {
  onSubmit: (data) => (dispatch, getState) => {
    const scaleId = scaleIdSelector(getState());
    return dispatch(updateScale(scaleId, data));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormScaleEdit);
