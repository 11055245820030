import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { noopPromise } from '../../utils';
import { getEventById } from '../../redux/events/actions';
import EventDetailContent from './EventDetailContent/EventDetailContent';

import {
  fetchAllSessionsCount,
  fetchSessionSessionsCount,
  fetchSessionTargetsCount,
  fetchSingleSessionsCount,
  fetchSingleTargetsCount,
  fetchTargetsCount,
  getAllTargetsList,
  getSessionTargetsList,
  getSingleTargetsList
} from '../../redux/target/actions';
import {
  allSessionCountSelector,
  allTargetListResultsSelector,
  sessionSessionCountSelector,
  sessionTargetListResultsSelector,
  singleSessionCountSelector,
  singleTargetListResultsSelector
} from '../../redux/target/selectors';
import { RESET_TARGETS_LISTS } from '../../redux/target/constants';

import style from './EventDetail.scss';

const EventDetail = ({
  onMount
}) => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const [event, setEvent] = useState({ title: '', date: '', id: eventId });

  const getData = useCallback(async() => {
    const eventData = await dispatch(getEventById(eventId));
    setEvent(eventData);
  }, [dispatch, eventId]);

  useEffect(() => {
    onMount();
    getData();
    return () => {
      dispatch({ type: RESET_TARGETS_LISTS });
    };
  }, [getData, dispatch, onMount]);

  const [currentTab, setCurrentTab] = useState('all targets');

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };

  const tabs = [
    {
      name: 'all targets',
      dispatchData: getAllTargetsList,
      targetsDataSelector: allTargetListResultsSelector,
      dispatchCount: fetchAllSessionsCount,
      countSelector: allSessionCountSelector,
      dispatchTargetsCount: fetchTargetsCount
    },
    {
      name: 'single targets',
      dispatchData: getSingleTargetsList,
      targetsDataSelector: singleTargetListResultsSelector,
      dispatchCount: fetchSingleSessionsCount,
      countSelector: singleSessionCountSelector,
      dispatchTargetsCount: fetchSingleTargetsCount
    },
    {
      name: 'sessions',
      dispatchData: getSessionTargetsList,
      targetsDataSelector: sessionTargetListResultsSelector,
      dispatchCount: fetchSessionSessionsCount,
      countSelector: sessionSessionCountSelector,
      dispatchTargetsCount: fetchSessionTargetsCount
    }
  ];

  return (
    <>
      {tabs?.map((t) => (
        <div
          key={t.name}
          className={t.name === currentTab ? style.tabsContentActive : style.tabsContent}
        >
          <EventDetailContent
            event={event}
            tabName={t.name}
            currentTab={currentTab}
            setCurrentTab={handleChangeTab}
            dispatchData={t.dispatchData}
            targetsDataSelector={t.targetsDataSelector}
            dispatchCount={t.dispatchCount}
            countSelector={t.countSelector}
            dispatchTargetsCount={t.dispatchTargetsCount}
          />
        </div>
      ))}
    </>
  );
};

EventDetail.propTypes = {
  onMount: PropTypes.func
};

EventDetail.defaultProps = {
  onMount: noopPromise
};

export default memo(EventDetail);
