import { createActionRequestWithAuth } from '../utils';
import {
  GET_SCALE,
  GET_SCALES_LIST,
  CREATE_SCALE,
  UPDATE_SCALE,
  DELETE_SCALE
} from './constants';

export const getScalesList = () => (dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_SCALES_LIST,
  request: {
    method: 'GET',
    endpoint: 'scale/'
  }
}));

export const getScale = (scaleId) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_SCALE,
  request: {
    method: 'GET',
    endpoint: `scale/${scaleId}/`
  }
}));

export const createScale = ({ title, scale }) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: CREATE_SCALE,
  request: {
    method: 'POST',
    endpoint: 'scale/',
    data: {
      title,
      scale
    }
  }
}));

export const updateScale = (scaleId, { title, scale }) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: UPDATE_SCALE,
  request: {
    method: 'PATCH',
    endpoint: `scale/${scaleId}/`,
    data: {
      title,
      scale
    }
  }
}));

export const deleteScale = (scaleId) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: DELETE_SCALE,
  request: {
    method: 'DELETE',
    endpoint: `scale/${scaleId}/`
  },
  data: {
    scaleId
  }
}));
