import React, {
  memo, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import TargetItem from '../TargetItem/TargetItem';
import { getNewTarget, targetListResultsSelector } from '../../redux/target/selectors';
import { noopPromise } from '../../utils';
import { deleteTarget, fetchTargetsCount, getTargetsList } from '../../redux/target/actions';
import Loader from '../UI/Loader/Loader';

import style from './TargetList.scss';
import { RESET_TARGETS_LISTS } from '../../redux/target/constants';

const TargetList = ({ limit, onMount }) => {
  const dispatch = useDispatch();
  const targets = useSelector(targetListResultsSelector);
  const newTarget = useSelector(getNewTarget);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (onMount) {
      onMount();
    }
    return () => {
      dispatch({ type: RESET_TARGETS_LISTS });
    };
  }, [onMount, dispatch]);

  useEffect(() => {
    const fetchData = async() => {
      try {
        setIsLoading(true);
        dispatch(fetchTargetsCount());
        dispatch(getTargetsList());
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const displayedTargets = limit ? targets?.slice(0, limit) : targets;

  const handleUpdate = useCallback(async() => {
    setTimeout(() => {
      dispatch(getTargetsList());
      dispatch(fetchTargetsCount());
    }, 1000);
  }, [dispatch]);

  const handleDeleteTarget = useCallback(async(sessionId) => {
    await dispatch(deleteTarget({ sessionId }));
    dispatch(fetchTargetsCount());
  }, [dispatch]);

  return (
    <ul className={style.listHolder}>
      {isLoading && <Loader />}
      {!isLoading && displayedTargets.map((target) => {
        if (target && target.sessionId) {
          const isMatching = newTarget && target.targets?.some((t) => (
            newTarget?.targetIds.includes(t.targetId)
          ));
          return (
            <TargetItem
              key={target.sessionId}
              target={target}
              handleUpdate={handleUpdate}
              handleDeleteItem={handleDeleteTarget}
              isNew={isMatching}
            />
          );
        }
        return null;
      })}
    </ul>
  );
};

TargetList.propTypes = {
  limit: PropTypes.number,
  onMount: PropTypes.func
};

TargetList.defaultProps = {
  limit: undefined,
  onMount: noopPromise
};

export default memo(TargetList);
