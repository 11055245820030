import {
  createReducer,
  createRequestReducer,
  defaultFetchReducer
} from '../utils';
import {
  GET_ACCOUNT,
  UPDATE_ACCOUNT
} from './constants';

const initialState = {
  data: null,
  fetching: false
};

const account = createReducer(initialState, {
  [GET_ACCOUNT]: defaultFetchReducer,

  [UPDATE_ACCOUNT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: action.request.response.data
    })
  })
});

export default account;
