import {
  createReducer,
  createRequestReducer
} from '../utils';
import {
  CREATE_TARGETS,
  GET_TARGETS_LIST,
  GET_TARGET,
  DELETE_TARGET,
  SET_TARGET_FIELDS,
  SET_TARGET_IMAGE_FOR_CREATION,
  SET_TARGET_VIDEO_FOR_CREATION,
  UPDATE_TARGETS,
  UPDATE_SESSION_NAME,
  LOAD_IMAGE,
  LOAD_VIDEO,
  RESET_STATE,
  FETCH_TARGETS_COUNT,
  FETCH_TARGETS_COUNT_SUCCESS,
  FETCH_TARGETS_COUNT_FAILURE,
  UPDATE_EVENT_NAME,
  GET_TARGET_BY_SESSION_ID,
  RESET_NEW_TARGET,
  DELETE_ASSET,
  FETCH_SESSIONS_COUNT_REQUEST,
  FETCH_SESSIONS_COUNT_FAILURE,
  FETCH_SINGLE_TARGETS,
  FETCH_SESSION_TARGETS,
  FETCH_SESSION_SESSIONS_COUNT,
  FETCH_SINGLE_SESSIONS_COUNT,
  FETCH_ALL_TARGETS,
  FETCH_ALL_SESSION_COUNT,
  RESET_TARGETS_LISTS,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA,
  REMOVE_FILE_FROM_UPLOAD,
  INSTANT_DELETE_SESSION,
  FETCH_SINGLE_TARGETS_COUNT,
  FETCH_SESSION_TARGETS_COUNT,
  RESET_EDIT_POPUP_STATE,
  DELETE_TARGETS_BY_EVENT_ID
} from './constants';

const initialState = {
  data: {
    sessionName: '',
    eventId: null,
    targets: [],
    targetsCount: 0,
    sessionCount: 0,
    allTargets: [],
    allTargetsCount: 0,
    singleTargets: [],
    singleSessionsCount: 0,
    sessionTargets: [],
    sessionSessionCounts: 0,
    singleTargetsCount: 0,
    sessionTargetsCount: 0,
    list: []
  },
  images: [],
  videos: [],
  fetching: false,
  newTarget: null,
  shouldUpdate: 0
};

const target = createReducer(initialState, {
  [LOAD_IMAGE]: (state, action) => {
    const newImage = action.payload;
    return {
      ...state,
      images: [...state.images, newImage]
    };
  },

  [LOAD_VIDEO]: (state, action) => {
    const newVideo = action.payload;
    return {
      ...state,
      videos: [...state.videos, newVideo]
    };
  },

  [UPDATE_SESSION_NAME]: (state, action) => {
    const newSessionName = action.payload;
    return {
      ...state,
      data: {
        ...state.data,
        sessionName: newSessionName
      }
    };
  },

  [UPDATE_EVENT_NAME]: (state, action) => {
    const id = action.payload;
    return {
      ...state,
      data: {
        ...state.data,
        eventId: id
      }
    };
  },

  [RESET_STATE]: (state = initialState) => ({
    ...state,
    data: {
      sessionName: '',
      eventId: null,
      targets: [],
      targetsCount: state.data.targetsCount,
      list: state.data.list
    },
    uploadMedia: [],
    images: [],
    videos: [],
    fetching: false
  }),

  [RESET_EDIT_POPUP_STATE]: (state = initialState) => ({
    ...state,
    data: {
      ...state.data,
      sessionName: '',
      eventId: null,
      targets: []
    },
    uploadMedia: [],
    images: [],
    videos: [],
    fetching: false
  }),

  [RESET_TARGETS_LISTS]: (state = initialState) => ({
    ...state,
    data: {
      list: [],
      targetsCount: 0,
      sessionCount: 0,
      allTargets: [],
      allTargetsCount: 0,
      singleTargets: [],
      singleSessionsCount: 0,
      sessionTargets: [],
      sessionSessionCounts: 0
    }
  }),

  [UPDATE_TARGETS]: (state, action) => {
    const newTargets = action.payload;
    const updatedList = Array.isArray(state.data.list)
      ? [...state.data.list]
      : [];
    return {
      ...state,
      data: {
        ...state.data,
        targets: newTargets,
        list: updatedList,
        targetsCount: updatedList.length
      },
      newTarget: newTargets
    };
  },

  [CREATE_TARGETS]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => {
      const newTargetData = Object.fromEntries(action.request.data.entries());
      const targetIds = action.request.response.data.targets || [];
      const newTarget = {
        ...newTargetData,
        targetIds
      };
      return {
        ...state,
        shouldUpdate: state.shouldUpdate + 1,
        newTarget
      };
    }
  }),

  [RESET_NEW_TARGET]: (state) => ({ ...state, newTarget: null }),

  [GET_TARGETS_LIST]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        list: action.request.response.data
      }
    }),
    SEND: () => ({
      ...state,
      fetching: true
    })
  }),

  [FETCH_ALL_TARGETS]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        allTargets: action.request.response.data
      }
    })
  }),

  [FETCH_SINGLE_TARGETS]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        singleTargets: action.request.response.data
      }
    })
  }),

  [FETCH_SESSION_TARGETS]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        sessionTargets: action.request.response.data
      }
    })
  }),

  [GET_TARGET]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      data: {
        ...state.data,
        target: action.request.response.data,
        image: null,
        video: null
      }
    })
  }),

  [GET_TARGET_BY_SESSION_ID]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => {
      const responseData = action.request.response.data[0];
      const {
        sessionId,
        eventId,
        isSingle,
        eventDate,
        eventName,
        sessionName,
        targets
      } = responseData;

      return {
        ...state,
        data: {
          ...state.data,
          sessionId,
          eventId,
          isSingle,
          eventDate,
          eventName,
          sessionName,
          targets: [...targets]
        },
        fetching: false
      };
    }
  }),

  [DELETE_TARGET]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      shouldUpdate: state.shouldUpdate + 1,
      newTarget: null
    })
  }),

  [INSTANT_DELETE_SESSION]: (state, action) => {
    const sessionId = action.payload;
    return {
      ...state,
      data: {
        ...state.data,
        list: state.data.list.filter((l) => l.sessionId !== sessionId),
        singleTargets: state.data.singleTargets.filter((l) => l.sessionId !== sessionId),
        allTargets: state.data.allTargets.filter((l) => l.sessionId !== sessionId),
        sessionTargets: state.data.sessionTargets.filter((l) => l.sessionId !== sessionId)
      },
      newTarget: null
    };
  },

  [DELETE_TARGETS_BY_EVENT_ID]: (state, action) => {
    const eventId = action.payload;
    return {
      ...state,
      data: {
        ...state.data,
        list: state.data.list.filter((l) => l.eventId !== eventId)
      }
    };
  },

  [DELETE_ASSET]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => {
      const { url } = action.request.data;
      const fileNameToDelete = url.substring(url.lastIndexOf('/') + 1);
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            results: state.data.list.results?.filter((item) => item.url !== url)
          },
          targets: []
        },
        newTarget: null,
        uploadMedia: state.uploadMedia?.filter((file) => file.path !== fileNameToDelete)
      };
    }
  }),

  [SET_TARGET_FIELDS]: (state, { target: newTarget }) => ({
    ...state,
    data: {
      ...state.data,
      target: {
        ...state.data.target,
        ...newTarget
      }
    }
  }),

  [SET_TARGET_IMAGE_FOR_CREATION]: (state, { image }) => ({
    ...state,
    data: {
      ...state.data,
      image
    }
  }),

  [SET_TARGET_VIDEO_FOR_CREATION]: (state, { video }) => ({
    ...state,
    data: {
      ...state.data,
      video
    }
  }),

  [FETCH_TARGETS_COUNT]: (state) => ({
    ...state,
    fetching: true,
    error: null
  }),

  [FETCH_TARGETS_COUNT_SUCCESS]: (state, action) => ({
    ...state,
    fetching: false,
    data: {
      ...state.data,
      targetsCount: action.payload.count
    }
  }),

  [FETCH_TARGETS_COUNT_FAILURE]: (state, action) => ({
    ...state,
    fetching: false,
    error: action.error
  }),

  [FETCH_SINGLE_TARGETS_COUNT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: {
        ...state.data,
        singleTargetsCount: action.request.response.data.count
      }
    }),
    FAILURE: () => ({
      ...state,
      fetching: false,
      error: action.request.response.error
    }),
    REQUEST: () => ({
      ...state,
      fetching: true
    })
  }),

  [FETCH_SESSION_TARGETS_COUNT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: {
        ...state.data,
        sessionTargetsCount: action.request.response.data.count
      }
    }),
    FAILURE: () => ({
      ...state,
      fetching: false,
      error: action.request.response.error
    })
  }),

  [FETCH_ALL_SESSION_COUNT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: {
        ...state.data,
        allTargetsCount: action.request.response.data.count
      }
    }),
    FAILURE: () => ({
      ...state,
      fetching: false,
      error: action.request.response.error
    }),
    REQUEST: () => ({
      ...state,
      fetching: true
    })
  }),

  [UPLOAD_MEDIA]: (state, action) => ({
    ...state,
    fetching: true,
    uploadMedia: [
      ...(state.uploadMedia || []),
      ...action.payload
    ]
  }),

  [UPLOAD_MEDIA_SUCCESS]: (state) => ({
    ...state,
    fetching: false
  }),

  [REMOVE_FILE_FROM_UPLOAD]: (state = initialState, action) => {
    const updatedFiles = state.uploadMedia?.filter((file) => !file.name.includes(action.payload));

    return {
      ...state,
      uploadMedia: updatedFiles,
      fetching: false
    };
  },

  [FETCH_SESSIONS_COUNT_REQUEST]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: {
        ...state.data,
        sessionCount: action.request.response.data.count
      }
    }),
    FAILURE: () => ({
      ...state,
      fetching: false,
      error: action.request.response.error
    }),
    REQUEST: () => ({
      ...state,
      fetching: true
    })
  }),

  [FETCH_SINGLE_SESSIONS_COUNT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: {
        ...state.data,
        singleSessionsCount: action.request.response.data.count
      }
    }),
    FAILURE: () => ({
      ...state,
      fetching: false,
      error: action.request.response.error
    }),
    REQUEST: () => ({
      ...state,
      fetching: true
    })
  }),

  [FETCH_SESSION_SESSIONS_COUNT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      data: {
        ...state.data,
        sessionSessionCounts: action.request.response.data.count
      }
    }),
    FAILURE: () => ({
      ...state,
      fetching: false,
      error: action.request.response.error
    }),
    REQUEST: () => ({
      ...state,
      fetching: true
    })
  }),

  [FETCH_SESSIONS_COUNT_FAILURE]: (state, action) => ({
    ...state,
    fetching: false,
    error: action.error
  })
});

export default target;
