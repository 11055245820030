/* eslint-disable max-len */
import React, {
  memo, useState, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Pencil from '../../icons/Pencil';
import Trash from '../../icons/Trash';
import Star from '../../icons/Star';
import Video from '../../icons/Video';
import Popup from '../Popup/Popup';
import FormDelete from '../Forms/FormDelete/FormDelete';
import FormEditTarget from '../Forms/FormEditTarget';
import { getTargetsBySessionId } from '../../redux/target/actions';
import PreviewPopUp from '../PreviewPopup/PreviewPopUp';
import { RESET_EDIT_POPUP_STATE } from '../../redux/target/constants';

import { noopPromise } from '../../utils';

import style from './TargetItem.scss';

const TargetItem = ({
  target, isNew, onMount, handleDeleteItem, handleUpdate, targetId
}) => {
  const dispatch = useDispatch();
  const {
    sessionId, sessionName, sessionViews, eventId, eventName, isSingle, eventDate, targets
  } = target;

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, [onMount, target]);

  const [isPopupDeleteTargetOpen, setIsPopupDeleteTargetOpen] = useState(false);
  const [isPopupEditTargetOpen, setIsPopupEditTargetOpen] = useState(false);
  const [selectedTargetId, setSelectedTargetId] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [highlight, setHighlight] = useState(isNew);

  useEffect(() => {
    let timer;
    if (highlight) {
      timer = setTimeout(() => {
        setHighlight(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [highlight]);

  // need to check because targetId was devclarted on line 25!!
  const openPreviewPopup = (tid) => {
    setOpenPreview(true);
    setSelectedTargetId(tid);
  };

  const openPopupDeleteTarget = useCallback(() => {
    setIsPopupDeleteTargetOpen(true);
  }, []);

  const closePopupDeleteTarget = useCallback(() => {
    setIsPopupDeleteTargetOpen(false);
  }, []);

  const handleOpenEditPopup = useCallback(() => {
    let idSession = sessionId;
    if (sessionId.toString().includes('_')) {
      const [id] = sessionId.toString().split('_');
      idSession = id;
    }

    dispatch(getTargetsBySessionId(idSession, eventId, isSingle));
    setIsPopupEditTargetOpen(true);
  }, [sessionId, eventId, isSingle, dispatch]);

  const closePopupEditTarget = useCallback(() => {
    dispatch({ type: RESET_EDIT_POPUP_STATE });
    setIsPopupEditTargetOpen(false);
  }, [dispatch]);

  const onEditTargetCreateSuccess = useCallback(() => {
    if (handleUpdate) {
      handleUpdate();
    }
    closePopupEditTarget();
  }, [handleUpdate, closePopupEditTarget]);

  const handleDeleteTarget = useCallback(async() => {
    handleDeleteItem(sessionId);
    closePopupDeleteTarget();
  }, [sessionId, closePopupDeleteTarget, handleDeleteItem]);

  let mediaClassName = style.mediaMultiple;
  if (targets.length > 5) {
    mediaClassName += ` ${style.mediaMultipleFive}`;
  } else if (targets.length === 5) {
    mediaClassName += ` ${style.mediaMultipleFive}`;
  } else if (targets.length === 4) {
    mediaClassName += ` ${style.mediaMultipleFour}`;
  } else if (targets.length === 3) {
    mediaClassName += ` ${style.mediaMultipleThree}`;
  }

  const displayedTargets = targets.length > 5 ? targets.slice(0, 5) : targets;

  return (
    <>
      <li className={`${style.row} ${highlight ? style.highlight : ''}`}>
        {displayedTargets.length === 1 ? null : (
          <NavLink exact to={`/target/${sessionId}`}>
            <b className={style.titleTargets}>Targets</b>
          </NavLink>
        )}
        <ul className={mediaClassName}>
          {displayedTargets.length === 1 ? (
            <>
              <li
                key={`img-key-${targets[0].targetId}-${targets[0].photoUrl}`}
                className={style.itemMultiple}
              >
                <button
                  type="button"
                  aria-haspopup="true"
                  onClick={() => openPreviewPopup(targets[0].targetId)}
                  className={style.imgBtn}
                >
                  <img
                    src={targets[0].photoUrl}
                    alt="target"
                    className={style.img}
                  />
                  {(() => {
                    let className = style.loadIndicateConverting;
                    let title = 'Converting...';

                    if (targets[0].photoConverted === true) {
                      className = style.loadIndicateSuccess;
                      title = 'Converted successfully!';
                    } else if (targets[0].photoConverted === false) {
                      className = style.loadIndicateError;
                      title = 'Failure! Please delete and reload!';
                    }

                    return (
                      <span
                        className={className}
                        aria-label="Loading indicate"
                        title={title}
                      />
                    );
                  })()}
                </button>
              </li>
              <li
                key={`video-key-${targets[0].targetId}-${targets[0].videoUrl}`}
                className={style.itemMultiple}
              >
                <button
                  type="button"
                  aria-haspopup="true"
                  onClick={() => openPreviewPopup(targets[0].targetId)}
                  className={style.videoBtn}
                >
                  <video className={style.video}>
                    <source src={targets[0].videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <Video className={style.videoIco} />
                  {(() => {
                    let className = style.loadIndicateConverting;
                    let title = 'Converting...';

                    if (targets[0].videoConverted === true) {
                      className = style.loadIndicateSuccess;
                      title = 'Converted successfully!';
                    } else if (targets[0].videoConverted === false) {
                      className = style.loadIndicateError;
                      title = 'Failure! Please delete and reload!';
                    }

                    return (
                      <span
                        className={className}
                        aria-label="Loading indicate"
                        title={title}
                      />
                    );
                  })()}
                </button>
              </li>
            </>
          ) : (
            (() => {
              let className = style.loadIndicateConverting;
              let title = 'Converting...';
              const allConverted = displayedTargets.every((item) => item.photoConverted === true)
                && displayedTargets.every((item) => item.videoConverted === true);
              const anyError = displayedTargets.some((item) => item.photoConverted === false)
                || displayedTargets.some((item) => item.videoConverted === false);
              const anyConverting = displayedTargets.some((item) => item.photoConverted === null)
                || displayedTargets.some((item) => item.videoConverted === null);

              if (allConverted) {
                className = style.loadIndicateSuccess;
                title = 'Converted successfully!';
              } else if (anyError) {
                className = style.loadIndicateError;
                title = 'Failure! Please delete and reload!';
              } else if (anyConverting) {
                className = style.loadIndicateConverting;
                title = 'Converting...';
              }

              return (
                displayedTargets?.map((item, index) => (
                  <li key={item.targetId} className={style.itemMultiple}>
                    <button
                      type="button"
                      aria-haspopup="true"
                      onClick={() => openPreviewPopup(item.targetId)}
                      className={style.imgBtn}
                    >
                      <img
                        src={item.photoUrl}
                        alt="Target"
                        width={item.width}
                        height={item.height}
                        className={style.img}
                      />
                      {index === 0 ? (
                        <span
                          className={className}
                          aria-label="Loading indicate"
                          title={title}
                        />
                      ) : null}
                    </button>
                  </li>
                ))
              );
            })()
          )}
        </ul>
        <div className={style.descr}>
          {displayedTargets.length === 1 ? (
            <>
              <strong className={style.title}>{targets[0].title}</strong>
              {eventName ? (
                <p className={style.titleLabel}>
                  <span className={style.point} />
                  {eventName}
                </p>
              ) : null}
            </>
          ) : (
            <>
              <strong className={style.title}>{sessionName}</strong>
              {eventName ? (
                <p className={style.titleLabel}>
                  <span className={style.point} />
                  {eventName}
                </p>
              ) : null}
            </>
          )}
        </div>
        <div className={style.meta}>
          {displayedTargets.length === 1 && eventDate ? (
            <div className={style.dateHolder}>
              <b className={style.titleDate}>Date</b>
              <time>{eventDate}</time>
            </div>
          ) : null}
          {displayedTargets.length === 1 ? (
            <div className={style.ratingHolder}>
              <Star />
              <b className={style.rating}>{targets[0].rate}</b>
            </div>
          ) : null}
          <div className={style.viewsHolder}>
            <b className={style.titleViews}>Views</b>
            <b className={style.countViews}>
              {displayedTargets.length === 1 ? targets[0].viewed : sessionViews}
            </b>
          </div>
          <NavLink
            exact
            to={`/target/${sessionId}`}
            className={style.targetsHolder}
          >
            <b className={style.titleTargets}>Targets</b>
            <b className={style.countTargets}>{targets.length}</b>
          </NavLink>
          <div className={style.action}>
            <button
              type="button"
              aria-label="edit"
              className={style.edit}
              onClick={() => handleOpenEditPopup(sessionId, eventId, isSingle)}
            >
              <Pencil />
            </button>
            <button
              type="button"
              aria-label="delete"
              className={style.delete}
              onClick={() => openPopupDeleteTarget(sessionId)}
            >
              <Trash />
            </button>
          </div>
        </div>
      </li>
      {openPreview && (
        <PreviewPopUp
          targets={targets}
          selectedTargetId={selectedTargetId}
          open={openPreview}
          handleClose={() => setOpenPreview(false)}
        />
      )}
      <Popup
        open={isPopupEditTargetOpen}
        handleClose={closePopupEditTarget}
        size="529px"
        closeIconWidth={32}
        closeIconHeight={32}
      >
        <FormEditTarget onSuccess={onEditTargetCreateSuccess} editTargetId={targetId} />
      </Popup>
      <Popup
        open={isPopupDeleteTargetOpen}
        handleClose={closePopupDeleteTarget}
      >
        <FormDelete
          onSubmit={handleDeleteTarget}
          handleClose={closePopupDeleteTarget}
        />
      </Popup>
    </>
  );
};

TargetItem.propTypes = {
  handleDeleteItem: PropTypes.func,
  handleUpdate: PropTypes.func,
  target: PropTypes.shape({
    sessionId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    sessionViews: PropTypes.number,
    sessionName: PropTypes.string,
    eventId: PropTypes.number,
    eventName: PropTypes.string,
    eventDate: PropTypes.string,
    isSingle: PropTypes.bool,
    targets: PropTypes.arrayOf(PropTypes.shape({
      targetId: PropTypes.number.isRequired,
      photoUrl: PropTypes.string,
      videoUrl: PropTypes.string,
      rate: PropTypes.number,
      viewed: PropTypes.number,
      title: PropTypes.string,
      photoConverted: PropTypes.bool,
      videoConverted: PropTypes.bool
    })).isRequired
  }),
  isNew: PropTypes.bool,
  onMount: PropTypes.func,
  targetId: PropTypes.number
};

TargetItem.defaultProps = {
  handleDeleteItem: noopPromise,
  handleUpdate: noopPromise,
  target: {
    sessionId: null,
    sessionName: '',
    eventId: null,
    eventName: '',
    eventDate: '',
    isSingle: false,
    targets: []
  },
  isNew: false,
  onMount: noopPromise,
  targetId: null
};

export default memo(TargetItem);
