import { createSelector } from 'reselect';

import { defaultDataSelector, defaultFetchingSelector } from '../utils';

const slice = ({ scale }) => scale;

export const dataSelector = defaultDataSelector(slice);
export const fetchingSelector = defaultFetchingSelector(slice);
export const scaleListSelector = createSelector(dataSelector, (data) => (data ? data.list : []));
export const scaleSelector = createSelector(dataSelector, (data) => (data ? data.scale : null));
export const scaleIdSelector = createSelector(
  scaleSelector,
  (scale) => (scale ? scale.uid : null)
);
