/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 18.414-4.707-4.707 1.414-1.414L12 15.586l3.293-3.293 1.414 1.414L12 18.414z"
      fill="currentColor"
    />
    <path
      d="M11 6h2v11h-2z"
      fill="currentColor"
    />
  </svg>
);

ArrowDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ArrowDown.defaultProps = {
  width: 30,
  height: 30
};

export default memo(ArrowDown);
