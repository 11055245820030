import React, { useCallback, memo } from 'react';
import {
  Switch, Route, Redirect, useHistory, useRouteMatch
} from 'react-router-dom';

import { useQuery } from '../../hooks';
import Panel from '../../components/UI/Panel';
import Link from '../../components/UI/Link';
import Heading from '../../components/UI/Heading';
import FormRequestPasswordReset from '../../components/Forms/FormRequestPasswordReset';
import FormResetPassword from '../../components/Forms/FormResetPassword';
import Logo from '../../components/UI/Logo';

import style from './PasswordReset.scss';
import Check from '../../icons/Check';

const PasswordReset = () => {
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const { email, code } = useQuery();

  const onResetPasswordSuccess = useCallback(
    (action) => {
      const { data } = action.request.response;
      push(`${path}reset/success/?email=${data.email}`);
    },
    [push, path]
  );

  const onRequestPasswordResetSuccess = useCallback(
    (action) => {
      const { data } = action.request;
      push(`${path}success/?email=${data.email}`);
    },
    [push, path]
  );

  return (
    <div className={style.component}>
      <div className={style.center}>
        <Switch>
          <Route exact path={`${path}reset/success/`}>
            <Panel padding="42px 36px">
              <Check />
              <p className={style.confirmstionText}>Your password has been changed successfully.</p>
              <div className={style.textHolder}>
                <p>Please</p>
                <Link className={style.link} to={`/sign-in/?email=${email}`}>Sign in</Link>
                <p>to continue</p>
              </div>
            </Panel>
          </Route>

          <Route exact path={`${path}restore/`}>
            <div className={style.center}>
              <Panel padding="42px 36px">
                <Logo />
                <Heading align="center" weight={500} fSize={24} mTop={16} mBottom={16}>Pick a new password</Heading>

                {email && code && (
                  <FormResetPassword
                    email={email}
                    code={code}
                    onSuccess={onResetPasswordSuccess}
                  />
                )}

                {(!email || !code) && <Redirect to={path} />}
              </Panel>
            </div>
          </Route>

          <Route exact path={`${path}success/`}>
            <Panel padding="42px 36px">
              <Check />
              <p className={style.confirmstionText}>We sent an email to</p>
              <Link className={style.link} to={`mailto:${email}`}>{email}</Link>
              <p>Check it for next steps</p>
            </Panel>
          </Route>

          <Route>
            <div className={style.center}>
              <Panel align="center" maxWidth="529px">
                <Logo />
                <Heading align="center" fSize={24} weight={500} mTop={16} mBottom={16}>
                  Password reset
                </Heading>
                <FormRequestPasswordReset
                  onSuccess={onRequestPasswordResetSuccess}
                />
              </Panel>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default memo(PasswordReset);
