import React, { memo } from 'react';

import style from './Loader.scss';

const Loader = () => (
  <div className={style.spinnerholder}>
    <span className={style.spinner} />
  </div>
);

export default memo(Loader);
