import React, {
  memo, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Heading from '../../components/UI/Heading';
import Card from '../../components/Card/Card';
import Calendar from '../../icons/Calendar';
import Target from '../../icons/Target';
import TargetList from '../../components/TargetList';
import EventList from '../../components/EventList/EventList';
import Popup from '../../components/Popup/Popup';
import FormCreateEvent from '../../components/Forms/FormCreateEvent';
import FormCreateTarget from '../../components/Forms/FormCreateTarget';

import { noopPromise } from '../../utils';

import { fetchEventsCount, fetchEventsList } from '../../redux/events/actions';
import {
  fetchTargetsCount, getTargetsList
} from '../../redux/target/actions';
import { selectEventsCount, selectNewEvent } from '../../redux/events/selectors';
import { targetsCountSelector } from '../../redux/target/selectors';
import { RESET_EDIT_POPUP_STATE, RESET_NEW_TARGET } from '../../redux/target/constants';

import style from './Home.scss';

const Home = ({
  onMount
}) => {
  const dispatch = useDispatch();
  const eventsCount = useSelector(selectEventsCount);
  const targetsCount = useSelector(targetsCountSelector);
  const newEvent = useSelector(selectNewEvent);
  const history = useHistory();

  const [isPopupEventOpen, setIsPopupEventOpen] = useState(false);
  const [isPopupTargetOpen, setIsPopupTargetOpen] = useState(false);

  useEffect(() => {
    onMount();
    dispatch(fetchEventsCount());
    dispatch(fetchTargetsCount());
    dispatch(fetchEventsList());
    dispatch(getTargetsList());
  }, [onMount, dispatch]);

  const openPopupCreateEvent = useCallback(() => {
    setIsPopupEventOpen(true);
  }, []);

  const closePopupCreateEvent = useCallback(() => {
    setIsPopupEventOpen(false);
  }, []);

  useEffect(() => {
    if (newEvent && isPopupEventOpen) {
      setTimeout(() => {
        history.push(`/events/${newEvent.id}`);
      }, 1000);
      closePopupCreateEvent();
    }
  }, [newEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const openPopupCreateTarget = useCallback(() => {
    dispatch({ type: RESET_NEW_TARGET });
    setIsPopupTargetOpen(true);
  }, [dispatch]);

  const closePopupCreateTarget = useCallback(() => {
    setIsPopupTargetOpen(false);
    dispatch({ type: RESET_EDIT_POPUP_STATE });
  }, [dispatch]);

  const onTargetCreateSuccess = useCallback(() => {
    closePopupCreateTarget();
  }, [closePopupCreateTarget]);

  return (
    <>
      <div className={style.component}>
        <Heading weight={500} mBottom={24}>
          Dashboard
        </Heading>
        <ul className={style.item}>
          <Card
            Icon={Calendar}
            title="Events"
            count={eventsCount || 0}
            buttonLabel="Create Event"
            openPopup={openPopupCreateEvent}
          />
          <Card
            Icon={Target}
            title="Targets"
            count={targetsCount || 0}
            buttonLabel="Create Target"
            openPopup={openPopupCreateTarget}
          />
        </ul>

        <div className={style.table}>
          {targetsCount ? (
            <>
              <Heading level={2} weight={500} mBottom={24}>
                Recently uploaded
              </Heading>
              <TargetList limit={10} />
            </>
          ) : null}
          {eventsCount ? (
            <>
              <Heading level={2} weight={500} mBottom={24}>
                Recent Events
              </Heading>
              <EventList limit={10} />
            </>
          ) : null}
        </div>
        <Popup open={isPopupEventOpen} handleClose={closePopupCreateEvent}>
          <FormCreateEvent />
        </Popup>
        <Popup
          open={isPopupTargetOpen}
          handleClose={closePopupCreateTarget}
          size="529px"
          closeIconWidth={32}
          closeIconHeight={32}
        >
          <FormCreateTarget onSuccess={onTargetCreateSuccess} />
        </Popup>
      </div>
    </>
  );
};

Home.propTypes = {
  onMount: PropTypes.func
};

Home.defaultProps = {
  onMount: noopPromise
};

export default memo(Home);
