import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

const uploadFileToAWS = (fileObj) => {
  const { file } = fileObj;
  const type = file?.type.includes('image') ? 'photos' : 'videos';
  const timestamp = new Date().getTime();
  const fileName = `${type}/${timestamp}_${file.name}`;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  });

  const s3 = new S3({
    params: { Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_S3_REGION_NAME
  });

  const params = {
    Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME,
    Key: fileName,
    Body: file
  };

  return new Promise((resolve, reject) => {
    s3.putObject(params, (err) => {
      if (err) {
        reject(new Error(`Error uploading file: ${err.message}`));
      } else {
        const url = `https://${process.env.REACT_APP_AWS_S3_CUSTOM_DOMAIN}/${fileName}`;
        resolve({ success: true, url });
      }
    });
  });
};

export default uploadFileToAWS;
