import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { useQuery } from '../../../hooks';
import { isSuccessAction, noopPromise } from '../../../utils';

const ConfirmEmail = ({ registrationConfirm }) => {
  const { push } = useHistory();
  const { email, code } = useQuery();

  useEffect(() => {
    if (!email || !code) {
      return;
    }

    registrationConfirm({ email, code }).then((action) => {
      if (isSuccessAction(action)) {
        push('/');
      }
    });
  }, [email, code, registrationConfirm, push]);

  return null;
};

ConfirmEmail.propTypes = {
  registrationConfirm: PropTypes.func
};

ConfirmEmail.defaultProps = {
  registrationConfirm: noopPromise
};

export default memo(ConfirmEmail);
