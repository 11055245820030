import config from '../config';
import storageFactory from './storageFactory';

export const noop = () => {};

export const noopPromise = () => Promise.resolve({});

export const createArray = (length, iterator = () => null) => Array.from({ length }, iterator);

export const filterObjectKeys = (obj, keys = []) => keys.reduce((prev, key) => ({
  ...prev,
  [key]: obj ? obj[key] : null
}), {});

export const isNotEmptyArray = (obj) => Array.isArray(obj) && obj.length > 0;

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const isNotEmptyObject = (obj) => !isEmptyObject(obj);

export const isSuccessAction = (action) => (!!action && action.status === 'SUCCESS');

export const isFailAction = (action) => (!!action && action.status === 'FAIL');

export const shouldFactoryBindProps = (prevProps, nextProps, comparePropNames = []) => {
  if (prevProps === undefined || nextProps === undefined) {
    return true;
  }

  return comparePropNames.some((prop) => prevProps[prop] !== nextProps[prop]);
};

export const createDispatchPropsFactory = (createDispatchProps, comparePropNames = []) => {
  let prevOwnProps;
  let dispatchProps;

  return () => (dispatch, ownProps) => {
    const shouldBind = shouldFactoryBindProps(prevOwnProps, ownProps, comparePropNames);

    if (shouldBind) {
      dispatchProps = createDispatchProps(dispatch, ownProps);
      prevOwnProps = filterObjectKeys(ownProps, comparePropNames);
    }

    return dispatchProps;
  };
};

export const isNumber = (num) => (typeof num === 'number') && !Number.isNaN(num) && !Number.isFinite(num);

export const localStorage = storageFactory('localStorage');

export const buildRedirectUri = (provider) => {
  const protocol = config.APP_PROTOCOL || window.location.protocol.replace(':', '');
  const host = config.APP_HOST || window.location.host;
  const path = config.APP_PATH || '/';

  return `${protocol}://${host}${path}social-auth/${provider}/`;
};

export const downloadUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;

  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const mapParamsToQueryString = (params) => {
  const paramsObject = { ...params };
  return Object.entries(paramsObject).reduce((result, [ key, value ], idx) => {
    const prefix = idx === 0 ? '?' : '&';

    return `${result}${prefix}${key}=${value}`;
  }, '');
};
