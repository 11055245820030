import { createReducer } from '../utils';
import {
  SET_DEVICE,
  SET_ALERT
} from './constants';

const initialState = {
  device: {
    mobile: null,
    os: null
  },
  alert: null
};

const app = createReducer(initialState, {
  [SET_DEVICE]: (state, { device }) => ({
    ...state,
    device
  }),

  [SET_ALERT]: (state, { alert }) => ({
    ...state,
    alert
  })
});

export default app;
