/* eslint-disable max-len */
import React, { useMemo, memo, useState } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise } from '../../../utils';

import InputText from '../../UI/InputText';
import ErrorMessage from '../../UI/ErrorMessage';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader/Loader';

import style from './FormConfirmationCode.scss';

const DATA = {
  mfa_code: '',
  email: ''
};

const RULES = {
  mfa_code: {
    mfa_code: true,
    required: true
  }
};

const FormConfirmationCode = ({
  onSubmit, onSuccess, onFail, email
}) => {
  const [value, setValue] = useState(''); // eslint-disable-line no-unused-vars

  const initialData = useMemo(
    () => (email ? { mfa_code: '', email } : DATA),
    [email]
  );

  const {
    onFormSubmit, onFieldChange, formState, processing, errors
  } = useFormState(initialData, RULES, onSubmit, onSuccess, onFail);

  const isFormValue = () => formState.mfa_code;

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputText
          id="mfa_code"
          name="mfa_code"
          type="text"
          placeholder={value ? '' : 'Confirmation code'}
          errors={errors.mfa_code}
          disabled={processing}
          value={formState.mfa_code}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {(errors.detail || errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.detail || errors.nonFieldErrors}</ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <Button type="submit" disabled={!isFormValue()} fullWidth>
          Confirm
        </Button>
      </div>
      {processing && <Loader />}
    </form>
  );
};

FormConfirmationCode.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

FormConfirmationCode.defaultProps = {
  email: '',
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormConfirmationCode);
