/* eslint-disable max-len */
import React, { useMemo, memo, useState } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise } from '../../../utils';

import Link from '../../UI/Link';
import InputText from '../../UI/InputText';
import InputPassword from '../../UI/InputPassword';
import ErrorMessage from '../../UI/ErrorMessage';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader/Loader';

import style from './FormLogin.scss';

const DATA = {
  email: '',
  password: ''
};

const RULES = {
  email: {
    email: true,
    required: true
  },
  password: {
    required: true
  }
};

const FormLogin = ({
  email, onSubmit, onSuccess, onFail
}) => {
  const [value, setValue] = useState(''); // eslint-disable-line no-unused-vars

  const initialData = useMemo(
    () => (email ? { ...DATA, email } : DATA),
    [email]
  );
  const {
    onFormSubmit, onFieldChange, formState, processing, errors
  } = useFormState(initialData, RULES, onSubmit, onSuccess, onFail);

  const isFormValue = () => formState.email && formState.password;

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormControl margin="normal" fullWidth>
        <InputText
          id="email"
          name="email"
          type="email"
          placeholder={value ? '' : 'Your email'}
          errors={errors.email}
          disabled={processing}
          value={formState.email}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <InputPassword
          id="password"
          name="password"
          placeholder={value ? '' : 'Password'}
          errors={errors.password}
          disabled={processing}
          value={formState.password}
          onChange={onFieldChange}
          fullWidth
        />
      </FormControl>

      {(errors.detail || errors.nonFieldErrors) && (
        <div className={style.nonFieldError}>
          <ErrorMessage>{errors.detail || errors.nonFieldErrors}</ErrorMessage>
        </div>
      )}

      <Link
        to="/reset-password/"
        style={{ fontSize: '10px', fontWeight: '500' }}
      >
        Recovery password
      </Link>
      <div className={style.submitButton}>
        <Button type="submit" disabled={!isFormValue()} fullWidth>
          Sign In
        </Button>
      </div>
      {processing && <Loader />}
    </form>
  );
};

FormLogin.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

FormLogin.defaultProps = {
  email: null,
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise
};

export default memo(FormLogin);
