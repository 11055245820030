import React, { useCallback, memo } from 'react';
import {
  Switch, Route, useHistory, useRouteMatch
} from 'react-router-dom';

import { Typography } from '@material-ui/core';

import Heading from '../../components/UI/Heading';
import Panel from '../../components/UI/Panel';
import FormRegistration from '../../components/Forms/FormRegistration';
import ConfirmEmail from './ConfirmEmail';
import Link from '../../components/UI/Link';
import Logo from '../../components/UI/Logo';
import Check from '../../icons/Check';

import style from './SignUp.scss';

const SignUp = () => {
  const { push } = useHistory();
  const { path } = useRouteMatch();

  const onRegistrationSuccess = useCallback(
    (action) => {
      const { data } = action.request.response;
      push(`${path}success/?email=${data.email}`);
    },
    [push, path]
  );

  return (
    <Switch>
      <Route exact path={`${path}confirm`}>
        <ConfirmEmail />
      </Route>

      <Route exact path={`${path}success/`}>
        <div className={style.component}>
          <div className={style.center}>
            <Panel align="center" maxWidth="529px">
              <Check />
              <p className={style.confirmstionText}>You have successfully registrated!</p>
              <Typography
                style={{
                  fontSize: '14px',
                  marginTop: '12px'
                }}
              >
                Check your email for next steps
              </Typography>
            </Panel>
          </div>
        </div>
      </Route>

      <Route>
        <div className={style.component}>
          <div className={style.center}>
            <Panel align="center">
              <div className={style.headline}>
                <Logo />
                <Heading weight={500} fSize={24}>Sign up</Heading>
                <div className={style.headlineSecond}>
                  <Heading level="2" align="center" weight={400} lheight={1.7} fSize={14}>
                    Create an account to get started
                  </Heading>
                </div>
              </div>
              <FormRegistration onSuccess={onRegistrationSuccess} />

              <div className={style.links}>
                <Typography style={{ fontSize: '14px' }}>
                  Already have an account?
                </Typography>
                <Link to="/sign-in/">Sign in</Link>
              </div>
            </Panel>
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default memo(SignUp);
