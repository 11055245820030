/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Close = ({ width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.3288 8.54895L23.4488 6.66895L15.9954 14.1223L8.54211 6.66895L6.66211 8.54895L14.1154 16.0023L6.66211 23.4556L8.54211 25.3356L15.9954 17.8823L23.4488 25.3356L25.3288 23.4556L17.8754 16.0023L25.3288 8.54895Z"
      fill="currentColor"
    />
  </svg>
);

Close.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

Close.defaultProps = {
  width: 32,
  height: 32
};

export default memo(Close);
