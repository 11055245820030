/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Delete = ({ className, ...props }) => (
  <svg
    className={className}
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.49984 25.0417C8.49984 26.4625 9.69984 27.625 11.1665 27.625H21.8332C23.2998 27.625 24.4998 26.4625 24.4998 25.0417V9.54167H8.49984V25.0417ZM11.7798 15.845L13.6598 14.0238L16.4998 16.7621L19.3265 14.0238L21.2065 15.845L18.3798 18.5833L21.2065 21.3217L19.3265 23.1429L16.4998 20.4046L13.6732 23.1429L11.7932 21.3217L14.6198 18.5833L11.7798 15.845ZM21.1665 5.66667L19.8332 4.375H13.1665L11.8332 5.66667H7.1665V8.25H25.8332V5.66667H21.1665Z"
      fill="currentColor"
    />
  </svg>
);

Delete.propTypes = {
  className: PropTypes.string
};
Delete.defaultProps = {
  className: ''
};

export default memo(Delete);
