/* eslint-disable max-len */
import React, { memo } from 'react';

const Empty = ({ ...props }) => (
  <svg
    width="78"
    height="79"
    viewBox="0 0 79 78"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 40.0572V37.9838C0.878828 28.9996 3.92875 21.0747 9.64977 14.209C20.9841 0.60932 39.4558 -3.77818 55.6225 3.63182C56.3111 3.94768 56.8078 4.26709 57.1125 4.59006C59.0503 6.64823 57.2602 10.0424 54.4784 9.62502C54.1423 9.57526 53.4394 9.31577 52.37 8.84655C26.1395 -2.65845 -1.50027 23.991 8.75855 50.6314C9.06223 51.4195 9.38672 52.199 9.73203 52.9699C9.97984 53.5254 10.117 53.9769 10.1434 54.3242C10.2246 55.3794 9.84223 56.2519 8.99621 56.9415C7.8841 57.8464 6.07426 57.7961 5.02309 56.7663C4.7448 56.4931 4.44977 56.0162 4.13797 55.3358C1.91781 50.4983 0.705156 45.4055 0.5 40.0572Z"
      fill="curentColor"
    />
    <path
      d="M73.2702 0.979669L1.46185 72.788C0.279113 73.9708 0.279112 75.8884 1.46185 77.0711L1.48124 77.0905C2.66398 78.2732 4.58158 78.2732 5.76432 77.0905L77.5727 5.28214C78.7554 4.0994 78.7554 2.1818 77.5727 0.999059L77.5533 0.979669C76.3705 -0.203071 74.4529 -0.20307 73.2702 0.979669Z"
      fill="curentColor"
    />
    <path
      d="M78.4983 37.9016V39.8989C78.1225 48.8638 75.1432 56.7953 69.5603 63.6934C65.4455 68.7772 59.9763 72.892 53.8521 75.3036C44.1646 79.1183 32.9445 78.8501 23.4382 74.6043C22.7222 74.2844 22.2119 73.9604 21.9072 73.6324C20.923 72.572 20.856 71.0364 21.6695 69.8451C22.5866 68.5029 24.1558 68.2485 25.6365 68.9173C30.4064 71.0699 35.4277 72.1272 40.6439 71.9444C57.4337 71.3563 71.9064 57.0909 72.4061 40.2234C72.5472 35.4418 71.6708 30.838 69.7766 26.4119C69.414 25.5648 68.8885 24.5624 68.8747 23.7002C68.8321 21.1149 72.0191 19.6311 73.9067 21.3845C74.2682 21.7197 74.6374 22.2966 75.0142 23.1152C77.1633 27.7891 78.3246 32.7179 78.4983 37.9016Z"
      fill="curentColor"
    />
  </svg>
);

export default memo(Empty);
