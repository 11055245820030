/* eslint-disable max-len */
import React, { memo } from 'react';

const Star = ({ ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99967 1.33301C4.31967 1.33301 1.33301 4.31967 1.33301 7.99967C1.33301 11.6797 4.31967 14.6663 7.99967 14.6663C11.6797 14.6663 14.6663 11.6797 14.6663 7.99967C14.6663 4.31967 11.6797 1.33301 7.99967 1.33301ZM6.33301 10.9997V4.99967L10.9997 7.99967L6.33301 10.9997Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Star);
