const extractNumberFromUrl = (url) => {
  if (typeof url !== 'string') {
    return null;
  }

  const regex = /_(\d+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  }

  const regexAlt = /(\d+)(?=\.[a-zA-Z]+$)/;
  const matchAlt = url.match(regexAlt);

  if (matchAlt && matchAlt[1]) {
    return parseInt(matchAlt[1], 10);
  }

  return null;
};

export default extractNumberFromUrl;
