/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Pencil = ({ width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 17.2496V20.9996H6.75L17.81 9.93957L14.06 6.18957L3 17.2496ZM20.71 7.03957C21.1 6.64957 21.1 6.01957 20.71 5.62957L18.37 3.28957C17.98 2.89957 17.35 2.89957 16.96 3.28957L15.13 5.11957L18.88 8.86957L20.71 7.03957Z"
      fill="currentColor"
    />
  </svg>
);

Pencil.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};
Pencil.defaultProps = {
  width: 24,
  height: 24
};

export default memo(Pencil);
