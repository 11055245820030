import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../UI/CustomButton';

import style from './Card.scss';

const Card = ({
  Icon, title, count, buttonLabel, openPopup
}) => (
  <li className={style.card}>
    <Icon className={style.icon} />
    <strong className={style.title}>{title}</strong>
    <strong className={style.count}>{count}</strong>
    <CustomButton
      type="button"
      variant="primary"
      className={style.btn}
      onClick={openPopup}
    >
      {buttonLabel}
    </CustomButton>
  </li>
);

Card.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  openPopup: PropTypes.func.isRequired
};

export default memo(Card);
